import {Component, Inject, OnInit} from '@angular/core';
import {EventDto} from "../../models/event/event-dto";
import {HttpErrorResponse, HttpEventType, HttpResponse} from "@angular/common/http";
import {EventHttpService} from "../x-http-requests/event-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OrderDto} from "../../models/order-dto";
import {AuthenticationService} from "../../_auth/authentication.service";
import * as signalR from '@microsoft/signalr';
import {HubConnectionState} from '@microsoft/signalr';
import {LocationDto} from "../../models/location-dto";
import {LocationHttpService} from "../../http-requests/location-http.service";
import {EventEdto} from "../../models/event/event-edto";
import {PermissionService} from "../../_auth/permission.service";
import {N5PaymentMapper, BookingType} from "../../models/payment/booking-type";
import {ConfigurationDto} from "../../models/configuration-dto";
import {ConfigurationHttpService} from "../../http-requests/configuration-http.service";
import {LocationConfigLinker} from "../../models/location/location-config-linker";
import {Routes} from "../../enums/routes";
import {PaymentCounter} from "../../models/payment/payment-counter";
import {OtherCounter} from "../../models/order/other-counter";
import {CompositionCounter} from "../../models/order/composition-counter";
import {LocationCounter} from "../../models/location/location-counter";
import {EmployeeHttpService} from "../../_modules/company/x-http-requests/employee-http.service";
import {EmployeeOdto} from "../../_modules/company/x-models/employee-odto";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {PlatformScannerService} from "../../_services/platform-scanner.service";
import {Platform} from "../../enums/platform";
import {SupplierHttpService} from "../../_modules/company/x-http-requests/supplier-http.service";
import {SupplierDto} from "../../_modules/company/x-models/supplier-dto";
import {OrderEdto} from "../../models/order/order-edto";
import copy from "copy-to-clipboard";
import {ViewportScroller} from "@angular/common";
import {CompanyRoutes} from "../../_modules/company/x-models/x-enums/company-routes";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  events?: EventDto[];
  filtered_events?: EventDto[];
  event?: EventEdto;
  id: string = "";
  details: boolean = false;
  location?: LocationDto;
  location_id: string = "";
  name: string = "";
  start: string = "";
  end: string = "";
  first: number = 0;
  last: number = 10;
  searchText: string = "";
  inverse: boolean = true;
  field: string = "date";
  error?: HttpErrorResponse;
  now: Date = new Date();
  downloading: boolean = false;
  oneFile: boolean = false;
  readonly views: string[] = ["drinks", "location", "others", "payment"];
  view_index: number = 1;
  n5paymentMapper: N5PaymentMapper = new N5PaymentMapper();

  locations?: LocationConfigLinker[];
  configurations?: ConfigurationDto[];
  locationConfigVisible: boolean = false;
  generalConfiguration: ConfigurationDto | undefined;
  employeeSales: EmployeeOdto[] | undefined;

  selectionMode: boolean = false;

  constructor(private eventHttpService: EventHttpService,
              private locationHttpService: LocationHttpService,
              private configurationHttpService: ConfigurationHttpService,
              private route: ActivatedRoute,
              private router: Router,
              @Inject("BASE_URL") private baseUrl: string,
              private authenticationService: AuthenticationService,
              public PermissionService: PermissionService,
              private employeeHttpService: EmployeeHttpService,
              private globalAlertService: GlobalAlertService,
              public PlatformScannerService: PlatformScannerService,
              private supplierHttpService: SupplierHttpService,
              private scroller: ViewportScroller) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let start = new Date();
    start.setMinutes(0, 0, 0);
    start.setTime(start.getTime() + (60 * 60 * 1000));
    this.start = this.toIsoString(start).slice(0, 16); // start.toISOString().slice(0,16);

    let end = new Date();
    end.setTime(start.getTime() + (12 * 60 * 60 * 1000));
    this.end = this.toIsoString(end).slice(0, 16); //end.toISOString().slice(0,16);
  }

  AssignCopy(): void {
    this.filtered_events = Object.assign([], this.events);
  }

  FilterItems(value: string): void {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    } else {
      if (this.last != 2000000000) {
        this.last = 2000000000
        this.LoadData()
      }
      this.filtered_events = Object.assign([], this.events?.filter(event =>
        event.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ));
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  selectionModeChanged(): void {
    if (!this.selectionMode) {
      this.events?.forEach(x => x.selected = false);
    }
  }

  selectionItem(selected: EventDto): void {
    if (!this.selectionMode) return;
    selected.selected = !selected.selected;
  }

  redirectToSettings(id: string | undefined) {
    if (id == undefined) return;
    this.router.navigate([Routes.EventSettings], {queryParams: {id: id, last: this.last}});
  }

  SaveLocationConfiguration() {
    if (this.event == undefined || this.locations == undefined) return;
    this.eventHttpService.setLocationConfigs(this.event.id, this.locations).subscribe(x => {
      this.event = x;
      this.CalculateStats(this.event);
      this.locationConfigVisible = false;
      this.globalAlertService.createAlertBannerModel("Speichern erfolgreich", `Die Konfiguration wurde erfolgreich gespeichert.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Speichern fehlgeschlagen", `Die Konfiguration konnte nicht gespeichert werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  GeneralConfigurationChanged(configuration: ConfigurationDto | undefined) {
    this.locations?.forEach(x => {
      x.configuration = configuration;
    })
  }

  LoadData(): void {
    this.eventHttpService.list(this.first, this.last).subscribe(async (x) => {
      this.events = x;
      this.FilterItems(this.searchText);
      await this.delay(300);
      this.scrollTo(this.previous ?? "")
    }, error => {
      console.error(error);
      this.error = error;
    })
  }


  LoadLocationConfigData() {
    this.locationHttpService.list().subscribe(x => {
      this.locations = [];
      x.forEach(location => {
        this.locations?.push(new LocationConfigLinker(location))
      });
      this.configurationHttpService.list().subscribe(x => {
        this.configurations = x;

        this.locations?.forEach(loc => {
          let index = this.event!.locationConfigLinkers.findIndex(x => x.location == loc.location.id);
          if (index < 0) return;
          loc.configuration = this.configurations?.find(x => x.id == this.event!.locationConfigLinkers[index].configuration)
        })
      }, error => {
        console.error(error);
        this.globalAlertService.createAlertBannerModel("Fehler", `Beim Laden der Daten ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
        this.globalAlertService.show();
      })
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Laden der Daten ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  toIsoString(date: Date): string {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  toIsoFromString(date: string): string {
    var tzo = -(new Date()).getTimezoneOffset(), dif = tzo >= 0 ? "+" : "-",
      pad = function (num: number) {
        return (num < 10 ? "0" : "") + num;
      };
    return date + dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" + pad(Math.abs(tzo) % 60);
  }

  Add() {
    let start = new Date(this.start);
    let end = new Date(this.end);
    if (this.end <= this.start) {
      this.globalAlertService.createAlertBannerModel("Fehler", `Das Enddatum kann nicht vor dem Startdatum liegen.`, AlertLevel.error, 3000);
      this.globalAlertService.show();
      return;
    }
    this.eventHttpService.add(this.name, start, end).subscribe(x => {
      if (this.events == undefined) this.events = [];
      this.events.splice(0, 0, x);
      this.FilterItems(this.searchText);
      this.name = "";
      this.globalAlertService.createAlertBannerModel("Erfolgreich hinzugefügt", `Das Event ${x.name} wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Speichern des Events ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  scrollTo(id: string) {
    if (this.previous && this.filtered_events && this.filtered_events.findIndex(x => x.id == this.previous) > -1) {
      const element = document.getElementById(this.previous)
      if (element != null) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });
        element.classList.add("!bg-theme-primary-200");
      }
    }
  }

  Remove(event: EventEdto) {
    let confirmation = confirm("Folgendes Event wirklich löschen:\n" + event.name + "\n" + event.id);
    if (!confirmation) return;
    this.eventHttpService.delete(event.id).subscribe(x => {
      this.events?.splice(this.events?.findIndex(x => x.id == event.id), 1);
      this.FilterItems(this.searchText);
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `Das Event ${event.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
      this.router.navigate(['/events']);
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Löschen fehlgeschlagen", `Das Event ${event.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  CalculateTotal(order: OrderDto): number {
    let total = 0;
    order.compositions.forEach(composition => {
      if (composition.compositionOverride != undefined && composition.compositionOverride.price != null) total += composition.quantity * composition.compositionOverride.price;
      else total += composition.quantity * composition.composition.price;
    })
    return total;
  }

  SortBy(field: string) {
    if (this.event == undefined) return;
    if (this.event.orders == undefined) this.event.orders = []
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "date":
        this.event.orders.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
      case "price":
        this.event.orders.sort((a, b) => {
          if (a.total > b.total) return 1;
          if (a.total < b.total) return -1;
          return 0;
        })
    }
    if (this.inverse) this.event.orders.reverse();
  }

  OpenEmployeeSales(id: string) {
    this.employeeHttpService.employeeOrders(id).subscribe(x => {
      x.forEach(employee => {
        employee.total = employee.orders.map(x => x.compositions)
          .reduce((accumulator, value) => accumulator.concat(value), [])
          .map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price))
          .reduce((sum, current) => sum + current, 0);
      })
      this.employeeSales = x;
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeitergetränke konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  CalculateStats(event: EventEdto) {
    event.compositions = [];
    event.others = [];
    event.locations = [];
    event.payments = [];

    let all = event.orders.filter(x => x.bookingType != BookingType.Employee).map(x => x.compositions).reduce((accumulator, value) => accumulator.concat(value), []);
    let others = all.filter(x => x.composition.tags.findIndex(c => c.other) != -1);
    let normal = all.filter(x => x.composition.tags.findIndex(c => c.other) == -1);

    event.total = all.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

    event.oAmount = others.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
    event.oTotal = others.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

    event.nAmount = normal.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
    event.nTotal = normal.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);


    event.orders.forEach(order => {
      let others = order.compositions.filter(x => x.composition.tags.findIndex(c => c.other) != -1);
      let normal = order.compositions.filter(x => x.composition.tags.findIndex(c => c.other) == -1);

      order.total = order.compositions.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      order.oAmount = others.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
      order.oTotal = others.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      order.nAmount = normal.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
      order.nTotal = normal.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      if (order.bookingType != BookingType.Employee) {
        let bookingTypeIndex = event.payments.findIndex(x => x.bookingType == order.bookingType);
        if (bookingTypeIndex < 0) bookingTypeIndex = event.payments.push(new PaymentCounter(order.bookingType)) - 1;
        event.payments[bookingTypeIndex].amount++;
        event.payments[bookingTypeIndex].total += order.total;

        let lIndex = event.locations.findIndex(x => x.location.id == order.location.id);
        if (lIndex < 0) lIndex = event.locations.push(new LocationCounter(order.location)) - 1;
        event.locations[lIndex].sales++;
        event.locations[lIndex].total += order.total;

        normal.forEach((normal) => {
          let normalIndex = event.compositions.findIndex(x => x.composition.id == normal.composition.id);
          if (normalIndex < 0) normalIndex = event.compositions.push(new CompositionCounter(normal.composition)) - 1;
          event.compositions[normalIndex].amount += normal.quantity
        })

        others.forEach((other) => {
          let otherIndex = event.others.findIndex(x => x.composition.id == other.composition.id);
          if (otherIndex < 0) otherIndex = event.others.push(new OtherCounter(other.composition)) - 1;
          event.others[otherIndex].amount += other.quantity
          event.others[otherIndex].total += other.quantity * (other.compositionOverride != undefined && other.compositionOverride.price != undefined ? other.compositionOverride.price : other.composition.price)
        })
      }
    })
    event.locations.sort((a, b) => {
      if (a.location.name.toLowerCase() > b.location.name.toLowerCase()) return -1;
      if (a.location.name.toLowerCase() < b.location.name.toLowerCase()) return 1;
      return 0;
    })
    event.compositions.sort((a, b) => {
      return b.amount - a.amount;
    })
    event.others.sort((a, b) => {
      return b.amount - a.amount;
    })
    event.payments.sort((a, b) => {
      return b.total - a.total;
    })
  }

  GetEvent(id: string, location: string) {
    this.eventHttpService.getExtendedEvent(id, location).subscribe(x => {
      this.event = x;
      this.CalculateStats(this.event);
      if (location != "") this.GetLocation(location);
      if (this.Connection == undefined) this.SignalRConnect(this.event.id);

      if (this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_EventReadLocationConfig())) {
        this.LoadLocationConfigData()
      } else {
        this.locations = undefined;
      }
    }, error => {
      console.error(error);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {event: "", location: ""},
        queryParamsHandling: 'merge'
      })
      this.error = error;
      this.details = false;
      this.globalAlertService.createAlertBannerModel("Fehler beim Laden", `Das Event konnte nicht geladen werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  GetLocation(id: string) {
    this.locationHttpService.get(id).subscribe(x => {
      this.location = x;
    }, error => {
      console.error(error);
    })
  }

  Copy(text: string) {
    copy(text, {
      debug: true,
      message: 'Press #{key} to copy',
    });
  }

  ShowForeignTransactionId(order: OrderEdto) {
    if (order.bookingType != 1 || !order.foreignTransactionId) return;

    this.Copy(order.foreignTransactionId);

    this.globalAlertService.createAlertBannerModel("Information", `Transaktionsnummer ${order.foreignTransactionId} in Zwischenablage gespeichert.`, AlertLevel.info, 2000);
    this.globalAlertService.show();
  }

  public suppliers: SupplierDto[] | undefined;

  isMassDownloading(): boolean {
    if (this.events == undefined) return false;
    return this.events.findIndex(x => x.downloading) > -1;
  }

  isMassSelected(): boolean {
    if (this.events == undefined) return false;
    return this.events.findIndex(x => x.selected) > -1;
  }

  supplierSelectorVisible: boolean = false;

  massDownload(supplier: string | undefined = undefined) {
    let events = this.events?.filter(x => x.selected);
    if (!events || events.length == 0) return;

    if (this.oneFile && supplier == undefined) {
      this.supplierSelectorVisible = true;
      return;
    }

    if (this.oneFile) {
      events[0].downloading = true;
      this.eventHttpService.downloads(events.map(x => x.id), supplier ?? '').subscribe({
        next: file => {
          if (file.type === HttpEventType.Response) this.downloadFile(file, events![0]);
        },
        error: error => {
          this.globalAlertService.createAlertBannerModel("Fehler", "Beim Download der Veranstaltungen ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
          this.globalAlertService.show();
          events![0].downloading = false;
        }
      })

      return;
    }

    events.forEach(x => {
      x.downloading = true;
      x.downloading = true;
      this.eventHttpService.download(x.id, "").subscribe(c => {
        if (c.type === HttpEventType.Response) this.downloadFile(c, x);
      }, x => {
        this.globalAlertService.createAlertBannerModel("Fehler", `Beim Download von der Veranstaltung ${x.name} ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
        this.globalAlertService.show();
        x.downloading = false;
      })
    })
  }

  //download = (supplier: string = "") => {
  //  if (supplier == "") {
  //    this.downloading = true;
  //    this.supplierHttpService.list().subscribe(x => {
  //      this.suppliers = x;
  //    }, error => {
  //      this.globalAlertService.createAlertBannerModel("Fehler", "Es ist ein Fehler beim Laden der Daten aufgetreten.", AlertLevel.error, 3000);
  //      this.globalAlertService.show();
  //    })
  //  }
  //  else {
  //    this.downloading = true;
  //    this.eventHttpService.download(this.event?.id ?? "", (supplier == "__full" ? "" : supplier)).subscribe((event) => {
  //      if (event.type === HttpEventType.Response) {
  //        this.downloadFile(event);
  //      }
  //    })
  //    this.suppliers = undefined;
  //  }
  //}

  singleEventSupplierSelectorVisible = false;

  download(supplier: string | undefined = undefined) {
    if (supplier == undefined) {
      this.singleEventSupplierSelectorVisible = true;
      return;
    }

    this.downloading = true;
    this.eventHttpService.download(this.event?.id ?? "", supplier).subscribe({
      next: event => {
        if (event.type === HttpEventType.Response) {
          this.downloadFile(event);
        }
      }
    })
  }

  SetLast(last: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {last: last},
      queryParamsHandling: 'merge'
    })
  }

  private downloadFile = (data: HttpResponse<Blob>, event: EventDto | undefined = undefined) => {
    if (event != undefined) {
      event.downloading = false;
    }

    const downloadedFile = new Blob([data.body!], {type: data.body?.type});
    const a = document.createElement("a");
    a.setAttribute("style", "display: none;");
    document.body.appendChild(a);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";

    const contentDispositionHeader = data.headers.get("content-disposition");
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
    const matches = regex.exec(contentDispositionHeader || '');

    a.download = matches && matches.length > 1 ? matches[1] : "event-stats.xlsx";

    a.click();
    document.body.removeChild(a);
    this.downloading = false;
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }


  previous: string | undefined;


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params.event == undefined ? "" : params.event;
      this.location_id = params.location == undefined ? "" : params.location;
      this.last = params.last == undefined ? 10 : Number.parseInt(params.last);
      this.previous = params.previous;

      if (this.id != "" && this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_EventGetExtendedEvent())) {
        this.details = true;
        this.GetEvent(this.id, this.location_id);
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {event: "", location: ""},
          queryParamsHandling: 'merge'
        })
      }

      this.LoadData();
    })
  }

  ngOnDestroy(): void {
    if (this.Connection != undefined) {
      this.Connection.stop().then(r => {
        console.log(`SignalR Disconnected: ${r}`);
      }, error => {
        console.error(error)
      });
    }
  }

  public Connection: signalR.HubConnection | undefined;

  SignalRConnect(event: string): void {
    this.Connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this.baseUrl + "/hubs/event?event=" + event, {
        accessTokenFactory: () => this.authenticationService.userValue!.jwt,
        //skipNegotiation: true,
        //transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this.Connection.start().then(function () {
      console.log('SignalR connected!');
    }).catch(function (err) {
      return console.error(err);
    });

    this.Connection.on("TransferEventData", () => {
      console.log("Loading new data...");
      if (this.event != null) this.GetEvent(this.event.id, this.location_id);
    })
  }

  protected readonly N5BookingType = BookingType;
  protected readonly open = open;
  protected readonly Platform = Platform;
  protected readonly HubConnectionState = HubConnectionState;
  protected readonly Routes = Routes;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly dlocation = location;
}
