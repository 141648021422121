<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Preisprofile</h1>
    </div>
  </div>
  <div *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_ConfigurationBuilder_Add())" class="pt-4">
    <label for="NewCategoryName" class="block text-sm font-medium leading-6 text-gray-900">Neues Preisprofil</label>
    <form class="mt-2 flex rounded-md shadow-sm">
      <div class="relative flex flex-grow items-stretch focus-within:z-10">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks"
               viewBox="0 0 16 16">
            <path
              d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
            <path
              d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
          </svg>
        </div>
        <input type="text" name="NewCategoryName" id="NewCategoryName" [(ngModel)]="newConfigurationName"
               class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
               placeholder="Name">
      </div>
      <button (click)="AddConfiguration()" type="submit"
              class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
             viewBox="0 0 16 16">
          <path
            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
        </svg>
        Hinzufügen
      </button>
    </form>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-x-8">
    <div class="mt-8">
      <nav class="space-y-1 flex-col" aria-label="Sidebar">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
        <div class="flex items-center"
             *ngFor="let configuration of configurationBuild; let cIndex = index; TrackBy: TrackByIndex;">
          <a (click)="SetActiveConfiguration(configuration)"
             class="w-full hover:bg-gray-50 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer flex items-center rounded-md px-3 py-2 text-sm font-medium"
             aria-current="page"
             [class.bg-gray-100]="activeConfiguration == configuration"
             [class.text-gray-900]="activeConfiguration == configuration">
            <span class="truncate">{{ configuration.name }}</span>
          </a>
          <span class="ml-auto pl-2 text-gray-600 hover:text-yellow-800 cursor-pointer"
                (click)="special = configuration.special">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
              <path
                d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
            </svg>
          </span>
          <span *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_ConfigurationBuilder_Add())"
                class="ml-auto pl-2 text-yellow-600 hover:text-yellow-800 cursor-pointer"
                (click)="CloneConfiguration(configuration)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-node-plus"
                 viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5h2.025zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5zM1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
            </svg>
          </span>
          <span *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Configuration_Delete())"
                class="ml-auto pl-2 text-red-400 hover:text-red-600 cursor-pointer"
                (click)="RemoveConfiguration(configuration)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </span>
        </div>
      </nav>
    </div>
    <div id="content" *ngIf="activeConfiguration" class="col-span-1 lg:col-span-2 xl:col-span-3 mt-8">
      <p class="fw-bold text-xl lg:hidden">{{ activeConfiguration.name }}</p>
      <div>
        <div class="2xl:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select [(ngModel)]="activeTag" id="tabs" name="tabs"
                  class="block w-full rounded-md border-gray-300 border-2 p-1 focus:border-theme-primary-900 focus:ring-theme-primary-900">
            <option *ngFor="let tag of activeConfiguration.tags"
                    [ngValue]="tag">{{ (!tag.active ? "(&cross;) " : "(&check;) ") + tag.name }}
            </option>
          </select>
        </div>
        <div class="hidden 2xl:block">
          <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
            <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
            <a *ngFor="let tag of activeConfiguration.tags; let tIndex = index; TrackBy: TrackByIndex;"
               class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 flex"
               aria-current="page"
               [class.text-gray-900]="activeTag?.id == tag.id" [class.!bg-gray-300]="!tag.active"
               [class.rounded-l-lg]=""
               [class.rounded-r-lg]=""
               (click)="SetActiveTag(tag)"
               [class.line-through]="!tag.active">
              <span class="truncate text-ellipsis w-full">{{ tag.name }}</span>
              <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5"
                    [class.bg-theme-primary-900]="activeTag?.id == tag.id"></span>
            </a>
          </nav>
        </div>
      </div>
      <div class="pt-2 overflow-x-auto" *ngIf="activeConfiguration && activeTag">
        <div class="flex py-3">
          <div class="relative flex items-start">
            <div class="flex h-6 items-center"
                 [ngClass]="{'pointer-events-none opacity-50' : !PermissionService.CheckPermission(PermissionService.Inventory_Override_Tag())}">
              <!--<input [(ngModel)]="activeTag.active" id="tag_active" (ngModelChange)="ToggleTag(activeConfiguration, activeTag)" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-theme-primary-700 focus:ring-theme-primary-700">-->
              <input [readonly]="!PermissionService.CheckPermission(PermissionService.Inventory_Override_Tag())"
                     [(ngModel)]="activeTag.active" (ngModelChange)="ToggleTag(activeConfiguration, activeTag)"
                     id="tag_active" aria-describedby="comments-description" name="comments" type="checkbox"
                     class="h-4 w-4 rounded border-gray-300 text-theme-primary-700 focus:ring-theme-primary-700">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="tag_active" class="font-medium text-gray-900">Aktiv</label>
            </div>
          </div>
        </div>

        <table class="min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" class=""></th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">ID
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Preis</th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"
                *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Override_Composition())">
              <span class="sr-only">Reset</span>
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
          <!--<tr *ngFor="let composition of activeTag.compositions" [class.line-through]="!composition.active">
            <td class="">
              <input [(ngModel)]="composition.active"  (ngModelChange)="ToggleComposition(activeConfiguration, composition)" aria-describedby="comments-description" name="comments" type="checkbox" class="place-self-center h-4 w-4 rounded border-gray-300 text-theme-primary-700 focus:ring-theme-primary-700">
            </td>
            <td class="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ composition.name }}</td>
            <td class="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 xl:table-cell">{{ composition.id }}</td>
            <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{{ composition.price | currency: "EUR" }}</td>
            <td class="whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <a href="#" class="text-theme-primary-700 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
            </td>
          </tr>-->
          <tr *ngFor="let composition of activeTag.compositions; let ccIndex = index; TrackBy: TrackByIndex">
            <td class="">
              <input
                [ngClass]="{'pointer-events-none opacity-50' : !PermissionService.CheckPermission(PermissionService.Inventory_Override_Composition())}"
                [readonly]="!PermissionService.CheckPermission(PermissionService.Inventory_Override_Composition())"
                [(ngModel)]="composition.active"
                (ngModelChange)="SetComposition(activeConfiguration, composition, composition.active, null)"
                aria-describedby="comments-description" name="comments" type="checkbox"
                class="place-self-center h-4 w-4 rounded border-gray-300 text-theme-primary-700 focus:ring-theme-primary-700 mr-1">
            </td>
            <td [class.line-through]="!composition.active" [class.text-red-600]="composition.special"
                class="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              {{ composition.name }}
            </td>
            <td [class.line-through]="!composition.active"
                class="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 xl:table-cell">{{ composition.id }}
            </td>
            <td [class.line-through]="!composition.active" class="whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                [ngClass]="{'pointer-events-none' : !PermissionService.CheckPermission(PermissionService.Inventory_Override_Composition())}">
              <span *ngIf="composition.price != composition.oPrice || composition.special"
                    [class.text-red-400]="composition.price != composition.oPrice || composition.special">{{ composition.oPrice | currency: "EUR" }}&nbsp;</span>
              <span *ngIf="!composition.special" (click)="OverrideCompositionPrice(activeConfiguration, composition)"
                    [class.line-through]="composition.price != composition.oPrice"
                    class="hover:text-theme-primary-700 cursor-pointer">
                {{ composition.price | currency: "EUR" }}
              </span>
            </td>
            <td class="whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Override_Composition())">
              <a *ngIf="!composition.special" (click)="SetComposition(activeConfiguration, composition)"
                 class="no-underline	cursor-pointer text-yellow-600 hover:text-yellow-900">Reset<span
                class="sr-only"></span></a>
            </td>
          </tr>
          <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="special" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto lg:pl-72">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="grid grid-cols-2 border-b-2 border-gray-400 content-center pb-2">
            <div class="flex">
              <p class="fw-bold text-lg place-self-center">Special Konfiguration</p>
            </div>
          </div>
        </div>
        <!-- todo check permissions -->
        <div class="grid grid-cols-1 pt-2 gap-2"
             [ngClass]="{'!pointer-events-none !cursor-normal': !PermissionService.CheckPermission(PermissionService.Inventory_ConfigurationBuilder_SetSpecial())}">
          <div class="flex mt-3">
            <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
            <button (click)="special.active = !special.active" [class.!bg-theme-primary-700]="special.active"
                    type="button"
                    class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2"
                    role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span aria-hidden="true" [class.!translate-x-5]="special.active"
                    class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
            </button>
            <span class="ml-3 text-sm" id="SpecialToggle">
              <span class="font-medium text-gray-900">Aktiviert</span>
            </span>
          </div>
          <div>
            <div class="mt-2 flex rounded-md shadow-sm">
              <span
                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Name</span>
              <input [(ngModel)]="special.name" type="text" name="special_name" id="special_name"
                     class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 px-2"
                     placeholder="Name">
            </div>
          </div>
          <div>
            <div class="mt-2 flex rounded-md shadow-sm">
              <span
                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Farbe</span>
              <input [ngModel]="'#' + special.color" (ngModelChange)="SetSpecialColor($event)" type="color"
                     name="special_color" id="special_color"
                     class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:grid-cols-2 sm:gap-3">
          <button (click)="special = undefined" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0">
            Abbrechen
          </button>
          <button
            *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_ConfigurationBuilder_SetSpecial())"
            (click)="ConfigureSpecial(special!)" type="button"
            class="mt-2 sm:!mt-0 inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-banner [(error)]="error"></app-error-banner>
