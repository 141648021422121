<div class="w-full">
  <div *ngIf="event == undefined && !details" class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Events</h1>
      </div>
    </div>
    <div class="pt-4">
      <ul role="list"
          class="divide-y divide-gray-100 overflow-hidden bg-white shadow-md ring-1 ring-gray-900/5 sm:rounded-xl">
        <li *ngFor="let event of filtered_events" [routerLink]="['/owner/events']"
            [queryParams]="{ event: event.id, shard: event.shard.id }" queryParamsHandling="merge"
            class="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer sm:px-6">
          <div class="flex gap-x-4">
            <img class="h-12 w-12 p-2 flex-none rounded-full bg-gray-700" src="./assets/images/icon_nobackground_md.png"
                 alt="">
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                <a>
                  <span class="absolute inset-x-0 -top-px bottom-0"></span>
                  {{ event.name }}
                </a>
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
                <span class="relative truncate">{{ event.id }}</span>
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-800 fw-bold">
                <span class="relative truncate">{{ event.company.name }} &#64; {{ event.shard.name }}</span>
              </p>
            </div>
          </div>
          <div class="flex items-center gap-x-4">
            <div class="hidden sm:flex sm:flex-col sm:items-end">
              <p class="text-sm leading-6 text-gray-900">{{ (event.start) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
              <p class="text-sm leading-6 text-gray-900">{{ (event.end) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
            </div>
            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </li>
      </ul>

    </div>
  </div>

  <div *ngIf="event" class="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
    <div class="grid grid-cols-1">
      <div class="flex w-full">
        <svg *ngIf="location_id == ''" [routerLink]="['/owner/events']" [queryParams]="{ event: '', shard: '' }"
             queryParamsHandling="merge" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
             class="col-span-1 mr-2 self-center bi bi-arrow-left-circle hover:text-theme-primary-700 hover:cursor-pointer"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        <svg *ngIf="location_id != ''" [routerLink]="['/owner/events']"
             [queryParams]="{ event: event.id, shard: shard, location: '' }" queryParamsHandling="merge"
             xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
             class="col-span-1 mr-2 self-center bi bi-arrow-left-circle hover:text-theme-primary-700 hover:cursor-pointer"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        <div class="flex-grow"></div>
      </div>
      <h1
        class="md:text-center pt-4 pb-4 flex-grow col-span-11 md:col-span-8 self-center font-semibold leading-6 text-gray-900 md:!text-3xl">{{ event.name }}{{ !location ? '' : ':&nbsp;' + location.name }}</h1>
    </div>

    <div class="md:hidden grid grid-cols-1 gap-3">
      <div class="bg-white shadow-lg w-full rounded-lg">
        <div class="px-3 py-2">
          <p class="text-gray-600 text-sm fw-bold">Gesamtumsatz</p>
          <p class="text-2xl fw-bold">{{ event.oTotal + event.nTotal | currency: "EUR" }}</p>
        </div>
      </div>
      <div class="bg-white shadow-lg w-full rounded-lg">
        <div class="grid grid-cols-2 divide-x divide-y">
          <div class="px-3 py-2">
            <p class="text-gray-600 text-sm fw-bold">Getränke</p>
            <div class="grid grid-cols-2">
              <p>{{ event.nAmount }}x</p>
              <p class="text-lg text-end">{{ event.nTotal | currency: "EUR" }}</p>
            </div>
          </div>
          <div class="px-3 py-2">
            <p class="text-gray-600 text-sm fw-bold">Sonstiges</p>
            <div class="grid grid-cols-2">
              <p>{{ event.oAmount }}x</p>
              <p class="text-lg text-end">{{ event.oTotal | currency: "EUR" }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="rounded-md max-w-7xl mx-auto hidden md:flex">
      <div class="mx-auto max-w-7xl w-full">
        <div class="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 xl:grid-cols-4 gap-3">
          <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg w-full">
            <p class="text-sm font-medium leading-6 text-gray-700 inline-flex">Sonstiges</p>
            <p class="mt-2 grid grid-cols-2 items-baseline gap-x-2">
              <span
                class="text-3xl font-semibold tracking-tight text-gray-900">{{ event.oTotal | currency: "EUR" }}</span>
              <span class="text-3xl font-semibold tracking-tight text-gray-900 justify-self-end">{{ event.oAmount }}
                x</span>
            </p>
          </div>
          <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
            <p class="text-sm font-medium leading-6 text-gray-700 inline-flex">Getränke</p>
            <p class="mt-2 flex items-baseline gap-x-2 grid grid-cols-2">
              <span
                class="text-3xl font-semibold tracking-tight text-gray-900 ">{{ event.nTotal | currency: "EUR" }}</span>
              <span class="text-3xl font-semibold tracking-tight text-gray-900 justify-self-end">{{ event.nAmount }}
                x</span>
            </p>
          </div>
          <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
            <p class="text-sm font-medium leading-6 text-gray-700">Gesamtbetrag</p>
            <p class="mt-2 flex items-baseline gap-x-2 grid grid-cols-2">
              <span
                class="text-3xl font-semibold tracking-tight text-gray-900">{{ event.nTotal + event.oTotal | currency: "EUR" }}</span>
            </p>
          </div>
          <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
            <p class="text-sm font-medium leading-6 text-gray-700">Bestseller</p>
            <p class="mt-2 flex items-baseline gap-x-2">
              <span
                class="text-4xl font-semibold tracking-tight text-gray-900">{{ (event.compositions.length > 0 ? event.compositions[0].composition.name : "") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <ul role="list"
        class="pt-4 grid grid-cols-1 gap-x-3 gap-y-4 lg:grid-cols-2 grid-rows-1 lg:grid-rows-2 h-50 max-w-7xl mx-auto"
        style="max-height: 50vh;">
      <li [class.hidden]="views[view_index%4]!='location'"
          class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt"
               viewBox="0 0 16 16">
            <path
              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          </svg>
          <div class="text-sm font-medium leading-6 text-gray-900">Verkaufspunkt: {{ event.total | currency: "EUR" }}
          </div>
          <div (click)="view_index = view_index+1"
               class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-arrow-left-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
          </div>
        </div>
        <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
          <div *ngFor="let location of event.locations"
               class="flex justify-between gap-x-4 py-1 hover:underline cursor-pointer"
               [routerLink]="['/events']" [queryParams]="{ location: location.location.id }"
               queryParamsHandling="merge">
            <dt class="text-gray-500 fw-normal">{{ location.location.name }}</dt>
            <dd class="text-gray-700">{{ location.total | currency: "EUR" }}</dd>
          </div>
        </dl>
      </li>

      <li [class.hidden]="views[view_index%4]!='others'"
          class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-ticket-perforated" viewBox="0 0 16 16">
            <path
              d="M4 4.85v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Z"/>
            <path
              d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3h-13ZM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9V4.5Z"/>
          </svg>
          <div class="text-sm font-medium leading-6 text-gray-900">Sonstiges: {{ event.oTotal | currency: "EUR" }}</div>
          <div (click)="view_index = view_index+1"
               class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-arrow-left-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
          </div>
        </div>
        <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
          <div *ngFor="let other of event.others" class="flex justify-between gap-x-4 py-1">
            <dt class="text-gray-500 fw-normal">{{ other.amount }}x&nbsp;{{ other.composition.name }}</dt>
            <dd class="text-gray-700">{{ other.total | currency: "EUR" }}</dd>
          </div>
        </dl>
      </li>

      <li [class.hidden]="views[view_index%4]!='payment'"
          class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wallet"
               viewBox="0 0 16 16">
            <path
              d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"/>
          </svg>
          <div class="text-sm font-medium leading-6 text-gray-900">
            Transaktionstypen: {{ (event.oTotal + event.nTotal) | currency: "EUR" }}
          </div>
          <div (click)="view_index = view_index+1"
               class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-arrow-left-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
          </div>
        </div>
        <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
          <div *ngFor="let payment of event.payments" class="flex justify-between gap-x-4 py-1">
            <dt class="text-gray-500 fw-normal">{{ payment.amount }}
              x {{ n5paymentMapper.GetString(payment.bookingType) }}
            </dt>
            <dd class="text-gray-700">{{ payment.total | currency: "EUR" }}</dd>
          </div>
        </dl>
      </li>

      <li [class.hidden]="views[view_index%4]!='drinks'"
          class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cup-straw"
               viewBox="0 0 16 16">
            <path
              d="M13.902.334a.5.5 0 0 1-.28.65l-2.254.902-.4 1.927c.376.095.715.215.972.367.228.135.56.396.56.82 0 .046-.004.09-.011.132l-.962 9.068a1.28 1.28 0 0 1-.524.93c-.488.34-1.494.87-3.01.87-1.516 0-2.522-.53-3.01-.87a1.28 1.28 0 0 1-.524-.93L3.51 5.132A.78.78 0 0 1 3.5 5c0-.424.332-.685.56-.82.262-.154.607-.276.99-.372C5.824 3.614 6.867 3.5 8 3.5c.712 0 1.389.045 1.985.127l.464-2.215a.5.5 0 0 1 .303-.356l2.5-1a.5.5 0 0 1 .65.278zM9.768 4.607A13.991 13.991 0 0 0 8 4.5c-1.076 0-2.033.11-2.707.278A3.284 3.284 0 0 0 4.645 5c.146.073.362.15.648.222C5.967 5.39 6.924 5.5 8 5.5c.571 0 1.109-.03 1.588-.085l.18-.808zm.292 1.756C9.445 6.45 8.742 6.5 8 6.5c-1.133 0-2.176-.114-2.95-.308a5.514 5.514 0 0 1-.435-.127l.838 8.03c.013.121.06.186.102.215.357.249 1.168.69 2.438.69 1.27 0 2.081-.441 2.438-.69.042-.029.09-.094.102-.215l.852-8.03a5.517 5.517 0 0 1-.435.127 8.88 8.88 0 0 1-.89.17zM4.467 4.884s.003.002.005.006l-.005-.006zm7.066 0-.005.006c.002-.004.005-.006.005-.006zM11.354 5a3.174 3.174 0 0 0-.604-.21l-.099.445.055-.013c.286-.072.502-.149.648-.222z"/>
          </svg>
          <div class="text-sm font-medium leading-6 text-gray-900">Getränke: {{ event.nAmount }}</div>
          <div (click)="view_index = view_index+1"
               class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-arrow-left-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
          </div>
        </div>
        <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
          <div *ngFor="let composition of event.compositions" class="flex justify-between gap-x-4 py-1">
            <dt class="text-gray-500 fw-normal">{{ composition.composition.name }}</dt>
            <dd class="text-gray-700">{{ composition.amount }}</dd>
          </div>
        </dl>
      </li>


    </ul>

    <div class="">
      <!--<div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">

        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button type="button" class="block rounded-md bg-theme-primary-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">Export</button>
        </div>
      </div>-->
      <div class="mt-8 flow-root">
        <div class=" -my-2 overflow-x-auto">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
              <tr>
                <th scope="col"
                    class="whitespace-nowrap py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900">Getränke
                </th>
                <th scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                  Einzelpreise
                </th>
                <th (click)="SortBy('price')" scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <div class="inline-flex">
                    <div class="grid grid-cols-1 gap-1">
                      <svg [class.opacity-100]="this.field == 'price' && !this.inverse"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                        <path
                          d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                      <svg [class.opacity-100]="this.field == 'price' && this.inverse"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                        <path
                          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </div>
                    <a class="cursor-pointer select-none place-self-center pl-2 text-sm">Gesamt</a>
                  </div>
                </th>
                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Verkaufspunkt
                </th>
                <th scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                  Profil
                </th>
                <th (click)="SortBy('date')" scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <div class="inline-flex">
                    <div class="grid grid-cols-1 gap-1">
                      <svg [class.opacity-100]="this.field == 'date' && !this.inverse"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                        <path
                          d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                      <svg [class.opacity-100]="this.field == 'date' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                           width="16" height="16" fill="currentColor"
                           class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                        <path
                          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </div>
                    <a class="cursor-pointer select-none place-self-center pl-2 text-sm">Zeitstempel</a>
                  </div>
                </th>
                <th scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell">
                  Transaction ID
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr *ngFor="let order of event.orders; let index = index; TrackBy: TrackByIndex"
                  [class.bg-gray-200]="order.bookingType == N5BookingType.Employee">
                <td class="whitespace-nowrap py-2 pl-2 pr-3 text-sm text-gray-500">
                  <p *ngFor="let composition of order.compositions">
                    <span>{{ composition.quantity }}x&nbsp;</span>
                    <span>{{ composition.composition.name }}</span>
                  </p>
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden sm:block">
                  <p *ngFor="let composition of order.compositions">
                    <span class="text-red-600"
                          *ngIf="composition.compositionOverride && composition.compositionOverride.price != null">{{ composition.compositionOverride.price | currency: "EUR" }}</span>
                    <span
                      *ngIf="!(composition.compositionOverride && composition.compositionOverride.price != null)">{{ composition.composition.price | currency: "EUR" }}</span>
                  </p>
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  <div class="inline-flex">
                    <svg *ngIf="order.bookingType == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         fill="currentColor" class="bi bi-cash-coin mr-2 place-self-center" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                      <path
                        d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                      <path
                        d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                      <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
                    </svg>
                    <svg *ngIf="order.bookingType == 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         fill="currentColor" class="bi bi-credit-card-2-back-fill mr-2 place-self-center"
                         viewBox="0 0 16 16">
                      <path
                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
                    </svg>
                    <svg *ngIf="order.bookingType == 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         fill="currentColor" class="bi bi-person-circle mr-2 place-self-center" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                      <path fill-rule="evenodd"
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                    </svg>
                    <span class="place-self-center">
                      {{ order.oTotal + order.nTotal | currency: "EUR" }}
                    </span>
                  </div>
                </td>
                <td
                  class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 cursor-pointer hover:text-theme-primary-700"
                  [routerLink]="['/events']" [queryParams]="{ location: order.location.id }"
                  queryParamsHandling="merge">{{ order.location.name }}
                </td>
                <td
                  class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 hidden md:table-cell">{{ order.configuration.name }}
                </td>
                <td
                  class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ (order.created) | date : "dd.MM.YYYY, HH:mm" }}
                </td>


                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden xl:table-cell">{{ order.id }}</td>
              </tr>

              <!-- More transactions... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-error-banner [(error)]="error"></app-error-banner>
