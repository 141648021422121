import {IModelBase, ModelBase} from "../../_modules/company/x-models/model-base";
import {EmployeeNl} from "../../_modules/company/x-models/employee-nl";
import {jsonIgnore} from "json-ignore";
import {Tuple} from "../_generic/tuple";
import {EmployeeStats} from "../../_modules/company/x-models/employee-stats";

export class EmployeeEventResponseDto extends ModelBase implements IModelBase {
  comments: string = "";
  employee: EmployeeNl = new EmployeeNl();
  shardId: string = "";
  shardName: string = "";
  color: string = "";
  eventId: string = "";

  start: Date | undefined;
  end: Date | undefined;
  e_start: Date | undefined;
  e_end: Date | undefined;

  // shift maker
  responses: EmployeeEventResponseDto[] | undefined;
  locationId: string = "drop_base";
  index: number = 0;

  // employeeStats: EmployeeStats = new EmployeeStats();

  manuallyCreated: boolean = false;

  updatedEmployeeStats: any = {};

  expanded: boolean = false;
}

export class EmployeeEventResponseDtoMinimal {
  id: string = "";

  start: Date | undefined;
  end: Date | undefined;

  e_start: Date | undefined;
  e_end: Date | undefined;

  // shift maker
  locationId: string = "drop_base";
  index: number = 0;

  employeeId: string | undefined;

  manuallyCreated: boolean = false;

  active: boolean = true;

  constructor(response: EmployeeEventResponseDto) {
    this.active = response.active;
    this.id = response.id;

    this.start = response.start;
    this.end = response.end;

    this.e_start = response.e_start;
    this.e_end = response.e_end;

    this.locationId = response.locationId;
    this.index = response.index;

    this.employeeId = response.employee?.id

    this.manuallyCreated = response.manuallyCreated;
  }
}
