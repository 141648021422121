<div>
  <div (click)="Filter()" class="relative !cursor-pointer" (keydown.enter)="$event.preventDefault()">
    <input [class.user-select-none]="!filter" [disabled]="!filter" #searchInput [(ngModel)]="search"
           (ngModelChange)="Filter()" id="combobox" type="text"
           class="w-full border-2 border-gray-200 bg-white py-1.5 pl-3 pr-12 text-gray-900 focus:ring-1 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 {{classes}}"
           role="combobox" aria-controls="options" aria-expanded="false">
    <button (click)="Filter()" type="button"
            class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            [ngClass]="{'left-0': !filter}">
      <svg class="h-5 w-5 text-gray-400 ml-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
              clip-rule="evenodd"/>
      </svg>
    </button>

    <ul [class.py-1]="FilteredObjects.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        id="options" role="listbox">
      <!--
        Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Active: "text-white bg-theme-primary-700", Not Active: "text-gray-900"
      -->
      <li *ngFor="let object of FilteredObjects" (mouseover)="hover = FilteredObjects.indexOf(object)"
          [ngClass]="{'text-white bg-theme-primary-700': FilteredObjects.indexOf(object) == hover}"
          (click)="SetActive(object)"
          class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-theme-primary-900 hover:text-white"
          id="option-0" role="option" tabindex="-1">
        <!-- Selected: "font-semibold" -->
        <span class="block truncate">{{ object[displayProperty] }}</span>

        <!--
          Checkmark, only display for selected option.

          Active: "text-white", Not Active: "text-theme-primary-700"
        -->
        <span *ngIf="selection && selection[idProperty] == object[idProperty]"
              class="absolute inset-y-0 right-0 flex items-center pr-4 text-theme-primary-700"
              [class.text-white]="FilteredObjects.indexOf(object) == hover">
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"/>
          </svg>
        </span>
      </li>

      <!-- More items... -->
    </ul>
  </div>
</div>
