import {Injectable} from '@angular/core';
import {PermissionSet} from "./Models/PermissionSet";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  GetDeactivatedPermission(): PermissionSet {
    return PermissionService.deactivated;
  }

  static deactivated = new PermissionSet(
    "Owner",
    []
  )


  Admin(): PermissionSet {
    return PermissionService.admin;
  }

  static admin = new PermissionSet("Admin", ["Admin"]);

  Administration_Contract_GetCurretCompany(): PermissionSet {
    return PermissionService.administration_contract_getcurretcompany;
  }

  static administration_contract_getcurretcompany = new PermissionSet("Admin", ["Administration.Contract.GetCurretCompany"]);

  Administration_MigrationAssistant_Event_Register_Patch_20231003(): PermissionSet {
    return PermissionService.administration_migrationassistant_event_register_patch_20231003;
  }

  static administration_migrationassistant_event_register_patch_20231003 = new PermissionSet("Admin", ["Administration.MigrationAssistant.Event_Register_Patch_20231003"]);

  Administration_MigrationAssistant_GetPatches(): PermissionSet {
    return PermissionService.administration_migrationassistant_getpatches;
  }

  static administration_migrationassistant_getpatches = new PermissionSet("Admin", ["Administration.MigrationAssistant.GetPatches"]);

  Administration_MigrationAssistant_InventoryNumber_Patch_20230909(): PermissionSet {
    return PermissionService.administration_migrationassistant_inventorynumber_patch_20230909;
  }

  static administration_migrationassistant_inventorynumber_patch_20230909 = new PermissionSet("Admin", ["Administration.MigrationAssistant.InventoryNumber_Patch_20230909"]);

  Administration_MigrationAssistant_Vat_Patch_20230912(): PermissionSet {
    return PermissionService.administration_migrationassistant_vat_patch_20230912;
  }

  static administration_migrationassistant_vat_patch_20230912 = new PermissionSet("Admin", ["Administration.MigrationAssistant.Vat_Patch_20230912"]);

  Administration_Permission_List(): PermissionSet {
    return PermissionService.administration_permission_list;
  }

  static administration_permission_list = new PermissionSet("Admin", ["Administration.Permission.List"]);

  Administration_Role_Add(): PermissionSet {
    return PermissionService.administration_role_add;
  }

  static administration_role_add = new PermissionSet("Admin", ["Administration.Role.Add"]);

  Administration_Role_AddUser(): PermissionSet {
    return PermissionService.administration_role_adduser;
  }

  static administration_role_adduser = new PermissionSet("Admin", ["Administration.Role.AddUser"]);

  Administration_Role_Delete(): PermissionSet {
    return PermissionService.administration_role_delete;
  }

  static administration_role_delete = new PermissionSet("Admin", ["Administration.Role.Delete"]);

  Administration_Role_Get(): PermissionSet {
    return PermissionService.administration_role_get;
  }

  static administration_role_get = new PermissionSet("Admin", ["Administration.Role.Get"]);

  Administration_Role_List(): PermissionSet {
    return PermissionService.administration_role_list;
  }

  static administration_role_list = new PermissionSet("Admin", ["Administration.Role.List"]);

  Administration_Role_RemoveUser(): PermissionSet {
    return PermissionService.administration_role_removeuser;
  }

  static administration_role_removeuser = new PermissionSet("Admin", ["Administration.Role.RemoveUser"]);

  Administration_Role_TogglePermission(): PermissionSet {
    return PermissionService.administration_role_togglepermission;
  }

  static administration_role_togglepermission = new PermissionSet("Admin", ["Administration.Role.TogglePermission"]);

  Administration_Settings_GetLogo(): PermissionSet {
    return PermissionService.administration_settings_getlogo;
  }

  static administration_settings_getlogo = new PermissionSet("Admin", ["Administration.Settings.GetLogo"]);

  Administration_Settings_GetTimezone(): PermissionSet {
    return PermissionService.administration_settings_gettimezone;
  }

  static administration_settings_gettimezone = new PermissionSet("Admin", ["Administration.Settings.GetTimezone"]);

  Administration_Settings_SetLogo(): PermissionSet {
    return PermissionService.administration_settings_setlogo;
  }

  static administration_settings_setlogo = new PermissionSet("Admin", ["Administration.Settings.SetLogo"]);

  Administration_Settings_SetTimezone(): PermissionSet {
    return PermissionService.administration_settings_settimezone;
  }

  static administration_settings_settimezone = new PermissionSet("Admin", ["Administration.Settings.SetTimezone"]);

  Administration_User_Add(): PermissionSet {
    return PermissionService.administration_user_add;
  }

  static administration_user_add = new PermissionSet("Admin", ["Administration.User.Add"]);

  Administration_User_Delete(): PermissionSet {
    return PermissionService.administration_user_delete;
  }

  static administration_user_delete = new PermissionSet("Admin", ["Administration.User.Delete"]);

  Administration_User_Get(): PermissionSet {
    return PermissionService.administration_user_get;
  }

  static administration_user_get = new PermissionSet("Admin", ["Administration.User.Get"]);

  Administration_User_List(): PermissionSet {
    return PermissionService.administration_user_list;
  }

  static administration_user_list = new PermissionSet("Admin", ["Administration.User.List"]);

  Administration_User_ListCompanyUser(): PermissionSet {
    return PermissionService.administration_user_listcompanyuser;
  }

  static administration_user_listcompanyuser = new PermissionSet("Admin", ["Administration.User.ListCompanyUser"]);

  Administration_User_ListOwners(): PermissionSet {
    return PermissionService.administration_user_listowners;
  }

  static administration_user_listowners = new PermissionSet("Admin", ["Administration.User.ListOwners"]);

  Analysis_EventAnalysis_FetchEventData(): PermissionSet {
    return PermissionService.analysis_eventanalysis_fetcheventdata;
  }

  static analysis_eventanalysis_fetcheventdata = new PermissionSet("Admin", ["Analysis.EventAnalysis.FetchEventData"]);

  Analysis_EventAnalysis_GetEventsLike(): PermissionSet {
    return PermissionService.analysis_eventanalysis_geteventslike;
  }

  static analysis_eventanalysis_geteventslike = new PermissionSet("Admin", ["Analysis.EventAnalysis.GetEventsLike"]);

  Automation_Scheduler_DeleteGroup(): PermissionSet {
    return PermissionService.automation_scheduler_deletegroup;
  }

  static automation_scheduler_deletegroup = new PermissionSet("Admin", ["Automation.Scheduler.DeleteGroup"]);

  Automation_Scheduler_GetSchedulerGroup(): PermissionSet {
    return PermissionService.automation_scheduler_getschedulergroup;
  }

  static automation_scheduler_getschedulergroup = new PermissionSet("Admin", ["Automation.Scheduler.GetSchedulerGroup"]);

  Automation_Scheduler_GetSchedulerObjects(): PermissionSet {
    return PermissionService.automation_scheduler_getschedulerobjects;
  }

  static automation_scheduler_getschedulerobjects = new PermissionSet("Admin", ["Automation.Scheduler.GetSchedulerObjects"]);

  Automation_Scheduler_ScheduleGroup(): PermissionSet {
    return PermissionService.automation_scheduler_schedulegroup;
  }

  static automation_scheduler_schedulegroup = new PermissionSet("Admin", ["Automation.Scheduler.ScheduleGroup"]);

  Booking_Order_GetOrderCountFromShard(): PermissionSet {
    return PermissionService.booking_order_getordercountfromshard;
  }

  static booking_order_getordercountfromshard = new PermissionSet("Admin", ["Booking.Order.GetOrderCountFromShard"]);

  Booking_Order_List(): PermissionSet {
    return PermissionService.booking_order_list;
  }

  static booking_order_list = new PermissionSet("Admin", ["Booking.Order.List"]);

  Booking_Order_SetActiveState(): PermissionSet {
    return PermissionService.booking_order_setactivestate;
  }

  static booking_order_setactivestate = new PermissionSet("Admin", ["Booking.Order.SetActiveState"]);

  Booking_Table_Add(): PermissionSet {
    return PermissionService.booking_table_add;
  }

  static booking_table_add = new PermissionSet("Admin", ["Booking.Table.Add"]);

  Booking_Table_Delete(): PermissionSet {
    return PermissionService.booking_table_delete;
  }

  static booking_table_delete = new PermissionSet("Admin", ["Booking.Table.Delete"]);

  Booking_Table_Get(): PermissionSet {
    return PermissionService.booking_table_get;
  }

  static booking_table_get = new PermissionSet("Admin", ["Booking.Table.Get"]);

  Booking_Table_List(): PermissionSet {
    return PermissionService.booking_table_list;
  }

  static booking_table_list = new PermissionSet("Admin", ["Booking.Table.List"]);

  Booking_Table_Upsert(): PermissionSet {
    return PermissionService.booking_table_upsert;
  }

  static booking_table_upsert = new PermissionSet("Admin", ["Booking.Table.Upsert"]);

  Booking_Table_UpsertList(): PermissionSet {
    return PermissionService.booking_table_upsertlist;
  }

  static booking_table_upsertlist = new PermissionSet("Admin", ["Booking.Table.UpsertList"]);

  Company_Company_GetCompanyShards(): PermissionSet {
    return PermissionService.company_company_getcompanyshards;
  }

  static company_company_getcompanyshards = new PermissionSet("Admin", ["Company.Company.GetCompanyShards"]);

  Company_Company_List(): PermissionSet {
    return PermissionService.company_company_list;
  }

  static company_company_list = new PermissionSet("Admin", ["Company.Company.List"]);

  Company_Employee_Add(): PermissionSet {
    return PermissionService.company_employee_add;
  }

  static company_employee_add = new PermissionSet("Admin", ["Company.Employee.Add"]);

  Company_Employee_AllowEmployeeLogin(): PermissionSet {
    return PermissionService.company_employee_allowemployeelogin;
  }

  static company_employee_allowemployeelogin = new PermissionSet("Admin", ["Company.Employee.AllowEmployeeLogin"]);

  Company_EmployeeAttribute_Add(): PermissionSet {
    return PermissionService.company_employeeattribute_add;
  }

  static company_employeeattribute_add = new PermissionSet("Admin", ["Company.EmployeeAttribute.Add"]);

  Company_EmployeeAttribute_ChangeName(): PermissionSet {
    return PermissionService.company_employeeattribute_changename;
  }

  static company_employeeattribute_changename = new PermissionSet("Admin", ["Company.EmployeeAttribute.ChangeName"]);

  Company_EmployeeAttribute_ChangeShortName(): PermissionSet {
    return PermissionService.company_employeeattribute_changeshortname;
  }

  static company_employeeattribute_changeshortname = new PermissionSet("Admin", ["Company.EmployeeAttribute.ChangeShortName"]);

  Company_EmployeeAttribute_Delete(): PermissionSet {
    return PermissionService.company_employeeattribute_delete;
  }

  static company_employeeattribute_delete = new PermissionSet("Admin", ["Company.EmployeeAttribute.Delete"]);

  Company_EmployeeAttribute_Get(): PermissionSet {
    return PermissionService.company_employeeattribute_get;
  }

  static company_employeeattribute_get = new PermissionSet("Admin", ["Company.EmployeeAttribute.Get"]);

  Company_EmployeeAttribute_List(): PermissionSet {
    return PermissionService.company_employeeattribute_list;
  }

  static company_employeeattribute_list = new PermissionSet("Admin", ["Company.EmployeeAttribute.List"]);

  Company_EmployeeAttribute_SetEmployeeAttributes(): PermissionSet {
    return PermissionService.company_employeeattribute_setemployeeattributes;
  }

  static company_employeeattribute_setemployeeattributes = new PermissionSet("Admin", ["Company.EmployeeAttribute.SetEmployeeAttributes"]);

  Company_Employee_ChangeName(): PermissionSet {
    return PermissionService.company_employee_changename;
  }

  static company_employee_changename = new PermissionSet("Admin", ["Company.Employee.ChangeName"]);

  Company_Employee_Delete(): PermissionSet {
    return PermissionService.company_employee_delete;
  }

  static company_employee_delete = new PermissionSet("Admin", ["Company.Employee.Delete"]);

  Company_Employee_EmployeeSalesList(): PermissionSet {
    return PermissionService.company_employee_employeesaleslist;
  }

  static company_employee_employeesaleslist = new PermissionSet("Admin", ["Company.Employee.EmployeeSalesList"]);

  Company_Employee_EmployeeSalesOrders(): PermissionSet {
    return PermissionService.company_employee_employeesalesorders;
  }

  static company_employee_employeesalesorders = new PermissionSet("Admin", ["Company.Employee.EmployeeSalesOrders"]);

  Company_Employee_Get(): PermissionSet {
    return PermissionService.company_employee_get;
  }

  static company_employee_get = new PermissionSet("Admin", ["Company.Employee.Get"]);

  Company_Employee_GetEmployeeShiftList(): PermissionSet {
    return PermissionService.company_employee_getemployeeshiftlist;
  }

  static company_employee_getemployeeshiftlist = new PermissionSet("Admin", ["Company.Employee.GetEmployeeShiftList"]);

  Company_Employee_GetRestricted(): PermissionSet {
    return PermissionService.company_employee_getrestricted;
  }

  static company_employee_getrestricted = new PermissionSet("Admin", ["Company.Employee.GetRestricted"]);

  Company_Employee_List(): PermissionSet {
    return PermissionService.company_employee_list;
  }

  static company_employee_list = new PermissionSet("Admin", ["Company.Employee.List"]);

  Company_Employee_ListMinimal(): PermissionSet {
    return PermissionService.company_employee_listminimal;
  }

  static company_employee_listminimal = new PermissionSet("Admin", ["Company.Employee.ListMinimal"]);

  Company_Employee_ListNl(): PermissionSet {
    return PermissionService.company_employee_listnl;
  }

  static company_employee_listnl = new PermissionSet("Admin", ["Company.Employee.ListNl"]);

  Company_Employee_RequestVerification(): PermissionSet {
    return PermissionService.company_employee_requestverification;
  }

  static company_employee_requestverification = new PermissionSet("Admin", ["Company.Employee.RequestVerification"]);

  Company_Employee_ResetSocial(): PermissionSet {
    return PermissionService.company_employee_resetsocial;
  }

  static company_employee_resetsocial = new PermissionSet("Admin", ["Company.Employee.ResetSocial"]);

  Company_Employee_SetNewPassword(): PermissionSet {
    return PermissionService.company_employee_setnewpassword;
  }

  static company_employee_setnewpassword = new PermissionSet("Admin", ["Company.Employee.SetNewPassword"]);

  Company_Employee_SetPublicProperty(): PermissionSet {
    return PermissionService.company_employee_setpublicproperty;
  }

  static company_employee_setpublicproperty = new PermissionSet("Admin", ["Company.Employee.SetPublicProperty"]);

  Company_Employee_SetSocial(): PermissionSet {
    return PermissionService.company_employee_setsocial;
  }

  static company_employee_setsocial = new PermissionSet("Admin", ["Company.Employee.SetSocial"]);

  Company_EmploymentRelationship_Add(): PermissionSet {
    return PermissionService.company_employmentrelationship_add;
  }

  static company_employmentrelationship_add = new PermissionSet("Admin", ["Company.EmploymentRelationship.Add"]);

  Company_EmploymentRelationship_ChangeName(): PermissionSet {
    return PermissionService.company_employmentrelationship_changename;
  }

  static company_employmentrelationship_changename = new PermissionSet("Admin", ["Company.EmploymentRelationship.ChangeName"]);

  Company_EmploymentRelationship_Delete(): PermissionSet {
    return PermissionService.company_employmentrelationship_delete;
  }

  static company_employmentrelationship_delete = new PermissionSet("Admin", ["Company.EmploymentRelationship.Delete"]);

  Company_EmploymentRelationship_Get(): PermissionSet {
    return PermissionService.company_employmentrelationship_get;
  }

  static company_employmentrelationship_get = new PermissionSet("Admin", ["Company.EmploymentRelationship.Get"]);

  Company_EmploymentRelationship_GetEventEmploymentCosts(): PermissionSet {
    return PermissionService.company_employmentrelationship_geteventemploymentcosts;
  }

  static company_employmentrelationship_geteventemploymentcosts = new PermissionSet("Admin", ["Company.EmploymentRelationship.GetEventEmploymentCosts"]);

  Company_EmploymentRelationship_GetEventsEmploymentCosts(): PermissionSet {
    return PermissionService.company_employmentrelationship_geteventsemploymentcosts;
  }

  static company_employmentrelationship_geteventsemploymentcosts = new PermissionSet("Admin", ["Company.EmploymentRelationship.GetEventsEmploymentCosts"]);

  Company_EmploymentRelationship_List(): PermissionSet {
    return PermissionService.company_employmentrelationship_list;
  }

  static company_employmentrelationship_list = new PermissionSet("Admin", ["Company.EmploymentRelationship.List"]);

  Company_EmploymentRelationship_SetRelationship(): PermissionSet {
    return PermissionService.company_employmentrelationship_setrelationship;
  }

  static company_employmentrelationship_setrelationship = new PermissionSet("Admin", ["Company.EmploymentRelationship.SetRelationship"]);

  Company_EmploymentRelationship_SetValues(): PermissionSet {
    return PermissionService.company_employmentrelationship_setvalues;
  }

  static company_employmentrelationship_setvalues = new PermissionSet("Admin", ["Company.EmploymentRelationship.SetValues"]);

  Company_Setting_List(): PermissionSet {
    return PermissionService.company_setting_list;
  }

  static company_setting_list = new PermissionSet("Admin", ["Company.Setting.List"]);

  Company_Setting_SetAuthPair(): PermissionSet {
    return PermissionService.company_setting_setauthpair;
  }

  static company_setting_setauthpair = new PermissionSet("Admin", ["Company.Setting.SetAuthPair"]);

  Company_Setting_SetAuthPairs(): PermissionSet {
    return PermissionService.company_setting_setauthpairs;
  }

  static company_setting_setauthpairs = new PermissionSet("Admin", ["Company.Setting.SetAuthPairs"]);

  Company_Setting_SumUpIsConfigured(): PermissionSet {
    return PermissionService.company_setting_sumupisconfigured;
  }

  static company_setting_sumupisconfigured = new PermissionSet("Admin", ["Company.Setting.SumUpIsConfigured"]);

  Company_Setting_TelegramIsConfigured(): PermissionSet {
    return PermissionService.company_setting_telegramisconfigured;
  }

  static company_setting_telegramisconfigured = new PermissionSet("Admin", ["Company.Setting.TelegramIsConfigured"]);

  Company_Supplier_Add(): PermissionSet {
    return PermissionService.company_supplier_add;
  }

  static company_supplier_add = new PermissionSet("Admin", ["Company.Supplier.Add"]);

  Company_Supplier_ChangeName(): PermissionSet {
    return PermissionService.company_supplier_changename;
  }

  static company_supplier_changename = new PermissionSet("Admin", ["Company.Supplier.ChangeName"]);

  Company_Supplier_Delete(): PermissionSet {
    return PermissionService.company_supplier_delete;
  }

  static company_supplier_delete = new PermissionSet("Admin", ["Company.Supplier.Delete"]);

  Company_Supplier_Get(): PermissionSet {
    return PermissionService.company_supplier_get;
  }

  static company_supplier_get = new PermissionSet("Admin", ["Company.Supplier.Get"]);

  Company_Supplier_List(): PermissionSet {
    return PermissionService.company_supplier_list;
  }

  static company_supplier_list = new PermissionSet("Admin", ["Company.Supplier.List"]);

  Company_Supplier_Update(): PermissionSet {
    return PermissionService.company_supplier_update;
  }

  static company_supplier_update = new PermissionSet("Admin", ["Company.Supplier.Update"]);

  Company_Telegram_AddBot(): PermissionSet {
    return PermissionService.company_telegram_addbot;
  }

  static company_telegram_addbot = new PermissionSet("Admin", ["Company.Telegram.AddBot"]);

  Company_Telegram_AddChannel(): PermissionSet {
    return PermissionService.company_telegram_addchannel;
  }

  static company_telegram_addchannel = new PermissionSet("Admin", ["Company.Telegram.AddChannel"]);

  Company_Telegram_DeleteBot(): PermissionSet {
    return PermissionService.company_telegram_deletebot;
  }

  static company_telegram_deletebot = new PermissionSet("Admin", ["Company.Telegram.DeleteBot"]);

  Company_Telegram_DeleteChannel(): PermissionSet {
    return PermissionService.company_telegram_deletechannel;
  }

  static company_telegram_deletechannel = new PermissionSet("Admin", ["Company.Telegram.DeleteChannel"]);

  Company_Telegram_GetBots(): PermissionSet {
    return PermissionService.company_telegram_getbots;
  }

  static company_telegram_getbots = new PermissionSet("Admin", ["Company.Telegram.GetBots"]);

  Company_Telegram_GetChannelSettings(): PermissionSet {
    return PermissionService.company_telegram_getchannelsettings;
  }

  static company_telegram_getchannelsettings = new PermissionSet("Admin", ["Company.Telegram.GetChannelSettings"]);

  Company_Telegram_List(): PermissionSet {
    return PermissionService.company_telegram_list;
  }

  static company_telegram_list = new PermissionSet("Admin", ["Company.Telegram.List"]);

  Company_Telegram_SetChannelSettings(): PermissionSet {
    return PermissionService.company_telegram_setchannelsettings;
  }

  static company_telegram_setchannelsettings = new PermissionSet("Admin", ["Company.Telegram.SetChannelSettings"]);

  Device_CashPointEntry_Close(): PermissionSet {
    return PermissionService.device_cashpointentry_close;
  }

  static device_cashpointentry_close = new PermissionSet("Admin", ["Device.CashPointEntry.Close"]);

  Device_CashPointEntry_GetLastClosed(): PermissionSet {
    return PermissionService.device_cashpointentry_getlastclosed;
  }

  static device_cashpointentry_getlastclosed = new PermissionSet("Admin", ["Device.CashPointEntry.GetLastClosed"]);

  Device_CashPointEntry_GetOpen(): PermissionSet {
    return PermissionService.device_cashpointentry_getopen;
  }

  static device_cashpointentry_getopen = new PermissionSet("Admin", ["Device.CashPointEntry.GetOpen"]);

  Device_CashPointEntry_GetSummary(): PermissionSet {
    return PermissionService.device_cashpointentry_getsummary;
  }

  static device_cashpointentry_getsummary = new PermissionSet("Admin", ["Device.CashPointEntry.GetSummary"]);

  Device_CashPointEntry_List(): PermissionSet {
    return PermissionService.device_cashpointentry_list;
  }

  static device_cashpointentry_list = new PermissionSet("Admin", ["Device.CashPointEntry.List"]);

  Device_CashPointEntry_Open(): PermissionSet {
    return PermissionService.device_cashpointentry_open;
  }

  static device_cashpointentry_open = new PermissionSet("Admin", ["Device.CashPointEntry.Open"]);

  Device_CashPointEntry_PrepareForClosing(): PermissionSet {
    return PermissionService.device_cashpointentry_prepareforclosing;
  }

  static device_cashpointentry_prepareforclosing = new PermissionSet("Admin", ["Device.CashPointEntry.PrepareForClosing"]);

  Device_CashPointEntry_Summary(): PermissionSet {
    return PermissionService.device_cashpointentry_summary;
  }

  static device_cashpointentry_summary = new PermissionSet("Admin", ["Device.CashPointEntry.Summary"]);

  Device_Client_GetData(): PermissionSet {
    return PermissionService.device_client_getdata;
  }

  static device_client_getdata = new PermissionSet("Admin", ["Device.Client.GetData"]);

  Device_Client_ProcessTransactions(): PermissionSet {
    return PermissionService.device_client_processtransactions;
  }

  static device_client_processtransactions = new PermissionSet("Admin", ["Device.Client.ProcessTransactions"]);

  Device_Information_ChangeName(): PermissionSet {
    return PermissionService.device_information_changename;
  }

  static device_information_changename = new PermissionSet("Admin", ["Device.Information.ChangeName"]);

  Device_Information_Delete(): PermissionSet {
    return PermissionService.device_information_delete;
  }

  static device_information_delete = new PermissionSet("Admin", ["Device.Information.Delete"]);

  Device_Information_Get(): PermissionSet {
    return PermissionService.device_information_get;
  }

  static device_information_get = new PermissionSet("Admin", ["Device.Information.Get"]);

  Device_Information_List(): PermissionSet {
    return PermissionService.device_information_list;
  }

  static device_information_list = new PermissionSet("Admin", ["Device.Information.List"]);

  Device_Information_RequestInformation(): PermissionSet {
    return PermissionService.device_information_requestinformation;
  }

  static device_information_requestinformation = new PermissionSet("Admin", ["Device.Information.RequestInformation"]);

  Device_Information_SetLockedOut(): PermissionSet {
    return PermissionService.device_information_setlockedout;
  }

  static device_information_setlockedout = new PermissionSet("Admin", ["Device.Information.SetLockedOut"]);

  Device_Printer_Add(): PermissionSet {
    return PermissionService.device_printer_add;
  }

  static device_printer_add = new PermissionSet("Admin", ["Device.Printer.Add"]);

  Device_Printer_ChangeName(): PermissionSet {
    return PermissionService.device_printer_changename;
  }

  static device_printer_changename = new PermissionSet("Admin", ["Device.Printer.ChangeName"]);

  Device_Printer_Delete(): PermissionSet {
    return PermissionService.device_printer_delete;
  }

  static device_printer_delete = new PermissionSet("Admin", ["Device.Printer.Delete"]);

  Device_Printer_Get(): PermissionSet {
    return PermissionService.device_printer_get;
  }

  static device_printer_get = new PermissionSet("Admin", ["Device.Printer.Get"]);

  Device_Printer_List(): PermissionSet {
    return PermissionService.device_printer_list;
  }

  static device_printer_list = new PermissionSet("Admin", ["Device.Printer.List"]);

  Device_Printer_SetVariable(): PermissionSet {
    return PermissionService.device_printer_setvariable;
  }

  static device_printer_setvariable = new PermissionSet("Admin", ["Device.Printer.SetVariable"]);

  Document_Employee_Download(): PermissionSet {
    return PermissionService.document_employee_download;
  }

  static document_employee_download = new PermissionSet("Admin", ["Document.Employee.Download"]);

  Document_Employee_DownloadIndividualDetails(): PermissionSet {
    return PermissionService.document_employee_downloadindividualdetails;
  }

  static document_employee_downloadindividualdetails = new PermissionSet("Admin", ["Document.Employee.DownloadIndividualDetails"]);

  Document_EmploymentRelationship_Download(): PermissionSet {
    return PermissionService.document_employmentrelationship_download;
  }

  static document_employmentrelationship_download = new PermissionSet("Admin", ["Document.EmploymentRelationship.Download"]);

  Document_ShiftMaker_Download(): PermissionSet {
    return PermissionService.document_shiftmaker_download;
  }

  static document_shiftmaker_download = new PermissionSet("Admin", ["Document.ShiftMaker.Download"]);

  Hubs_AutomationHub_Subscribe(): PermissionSet {
    return PermissionService.hubs_automationhub_subscribe;
  }

  static hubs_automationhub_subscribe = new PermissionSet("Admin", ["Hubs.AutomationHub.Subscribe"]);

  Hubs_ClientHub_DV_Information_Set(): PermissionSet {
    return PermissionService.hubs_clienthub_dv_information_set;
  }

  static hubs_clienthub_dv_information_set = new PermissionSet("Admin", ["Hubs.ClientHub.DV_Information_Set"]);

  Hubs_ClientHub_DV_Location_Set(): PermissionSet {
    return PermissionService.hubs_clienthub_dv_location_set;
  }

  static hubs_clienthub_dv_location_set = new PermissionSet("Admin", ["Hubs.ClientHub.DV_Location_Set"]);

  Hubs_ClientHub_GetDeviceInformation(): PermissionSet {
    return PermissionService.hubs_clienthub_getdeviceinformation;
  }

  static hubs_clienthub_getdeviceinformation = new PermissionSet("Admin", ["Hubs.ClientHub.GetDeviceInformation"]);

  Hubs_ClientHub_InvokeEReceiptTransaction(): PermissionSet {
    return PermissionService.hubs_clienthub_invokeereceipttransaction;
  }

  static hubs_clienthub_invokeereceipttransaction = new PermissionSet("Admin", ["Hubs.ClientHub.InvokeEReceiptTransaction"]);

  Hubs_ClientHub_O_Transmit(): PermissionSet {
    return PermissionService.hubs_clienthub_o_transmit;
  }

  static hubs_clienthub_o_transmit = new PermissionSet("Admin", ["Hubs.ClientHub.O_Transmit"]);

  Hubs_ClientHub_RequestHelp(): PermissionSet {
    return PermissionService.hubs_clienthub_requesthelp;
  }

  static hubs_clienthub_requesthelp = new PermissionSet("Admin", ["Hubs.ClientHub.RequestHelp"]);

  Hubs_DeviceHub_JoinListeners(): PermissionSet {
    return PermissionService.hubs_devicehub_joinlisteners;
  }

  static hubs_devicehub_joinlisteners = new PermissionSet("Admin", ["Hubs.DeviceHub.JoinListeners"]);

  Hubs_ShiftMakerHub_PushGeneralUpdate(): PermissionSet {
    return PermissionService.hubs_shiftmakerhub_pushgeneralupdate;
  }

  static hubs_shiftmakerhub_pushgeneralupdate = new PermissionSet("Admin", ["Hubs.ShiftMakerHub.PushGeneralUpdate"]);

  Hubs_ShiftMakerHub_PushLiveUpdate(): PermissionSet {
    return PermissionService.hubs_shiftmakerhub_pushliveupdate;
  }

  static hubs_shiftmakerhub_pushliveupdate = new PermissionSet("Admin", ["Hubs.ShiftMakerHub.PushLiveUpdate"]);

  Inventory_Category_Add(): PermissionSet {
    return PermissionService.inventory_category_add;
  }

  static inventory_category_add = new PermissionSet("Admin", ["Inventory.Category.Add"]);

  Inventory_Category_ChangeName(): PermissionSet {
    return PermissionService.inventory_category_changename;
  }

  static inventory_category_changename = new PermissionSet("Admin", ["Inventory.Category.ChangeName"]);

  Inventory_Category_Delete(): PermissionSet {
    return PermissionService.inventory_category_delete;
  }

  static inventory_category_delete = new PermissionSet("Admin", ["Inventory.Category.Delete"]);

  Inventory_Category_Get(): PermissionSet {
    return PermissionService.inventory_category_get;
  }

  static inventory_category_get = new PermissionSet("Admin", ["Inventory.Category.Get"]);

  Inventory_Category_List(): PermissionSet {
    return PermissionService.inventory_category_list;
  }

  static inventory_category_list = new PermissionSet("Admin", ["Inventory.Category.List"]);

  Inventory_Component_Add(): PermissionSet {
    return PermissionService.inventory_component_add;
  }

  static inventory_component_add = new PermissionSet("Admin", ["Inventory.Component.Add"]);

  Inventory_Component_ChangeName(): PermissionSet {
    return PermissionService.inventory_component_changename;
  }

  static inventory_component_changename = new PermissionSet("Admin", ["Inventory.Component.ChangeName"]);

  Inventory_Component_Delete(): PermissionSet {
    return PermissionService.inventory_component_delete;
  }

  static inventory_component_delete = new PermissionSet("Admin", ["Inventory.Component.Delete"]);

  Inventory_Component_Get(): PermissionSet {
    return PermissionService.inventory_component_get;
  }

  static inventory_component_get = new PermissionSet("Admin", ["Inventory.Component.Get"]);

  Inventory_Component_List(): PermissionSet {
    return PermissionService.inventory_component_list;
  }

  static inventory_component_list = new PermissionSet("Admin", ["Inventory.Component.List"]);

  Inventory_Composition_Add(): PermissionSet {
    return PermissionService.inventory_composition_add;
  }

  static inventory_composition_add = new PermissionSet("Admin", ["Inventory.Composition.Add"]);

  Inventory_Composition_ChangeName(): PermissionSet {
    return PermissionService.inventory_composition_changename;
  }

  static inventory_composition_changename = new PermissionSet("Admin", ["Inventory.Composition.ChangeName"]);

  Inventory_Composition_ChangePublicProperty(): PermissionSet {
    return PermissionService.inventory_composition_changepublicproperty;
  }

  static inventory_composition_changepublicproperty = new PermissionSet("Admin", ["Inventory.Composition.ChangePublicProperty"]);

  Inventory_Composition_ComponentChange(): PermissionSet {
    return PermissionService.inventory_composition_componentchange;
  }

  static inventory_composition_componentchange = new PermissionSet("Admin", ["Inventory.Composition.ComponentChange"]);

  Inventory_Composition_Create(): PermissionSet {
    return PermissionService.inventory_composition_create;
  }

  static inventory_composition_create = new PermissionSet("Admin", ["Inventory.Composition.Create"]);

  Inventory_Composition_Delete(): PermissionSet {
    return PermissionService.inventory_composition_delete;
  }

  static inventory_composition_delete = new PermissionSet("Admin", ["Inventory.Composition.Delete"]);

  Inventory_Composition_Download(): PermissionSet {
    return PermissionService.inventory_composition_download;
  }

  static inventory_composition_download = new PermissionSet("Admin", ["Inventory.Composition.Download"]);

  Inventory_Composition_Get(): PermissionSet {
    return PermissionService.inventory_composition_get;
  }

  static inventory_composition_get = new PermissionSet("Admin", ["Inventory.Composition.Get"]);

  Inventory_Composition_List(): PermissionSet {
    return PermissionService.inventory_composition_list;
  }

  static inventory_composition_list = new PermissionSet("Admin", ["Inventory.Composition.List"]);

  Inventory_Composition_PriceChange(): PermissionSet {
    return PermissionService.inventory_composition_pricechange;
  }

  static inventory_composition_pricechange = new PermissionSet("Admin", ["Inventory.Composition.PriceChange"]);

  Inventory_Composition_SetOrder(): PermissionSet {
    return PermissionService.inventory_composition_setorder;
  }

  static inventory_composition_setorder = new PermissionSet("Admin", ["Inventory.Composition.SetOrder"]);

  Inventory_Composition_ToggleTag(): PermissionSet {
    return PermissionService.inventory_composition_toggletag;
  }

  static inventory_composition_toggletag = new PermissionSet("Admin", ["Inventory.Composition.ToggleTag"]);

  Inventory_CompositionType_Add(): PermissionSet {
    return PermissionService.inventory_compositiontype_add;
  }

  static inventory_compositiontype_add = new PermissionSet("Admin", ["Inventory.CompositionType.Add"]);

  Inventory_CompositionType_ChangeName(): PermissionSet {
    return PermissionService.inventory_compositiontype_changename;
  }

  static inventory_compositiontype_changename = new PermissionSet("Admin", ["Inventory.CompositionType.ChangeName"]);

  Inventory_CompositionType_ChangeVat(): PermissionSet {
    return PermissionService.inventory_compositiontype_changevat;
  }

  static inventory_compositiontype_changevat = new PermissionSet("Admin", ["Inventory.CompositionType.ChangeVat"]);

  Inventory_CompositionType_Delete(): PermissionSet {
    return PermissionService.inventory_compositiontype_delete;
  }

  static inventory_compositiontype_delete = new PermissionSet("Admin", ["Inventory.CompositionType.Delete"]);

  Inventory_CompositionType_Get(): PermissionSet {
    return PermissionService.inventory_compositiontype_get;
  }

  static inventory_compositiontype_get = new PermissionSet("Admin", ["Inventory.CompositionType.Get"]);

  Inventory_CompositionType_List(): PermissionSet {
    return PermissionService.inventory_compositiontype_list;
  }

  static inventory_compositiontype_list = new PermissionSet("Admin", ["Inventory.CompositionType.List"]);

  Inventory_CompositionType_ListNl(): PermissionSet {
    return PermissionService.inventory_compositiontype_listnl;
  }

  static inventory_compositiontype_listnl = new PermissionSet("Admin", ["Inventory.CompositionType.ListNl"]);

  Inventory_CompositionType_SetCompositionType(): PermissionSet {
    return PermissionService.inventory_compositiontype_setcompositiontype;
  }

  static inventory_compositiontype_setcompositiontype = new PermissionSet("Admin", ["Inventory.CompositionType.SetCompositionType"]);

  Inventory_CompositionType_SetValue(): PermissionSet {
    return PermissionService.inventory_compositiontype_setvalue;
  }

  static inventory_compositiontype_setvalue = new PermissionSet("Admin", ["Inventory.CompositionType.SetValue"]);

  Inventory_Configuration_Add(): PermissionSet {
    return PermissionService.inventory_configuration_add;
  }

  static inventory_configuration_add = new PermissionSet("Admin", ["Inventory.Configuration.Add"]);

  Inventory_ConfigurationBuilder_Add(): PermissionSet {
    return PermissionService.inventory_configurationbuilder_add;
  }

  static inventory_configurationbuilder_add = new PermissionSet("Admin", ["Inventory.ConfigurationBuilder.Add"]);

  Inventory_ConfigurationBuilder_List(): PermissionSet {
    return PermissionService.inventory_configurationbuilder_list;
  }

  static inventory_configurationbuilder_list = new PermissionSet("Admin", ["Inventory.ConfigurationBuilder.List"]);

  Inventory_ConfigurationBuilder_SetSpecial(): PermissionSet {
    return PermissionService.inventory_configurationbuilder_setspecial;
  }

  static inventory_configurationbuilder_setspecial = new PermissionSet("Admin", ["Inventory.ConfigurationBuilder.SetSpecial"]);

  Inventory_Configuration_ChangeName(): PermissionSet {
    return PermissionService.inventory_configuration_changename;
  }

  static inventory_configuration_changename = new PermissionSet("Admin", ["Inventory.Configuration.ChangeName"]);

  Inventory_Configuration_Delete(): PermissionSet {
    return PermissionService.inventory_configuration_delete;
  }

  static inventory_configuration_delete = new PermissionSet("Admin", ["Inventory.Configuration.Delete"]);

  Inventory_Configuration_Get(): PermissionSet {
    return PermissionService.inventory_configuration_get;
  }

  static inventory_configuration_get = new PermissionSet("Admin", ["Inventory.Configuration.Get"]);

  Inventory_Configuration_List(): PermissionSet {
    return PermissionService.inventory_configuration_list;
  }

  static inventory_configuration_list = new PermissionSet("Admin", ["Inventory.Configuration.List"]);

  Inventory_Event_Add(): PermissionSet {
    return PermissionService.inventory_event_add;
  }

  static inventory_event_add = new PermissionSet("Admin", ["Inventory.Event.Add"]);

  Inventory_Event_ChangeName(): PermissionSet {
    return PermissionService.inventory_event_changename;
  }

  static inventory_event_changename = new PermissionSet("Admin", ["Inventory.Event.ChangeName"]);

  Inventory_Event_ChangePublicResponseTime(): PermissionSet {
    return PermissionService.inventory_event_changepublicresponsetime;
  }

  static inventory_event_changepublicresponsetime = new PermissionSet("Admin", ["Inventory.Event.ChangePublicResponseTime"]);

  Inventory_Event_ChangeTime(): PermissionSet {
    return PermissionService.inventory_event_changetime;
  }

  static inventory_event_changetime = new PermissionSet("Admin", ["Inventory.Event.ChangeTime"]);

  Inventory_Event_Delete(): PermissionSet {
    return PermissionService.inventory_event_delete;
  }

  static inventory_event_delete = new PermissionSet("Admin", ["Inventory.Event.Delete"]);

  Inventory_Event_Download(): PermissionSet {
    return PermissionService.inventory_event_download;
  }

  static inventory_event_download = new PermissionSet("Admin", ["Inventory.Event.Download"]);

  Inventory_Event_Downloads(): PermissionSet {
    return PermissionService.inventory_event_downloads;
  }

  static inventory_event_downloads = new PermissionSet("Admin", ["Inventory.Event.Downloads"]);

  Inventory_Event_Get(): PermissionSet {
    return PermissionService.inventory_event_get;
  }

  static inventory_event_get = new PermissionSet("Admin", ["Inventory.Event.Get"]);

  Inventory_Event_GetCurrentOrNext(): PermissionSet {
    return PermissionService.inventory_event_getcurrentornext;
  }

  static inventory_event_getcurrentornext = new PermissionSet("Admin", ["Inventory.Event.GetCurrentOrNext"]);

  Inventory_Event_GetDisabledLocations(): PermissionSet {
    return PermissionService.inventory_event_getdisabledlocations;
  }

  static inventory_event_getdisabledlocations = new PermissionSet("Admin", ["Inventory.Event.GetDisabledLocations"]);

  Inventory_Event_GetEmployeeAvailability(): PermissionSet {
    return PermissionService.inventory_event_getemployeeavailability;
  }

  static inventory_event_getemployeeavailability = new PermissionSet("Admin", ["Inventory.Event.GetEmployeeAvailability"]);

  Inventory_Event_GetEmployeeOrders(): PermissionSet {
    return PermissionService.inventory_event_getemployeeorders;
  }

  static inventory_event_getemployeeorders = new PermissionSet("Admin", ["Inventory.Event.GetEmployeeOrders"]);

  Inventory_Event_GetEventSummary(): PermissionSet {
    return PermissionService.inventory_event_geteventsummary;
  }

  static inventory_event_geteventsummary = new PermissionSet("Admin", ["Inventory.Event.GetEventSummary"]);

  Inventory_Event_GetExtendedEvent(): PermissionSet {
    return PermissionService.inventory_event_getextendedevent;
  }

  static inventory_event_getextendedevent = new PermissionSet("Admin", ["Inventory.Event.GetExtendedEvent"]);

  Inventory_Event_GetPrivatePublicEvents(): PermissionSet {
    return PermissionService.inventory_event_getprivatepublicevents;
  }

  static inventory_event_getprivatepublicevents = new PermissionSet("Admin", ["Inventory.Event.GetPrivatePublicEvents"]);

  Inventory_Event_GetPublicEvents(): PermissionSet {
    return PermissionService.inventory_event_getpublicevents;
  }

  static inventory_event_getpublicevents = new PermissionSet("Admin", ["Inventory.Event.GetPublicEvents"]);

  Inventory_Event_List(): PermissionSet {
    return PermissionService.inventory_event_list;
  }

  static inventory_event_list = new PermissionSet("Admin", ["Inventory.Event.List"]);

  Inventory_Event_SendEmployeeEventResponse(): PermissionSet {
    return PermissionService.inventory_event_sendemployeeeventresponse;
  }

  static inventory_event_sendemployeeeventresponse = new PermissionSet("Admin", ["Inventory.Event.SendEmployeeEventResponse"]);

  Inventory_Event_SetLocationConfigs(): PermissionSet {
    return PermissionService.inventory_event_setlocationconfigs;
  }

  static inventory_event_setlocationconfigs = new PermissionSet("Admin", ["Inventory.Event.SetLocationConfigs"]);

  Inventory_Event_SetPublicVariable(): PermissionSet {
    return PermissionService.inventory_event_setpublicvariable;
  }

  static inventory_event_setpublicvariable = new PermissionSet("Admin", ["Inventory.Event.SetPublicVariable"]);

  Inventory_Event_Upsert(): PermissionSet {
    return PermissionService.inventory_event_upsert;
  }

  static inventory_event_upsert = new PermissionSet("Admin", ["Inventory.Event.Upsert"]);

  Inventory_General_GetDashboardStats(): PermissionSet {
    return PermissionService.inventory_general_getdashboardstats;
  }

  static inventory_general_getdashboardstats = new PermissionSet("Admin", ["Inventory.General.GetDashboardStats"]);

  Inventory_General_OnlineCheck(): PermissionSet {
    return PermissionService.inventory_general_onlinecheck;
  }

  static inventory_general_onlinecheck = new PermissionSet("Admin", ["Inventory.General.OnlineCheck"]);

  Inventory_Item_Add(): PermissionSet {
    return PermissionService.inventory_item_add;
  }

  static inventory_item_add = new PermissionSet("Admin", ["Inventory.Item.Add"]);

  Inventory_Item_ChangeName(): PermissionSet {
    return PermissionService.inventory_item_changename;
  }

  static inventory_item_changename = new PermissionSet("Admin", ["Inventory.Item.ChangeName"]);

  Inventory_Item_Delete(): PermissionSet {
    return PermissionService.inventory_item_delete;
  }

  static inventory_item_delete = new PermissionSet("Admin", ["Inventory.Item.Delete"]);

  Inventory_Item_Get(): PermissionSet {
    return PermissionService.inventory_item_get;
  }

  static inventory_item_get = new PermissionSet("Admin", ["Inventory.Item.Get"]);

  Inventory_Item_GetEventSourcingCosts(): PermissionSet {
    return PermissionService.inventory_item_geteventsourcingcosts;
  }

  static inventory_item_geteventsourcingcosts = new PermissionSet("Admin", ["Inventory.Item.GetEventSourcingCosts"]);

  Inventory_Item_GetEventsSourcingCosts(): PermissionSet {
    return PermissionService.inventory_item_geteventssourcingcosts;
  }

  static inventory_item_geteventssourcingcosts = new PermissionSet("Admin", ["Inventory.Item.GetEventsSourcingCosts"]);

  Inventory_Item_List(): PermissionSet {
    return PermissionService.inventory_item_list;
  }

  static inventory_item_list = new PermissionSet("Admin", ["Inventory.Item.List"]);

  Inventory_Item_SetSourceDetail(): PermissionSet {
    return PermissionService.inventory_item_setsourcedetail;
  }

  static inventory_item_setsourcedetail = new PermissionSet("Admin", ["Inventory.Item.SetSourceDetail"]);

  Inventory_Item_SetUnit(): PermissionSet {
    return PermissionService.inventory_item_setunit;
  }

  static inventory_item_setunit = new PermissionSet("Admin", ["Inventory.Item.SetUnit"]);

  Inventory_Location_Add(): PermissionSet {
    return PermissionService.inventory_location_add;
  }

  static inventory_location_add = new PermissionSet("Admin", ["Inventory.Location.Add"]);

  Inventory_Location_ChangeName(): PermissionSet {
    return PermissionService.inventory_location_changename;
  }

  static inventory_location_changename = new PermissionSet("Admin", ["Inventory.Location.ChangeName"]);

  Inventory_Location_ClientList(): PermissionSet {
    return PermissionService.inventory_location_clientlist;
  }

  static inventory_location_clientlist = new PermissionSet("Admin", ["Inventory.Location.ClientList"]);

  Inventory_Location_Delete(): PermissionSet {
    return PermissionService.inventory_location_delete;
  }

  static inventory_location_delete = new PermissionSet("Admin", ["Inventory.Location.Delete"]);

  Inventory_Location_Get(): PermissionSet {
    return PermissionService.inventory_location_get;
  }

  static inventory_location_get = new PermissionSet("Admin", ["Inventory.Location.Get"]);

  Inventory_Location_GetCompanyLocations(): PermissionSet {
    return PermissionService.inventory_location_getcompanylocations;
  }

  static inventory_location_getcompanylocations = new PermissionSet("Admin", ["Inventory.Location.GetCompanyLocations"]);

  Inventory_Location_List(): PermissionSet {
    return PermissionService.inventory_location_list;
  }

  static inventory_location_list = new PermissionSet("Admin", ["Inventory.Location.List"]);

  Inventory_Location_PushLocation(): PermissionSet {
    return PermissionService.inventory_location_pushlocation;
  }

  static inventory_location_pushlocation = new PermissionSet("Admin", ["Inventory.Location.PushLocation"]);

  Inventory_Location_SetDefaultEmployeeCount(): PermissionSet {
    return PermissionService.inventory_location_setdefaultemployeecount;
  }

  static inventory_location_setdefaultemployeecount = new PermissionSet("Admin", ["Inventory.Location.SetDefaultEmployeeCount"]);

  Inventory_Location_SetLocationConfig(): PermissionSet {
    return PermissionService.inventory_location_setlocationconfig;
  }

  static inventory_location_setlocationconfig = new PermissionSet("Admin", ["Inventory.Location.SetLocationConfig"]);

  Inventory_Location_SetOrder(): PermissionSet {
    return PermissionService.inventory_location_setorder;
  }

  static inventory_location_setorder = new PermissionSet("Admin", ["Inventory.Location.SetOrder"]);

  Inventory_Location_SetTipShare(): PermissionSet {
    return PermissionService.inventory_location_settipshare;
  }

  static inventory_location_settipshare = new PermissionSet("Admin", ["Inventory.Location.SetTipShare"]);

  Inventory_Override_Composition(): PermissionSet {
    return PermissionService.inventory_override_composition;
  }

  static inventory_override_composition = new PermissionSet("Admin", ["Inventory.Override.Composition"]);

  Inventory_Override_Tag(): PermissionSet {
    return PermissionService.inventory_override_tag;
  }

  static inventory_override_tag = new PermissionSet("Admin", ["Inventory.Override.Tag"]);

  Inventory_Tag_Add(): PermissionSet {
    return PermissionService.inventory_tag_add;
  }

  static inventory_tag_add = new PermissionSet("Admin", ["Inventory.Tag.Add"]);

  Inventory_Tag_ChangeColor(): PermissionSet {
    return PermissionService.inventory_tag_changecolor;
  }

  static inventory_tag_changecolor = new PermissionSet("Admin", ["Inventory.Tag.ChangeColor"]);

  Inventory_Tag_ChangeName(): PermissionSet {
    return PermissionService.inventory_tag_changename;
  }

  static inventory_tag_changename = new PermissionSet("Admin", ["Inventory.Tag.ChangeName"]);

  Inventory_Tag_Delete(): PermissionSet {
    return PermissionService.inventory_tag_delete;
  }

  static inventory_tag_delete = new PermissionSet("Admin", ["Inventory.Tag.Delete"]);

  Inventory_Tag_Get(): PermissionSet {
    return PermissionService.inventory_tag_get;
  }

  static inventory_tag_get = new PermissionSet("Admin", ["Inventory.Tag.Get"]);

  Inventory_Tag_List(): PermissionSet {
    return PermissionService.inventory_tag_list;
  }

  static inventory_tag_list = new PermissionSet("Admin", ["Inventory.Tag.List"]);

  Inventory_Tag_SetFilter(): PermissionSet {
    return PermissionService.inventory_tag_setfilter;
  }

  static inventory_tag_setfilter = new PermissionSet("Admin", ["Inventory.Tag.SetFilter"]);

  Inventory_Tag_SetOrder(): PermissionSet {
    return PermissionService.inventory_tag_setorder;
  }

  static inventory_tag_setorder = new PermissionSet("Admin", ["Inventory.Tag.SetOrder"]);

  Legal_Settings_IsConfigured(): PermissionSet {
    return PermissionService.legal_settings_isconfigured;
  }

  static legal_settings_isconfigured = new PermissionSet("Admin", ["Legal.Settings.IsConfigured"]);

  Legal_Settings_List(): PermissionSet {
    return PermissionService.legal_settings_list;
  }

  static legal_settings_list = new PermissionSet("Admin", ["Legal.Settings.List"]);

  Legal_Settings_SelectProvider(): PermissionSet {
    return PermissionService.legal_settings_selectprovider;
  }

  static legal_settings_selectprovider = new PermissionSet("Admin", ["Legal.Settings.SelectProvider"]);

  Legal_Settings_SetCredentials(): PermissionSet {
    return PermissionService.legal_settings_setcredentials;
  }

  static legal_settings_setcredentials = new PermissionSet("Admin", ["Legal.Settings.SetCredentials"]);

  Legal_TSS_ChangeState(): PermissionSet {
    return PermissionService.legal_tss_changestate;
  }

  static legal_tss_changestate = new PermissionSet("Admin", ["Legal.TSS.ChangeState"]);

  Legal_TSS_Create(): PermissionSet {
    return PermissionService.legal_tss_create;
  }

  static legal_tss_create = new PermissionSet("Admin", ["Legal.TSS.Create"]);

  Legal_TSS_GetCurrent(): PermissionSet {
    return PermissionService.legal_tss_getcurrent;
  }

  static legal_tss_getcurrent = new PermissionSet("Admin", ["Legal.TSS.GetCurrent"]);

  Management_Changelog_Add(): PermissionSet {
    return PermissionService.management_changelog_add;
  }

  static management_changelog_add = new PermissionSet("Admin", ["Management.Changelog.Add"]);

  Management_Changelog_Delete(): PermissionSet {
    return PermissionService.management_changelog_delete;
  }

  static management_changelog_delete = new PermissionSet("Admin", ["Management.Changelog.Delete"]);

  Management_Changelog_Reactivate(): PermissionSet {
    return PermissionService.management_changelog_reactivate;
  }

  static management_changelog_reactivate = new PermissionSet("Admin", ["Management.Changelog.Reactivate"]);

  Management_Changelog_Update(): PermissionSet {
    return PermissionService.management_changelog_update;
  }

  static management_changelog_update = new PermissionSet("Admin", ["Management.Changelog.Update"]);

  Management_Company_Add(): PermissionSet {
    return PermissionService.management_company_add;
  }

  static management_company_add = new PermissionSet("Admin", ["Management.Company.Add"]);

  Management_Company_ChangeName(): PermissionSet {
    return PermissionService.management_company_changename;
  }

  static management_company_changename = new PermissionSet("Admin", ["Management.Company.ChangeName"]);

  Management_Company_Delete(): PermissionSet {
    return PermissionService.management_company_delete;
  }

  static management_company_delete = new PermissionSet("Admin", ["Management.Company.Delete"]);

  Management_Company_Get(): PermissionSet {
    return PermissionService.management_company_get;
  }

  static management_company_get = new PermissionSet("Admin", ["Management.Company.Get"]);

  Management_Company_List(): PermissionSet {
    return PermissionService.management_company_list;
  }

  static management_company_list = new PermissionSet("Admin", ["Management.Company.List"]);

  Management_Company_SetAddress(): PermissionSet {
    return PermissionService.management_company_setaddress;
  }

  static management_company_setaddress = new PermissionSet("Admin", ["Management.Company.SetAddress"]);

  Management_Company_SetAlias(): PermissionSet {
    return PermissionService.management_company_setalias;
  }

  static management_company_setalias = new PermissionSet("Admin", ["Management.Company.SetAlias"]);

  Management_Company_SetMetadata(): PermissionSet {
    return PermissionService.management_company_setmetadata;
  }

  static management_company_setmetadata = new PermissionSet("Admin", ["Management.Company.SetMetadata"]);

  Management_Device_Location(): PermissionSet {
    return PermissionService.management_device_location;
  }

  static management_device_location = new PermissionSet("Admin", ["Management.Device.Location"]);

  Management_Environment_Add(): PermissionSet {
    return PermissionService.management_environment_add;
  }

  static management_environment_add = new PermissionSet("Admin", ["Management.Environment.Add"]);

  Management_Environment_Delete(): PermissionSet {
    return PermissionService.management_environment_delete;
  }

  static management_environment_delete = new PermissionSet("Admin", ["Management.Environment.Delete"]);

  Management_Environment_Get(): PermissionSet {
    return PermissionService.management_environment_get;
  }

  static management_environment_get = new PermissionSet("Admin", ["Management.Environment.Get"]);

  Management_Environment_List(): PermissionSet {
    return PermissionService.management_environment_list;
  }

  static management_environment_list = new PermissionSet("Admin", ["Management.Environment.List"]);

  Management_Environment_Upsert(): PermissionSet {
    return PermissionService.management_environment_upsert;
  }

  static management_environment_upsert = new PermissionSet("Admin", ["Management.Environment.Upsert"]);

  Management_Fiskaly_IsConfigured(): PermissionSet {
    return PermissionService.management_fiskaly_isconfigured;
  }

  static management_fiskaly_isconfigured = new PermissionSet("Admin", ["Management.Fiskaly.IsConfigured"]);

  Management_Fiskaly_SetCredentials(): PermissionSet {
    return PermissionService.management_fiskaly_setcredentials;
  }

  static management_fiskaly_setcredentials = new PermissionSet("Admin", ["Management.Fiskaly.SetCredentials"]);

  Management_Fiskaly_UpsertCompany(): PermissionSet {
    return PermissionService.management_fiskaly_upsertcompany;
  }

  static management_fiskaly_upsertcompany = new PermissionSet("Admin", ["Management.Fiskaly.UpsertCompany"]);

  Management_Fiskaly_UpsertShard(): PermissionSet {
    return PermissionService.management_fiskaly_upsertshard;
  }

  static management_fiskaly_upsertshard = new PermissionSet("Admin", ["Management.Fiskaly.UpsertShard"]);

  Management_Hangfire_Authorize(): PermissionSet {
    return PermissionService.management_hangfire_authorize;
  }

  static management_hangfire_authorize = new PermissionSet("Admin", ["Management.Hangfire.Authorize"]);

  Management_Permission_GenerateTsEndpoints(): PermissionSet {
    return PermissionService.management_permission_generatetsendpoints;
  }

  static management_permission_generatetsendpoints = new PermissionSet("Admin", ["Management.Permission.GenerateTsEndpoints"]);

  Management_Permission_GenerateTsPermissionSets(): PermissionSet {
    return PermissionService.management_permission_generatetspermissionsets;
  }

  static management_permission_generatetspermissionsets = new PermissionSet("Admin", ["Management.Permission.GenerateTsPermissionSets"]);

  Management_Setup_Configure(): PermissionSet {
    return PermissionService.management_setup_configure;
  }

  static management_setup_configure = new PermissionSet("Admin", ["Management.Setup.Configure"]);

  Management_Setup_IsConfigured(): PermissionSet {
    return PermissionService.management_setup_isconfigured;
  }

  static management_setup_isconfigured = new PermissionSet("Admin", ["Management.Setup.IsConfigured"]);

  Management_Shard_Add(): PermissionSet {
    return PermissionService.management_shard_add;
  }

  static management_shard_add = new PermissionSet("Admin", ["Management.Shard.Add"]);

  Management_Shard_ChangeName(): PermissionSet {
    return PermissionService.management_shard_changename;
  }

  static management_shard_changename = new PermissionSet("Admin", ["Management.Shard.ChangeName"]);

  Management_Shard_Delete(): PermissionSet {
    return PermissionService.management_shard_delete;
  }

  static management_shard_delete = new PermissionSet("Admin", ["Management.Shard.Delete"]);

  Management_Shard_Get(): PermissionSet {
    return PermissionService.management_shard_get;
  }

  static management_shard_get = new PermissionSet("Admin", ["Management.Shard.Get"]);

  Management_Shard_List(): PermissionSet {
    return PermissionService.management_shard_list;
  }

  static management_shard_list = new PermissionSet("Admin", ["Management.Shard.List"]);

  Management_Shard_SetAddress(): PermissionSet {
    return PermissionService.management_shard_setaddress;
  }

  static management_shard_setaddress = new PermissionSet("Admin", ["Management.Shard.SetAddress"]);

  Management_Shard_SetMetadata(): PermissionSet {
    return PermissionService.management_shard_setmetadata;
  }

  static management_shard_setmetadata = new PermissionSet("Admin", ["Management.Shard.SetMetadata"]);

  Management_User_Create(): PermissionSet {
    return PermissionService.management_user_create;
  }

  static management_user_create = new PermissionSet("Admin", ["Management.User.Create"]);

  Management_User_Delete(): PermissionSet {
    return PermissionService.management_user_delete;
  }

  static management_user_delete = new PermissionSet("Admin", ["Management.User.Delete"]);

  Management_User_List(): PermissionSet {
    return PermissionService.management_user_list;
  }

  static management_user_list = new PermissionSet("Admin", ["Management.User.List"]);

  Miscellaneous_Changelog_List(): PermissionSet {
    return PermissionService.miscellaneous_changelog_list;
  }

  static miscellaneous_changelog_list = new PermissionSet("Admin", ["Miscellaneous.Changelog.List"]);

  Miscellaneous_Feedback_Add(): PermissionSet {
    return PermissionService.miscellaneous_feedback_add;
  }

  static miscellaneous_feedback_add = new PermissionSet("Admin", ["Miscellaneous.Feedback.Add"]);

  Miscellaneous_Feedback_AddComment(): PermissionSet {
    return PermissionService.miscellaneous_feedback_addcomment;
  }

  static miscellaneous_feedback_addcomment = new PermissionSet("Admin", ["Miscellaneous.Feedback.AddComment"]);

  Miscellaneous_Feedback_List(): PermissionSet {
    return PermissionService.miscellaneous_feedback_list;
  }

  static miscellaneous_feedback_list = new PermissionSet("Admin", ["Miscellaneous.Feedback.List"]);

  Miscellaneous_Feedback_ListComments(): PermissionSet {
    return PermissionService.miscellaneous_feedback_listcomments;
  }

  static miscellaneous_feedback_listcomments = new PermissionSet("Admin", ["Miscellaneous.Feedback.ListComments"]);

  Owner(): PermissionSet {
    return PermissionService.owner;
  }

  static owner = new PermissionSet("Admin", ["Owner"]);

  Payment_Settings_Get(): PermissionSet {
    return PermissionService.payment_settings_get;
  }

  static payment_settings_get = new PermissionSet("Admin", ["Payment.Settings.Get"]);

  Payment_Settings_IsConfigured(): PermissionSet {
    return PermissionService.payment_settings_isconfigured;
  }

  static payment_settings_isconfigured = new PermissionSet("Admin", ["Payment.Settings.IsConfigured"]);

  Payment_Settings_SelectProvider(): PermissionSet {
    return PermissionService.payment_settings_selectprovider;
  }

  static payment_settings_selectprovider = new PermissionSet("Admin", ["Payment.Settings.SelectProvider"]);

  Payment_Settings_SetCredentials(): PermissionSet {
    return PermissionService.payment_settings_setcredentials;
  }

  static payment_settings_setcredentials = new PermissionSet("Admin", ["Payment.Settings.SetCredentials"]);


  /*




  COMPONENT PERMISSIONS




   */

  // navbar inventory

  // --> navbar combined
  Navbar_ShowInventory(): boolean {
    return this.CheckPermission(this.Permissions_CategoryComponent()) ||
      this.CheckPermission(this.Permissions_ItemComponent()) ||
      this.CheckPermission(this.Permissions_CompositionTypesComponent()) ||
      this.CheckPermission(this.Permissions_CompositionComponent()) ||
      this.CheckPermission(this.Permissions_TagsComponent()) ||
      this.CheckPermission(this.Permissions_ConfigurationsComponent())
  }

  static Permissions_CategoryComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_category_list.default);
    return set;
  }

  Permissions_CategoryComponent(): PermissionSet {
    return PermissionService.Permissions_CategoryComponent();
  }

  static Permissions_ItemComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_item_list.default);
    return set;
  }

  Permissions_ItemComponent(): PermissionSet {
    return PermissionService.Permissions_ItemComponent();
  }

  static Permissions_CompositionTypesComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_compositiontype_listnl.default)
    return set;
  }

  Permissions_CompositionTypesComponent(): PermissionSet {
    return PermissionService.Permissions_CompositionTypesComponent();
  }

  static Permissions_CompositionsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_composition_list.default);
    set.default = set.default.concat(PermissionService.inventory_compositiontype_listnl.default)
    set.default = set.default.concat(PermissionService.inventory_compositiontype_get.default)
    set.default = set.default.concat(PermissionService.inventory_tag_list.default)
    set.default = set.default.concat(PermissionService.inventory_item_list.default)
    return set;
  }

  Permissions_CompositionComponent(): PermissionSet {
    return PermissionService.Permissions_CompositionsComponent()
  }

  static Permissions_TagsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_tag_list.default);
    return set;
  }

  Permissions_TagsComponent(): PermissionSet {
    return PermissionService.Permissions_TagsComponent();
  }


  static Permissions_ConfigurationsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_configurationbuilder_list.default);
    return set;
  }

  Permissions_ConfigurationsComponent(): PermissionSet {
    return PermissionService.Permissions_ConfigurationsComponent();
  }

  // navbar location

  // --> navbar combined

  Navbar_ShowLocations(): boolean {
    return this.CheckPermission(this.Permissions_EventsComponent()) ||
      this.CheckPermission(this.Permissions_OrderComponent()) ||
      this.CheckPermission(this.Permissions_LocationsComponent()) ||
      this.CheckPermission(this.Permissions_DevicesComponent()) ||
      this.CheckPermission(this.Permissions_TableComponent()) ||
      this.CheckPermission(this.Permissions_PrinterComponent()) ||
      this.CheckPermission(this.Permissions_CashPointComponent())
  }

  static Permissions_CashPointCloseAction(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.device_cashpointentry_getsummary.default)
    set.default = set.default.concat(PermissionService.device_cashpointentry_getlastclosed.default)
    set.default = set.default.concat(PermissionService.device_cashpointentry_close.default)
    return set;
  }

  Permissions_CashPointCloseAction(): PermissionSet {
    return PermissionService.Permissions_CashPointCloseAction();
  }

  static Permissions_CashPointComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.device_cashpointentry_list.default)
    set.default = set.default.concat(PermissionService.company_employee_listnl.default)
    return set;
  }

  Permissions_CashPointAssistantClosingComponent(): PermissionSet {
    return PermissionService.Permissions_CashPointsAssistantClosingComponent();
  }

  static Permissions_CashPointsAssistantClosingComponent(): PermissionSet {
    // todo niko: implement
    let set = PermissionService.Permissions_CashPointCloseAction();
    return set;
  }

  Permissions_CashPointComponent(): PermissionSet {
    return PermissionService.Permissions_CashPointComponent();
  }

  static Permissions_EventsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_list.default);
    return set;
  }

  Permissions_EventsComponent(): PermissionSet {
    return PermissionService.Permissions_EventsComponent()
  }

  static Permissions_OrderComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.booking_order_list.default)
    return set;
  }

  Permissions_OrderComponent(): PermissionSet {
    return PermissionService.Permissions_OrderComponent();
  }

  static Permissions_LocationsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_location_list.default)
    return set;
  }

  Permissions_LocationsComponent(): PermissionSet {
    return PermissionService.Permissions_LocationsComponent();
  }

  static Permissions_DevicesComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.device_information_list.default)
    set.default = set.default.concat(PermissionService.inventory_location_list.default)
    return set;
  }

  Permissions_DevicesComponent(): PermissionSet {
    return PermissionService.Permissions_DevicesComponent();
  }

  static Permissions_TableComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.booking_table_list.default)
    return set;
  }

  Permissions_TableComponent(): PermissionSet {
    return PermissionService.Permissions_TableComponent();
  }

  static Permissions_PrinterComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.device_printer_list.default)
    return set;
  }

  Permissions_PrinterComponent(): PermissionSet {
    return PermissionService.Permissions_PrinterComponent();
  }

  // navbar analytics

  // --> navbar analytics combined

  Navbar_ShowAnalytics(): boolean {
    return this.CheckPermission(PermissionService.Permissions_SingleAnalyticsComponent()) ||
      this.CheckPermission(PermissionService.Permissions_MultiAnalysisComponent())
  }

  static Permissions_SingleAnalyticsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_list.default)
    set.default = set.default.concat(PermissionService.inventory_location_list.default)
    set.default = set.default.concat(PermissionService.inventory_compositiontype_listnl.default)
    set.default = set.default.concat(PermissionService.company_employmentrelationship_geteventemploymentcosts.default)
    set.default = set.default.concat(PermissionService.inventory_item_geteventsourcingcosts.default)
    set.default = set.default.concat(PermissionService.inventory_event_getextendedevent.default)
    return set;
  }

  Permissions_SingleAnalyticsComponent(): PermissionSet {
    return PermissionService.Permissions_SingleAnalyticsComponent();
  }

  static Permissions_MultiAnalysisComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_location_list.default)
    set.default = set.default.concat(PermissionService.inventory_compositiontype_listnl.default)
    set.default = set.default.concat(PermissionService.analysis_eventanalysis_fetcheventdata.default)
    set.default = set.default.concat(PermissionService.company_employmentrelationship_geteventsemploymentcosts.default)
    return set;
  }

  Permissions_MultiAnalysisComponent(): PermissionSet {
    return PermissionService.Permissions_MultiAnalysisComponent();
  }

  // navbar automation

  // --> navbar automation combined
  Navbar_ShowAutomation(): boolean {
    return this.CheckPermission(PermissionService.Permissions_AutomationManagerComponent());
  }

  static Permissions_AutomationManagerComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_list.default)
    set.default = set.default.concat(PermissionService.inventory_configuration_list.default)
    set.default = set.default.concat(PermissionService.inventory_composition_list.default)
    set.default = set.default.concat(PermissionService.inventory_compositiontype_list.default)
    set.default = set.default.concat(PermissionService.automation_scheduler_getschedulerobjects.default)
    set.default = set.default.concat(PermissionService.automation_scheduler_schedulegroup.default)
    set.default = set.default.concat(PermissionService.automation_scheduler_deletegroup.default)
    return set;
  }

  Permissions_AutomationManagerComponent(): PermissionSet {
    return PermissionService.Permissions_AutomationManagerComponent();
  }

  // not in navbar
  static Permissions_AutomationViewComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.hubs_automationhub_subscribe.default);
    set.default = set.default.concat(PermissionService.automation_scheduler_getschedulergroup.default)
    set.default = set.default.concat(PermissionService.automation_scheduler_getschedulerobjects.default)
    return set;
  }

  // navbar legal

  // --> navbar legal combined
  Navbar_ShowLegal(): boolean {
    return this.CheckPermission(PermissionService.Permissions_LegalSettingsComponent()) ||
      this.CheckPermission(PermissionService.Permissions_LegalTSEComponent())
  }

  static Permissions_LegalSettingsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  Permissions_legalSettingsComponent(): PermissionSet {
    return PermissionService.Permissions_LegalSettingsComponent();
  }

  static Permissions_LegalTSEComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  Permissions_LegalTSEComponent(): PermissionSet {
    return PermissionService.Permissions_LegalTSEComponent();
  }

  // navbar company

  // --> navbar company combined

  Navbar_ShowCompany(): boolean {
    return this.CheckPermission(PermissionService.Permissions_EmployeeComponent()) ||
      this.CheckPermission(PermissionService.Permissions_EmploymentRelationshipComponent()) ||
      this.CheckPermission(PermissionService.Permissions_ShiftMakerComponent()) ||
      this.CheckPermission(PermissionService.Permissions_SuppliersComponent()) ||
      this.CheckPermission(PermissionService.Permissions_TelegramComponent()) ||
      this.CheckPermission(PermissionService.Permissions_CompanySettingsComponent() ||
        this.CheckPermission(PermissionService.Permissions_ContractComponent()))
  }

  static Permissions_ContractComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  Permissions_ContractComponent(): PermissionSet {
    return PermissionService.Permissions_ContractComponent();
  }

  static Permissions_EmployeeComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employee_list.default)
    set.default = set.default.concat(PermissionService.company_employmentrelationship_list.default)
    set.default = set.default.concat(PermissionService.company_employeeattribute_list.default)
    return set;
  }

  Permissions_EmployeeComponent(): PermissionSet {
    return PermissionService.Permissions_EmployeeComponent();
  }

  // not in navbar
  static Permissions_EmployeeDetailsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employee_get.default)
    set.default = set.default.concat(PermissionService.company_employee_employeesaleslist.default)
    return set;
  }

  Permissions_EmployeeDetailsComponent(): PermissionSet {
    return PermissionService.Permissions_EmployeeDetailsComponent();
  }

  static Permissions_EmploymentRelationshipComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employmentrelationship_list.default)
    return set;
  }

  Permissions_EmploymentRelationshipComponent(): PermissionSet {
    return PermissionService.Permissions_EmploymentRelationshipComponent();
  }

  static Permissions_ShiftMakerComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_location_getcompanylocations.default);
    set.default = set.default.concat(PermissionService.company_employeeattribute_list.default)
    set.default = set.default.concat(PermissionService.company_employee_listnl.default)
    set.default = set.default.concat(PermissionService.inventory_event_getemployeeavailability.default);
    set.default = set.default.concat(PermissionService.inventory_event_getprivatepublicevents.default)
    set.default = set.default.concat(PermissionService.inventory_event_getdisabledlocations.default)
    return set;
  }

  Permissions_ShiftMakerComponent(): PermissionSet {
    return PermissionService.Permissions_ShiftMakerComponent();
  }

  static Permissions_SuppliersComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_supplier_list.default)
    return set;
  }

  Permissions_SuppliersComponent(): PermissionSet {
    return PermissionService.Permissions_SuppliersComponent();
  }

  static Permissions_TelegramComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_telegram_getbots.default)
    return set;
  }

  Permissions_TelegramComponent(): PermissionSet {
    return PermissionService.Permissions_TelegramComponent();
  }

  static Permissions_CompanySettingsComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  // navbar admin

  // --> navbar admin combined

  Navbar_ShowAdmin(): boolean {
    return this.CheckPermission(PermissionService.Permissions_AdminUsersComponent()) ||
      this.CheckPermission(PermissionService.Permissions_AdminRolesComponent()) ||
      this.CheckPermission(PermissionService.Permissions_AdminPatchesComponent())
  }

  static Permissions_AdminUsersComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  static Permissions_AdminRolesComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  static Permissions_AdminPatchesComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.admin.default)
    return set;
  }

  // navbar owner

  // --> navbar owner combined

  Navbar_ShowOwner(): boolean {
    return this.CheckPermission(PermissionService.Permissions_Owner())
  }

  static Permissions_Owner(): PermissionSet {
    let set = new PermissionSet("Owner", PermissionService.owner.default)
    return set;
  }

  // navbar help

  // --> navbar help combined

  static Permissions_ChangelogComponent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.miscellaneous_changelog_list.default);
    return set;
  }


  // ------------------------------------------------------------------------------------------


  /*




  COMBINED PERMISSIONS




   */

  CombinedPermission_CompositionCreate(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_composition_create.default)
    set.default = set.default.concat(PermissionService.inventory_tag_list.default);
    set.default = set.default.concat(PermissionService.inventory_item_list.default);
    return set;
  }

  CombinedPermission_ItemAdd(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_item_add.default);
    set.default = set.default.concat(PermissionService.inventory_category_list.default);
    return set;
  }

  CombinedPermission_CompositionSetCompositionType(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_compositiontype_setcompositiontype.default);
    set.default = set.default.concat(PermissionService.inventory_compositiontype_listnl.default);
    return set;
  }

  CombinedPermission_TagSetFilter(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_tag_setfilter.default);
    set.default = set.default.concat(PermissionService.inventory_category_list.default)
    return set;
  }

  CombinedPermission_TagRedirectComposition(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.Permissions_CompositionsComponent().default)
    set.default = set.default.concat(PermissionService.inventory_tag_get.default);
    return set;
  }

  CombinedPermission_LocationReadConfiguration(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_configuration_list.default);
    return set;
  }

  CombinedPermission_LocationSetConfiguration(): PermissionSet {
    let set = new PermissionSet("Admin", this.CombinedPermission_LocationReadConfiguration().default);
    set.default = set.default.concat(PermissionService.inventory_location_setlocationconfig.default)
    set.default = set.default.concat(PermissionService.inventory_location_pushlocation.default)
    return set;
  }

  CombinedPermission_ConfigurationBuilderEdit(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_configurationbuilder_list.default);
    set.default = set.default.concat(PermissionService.inventory_configurationbuilder_setspecial.default)
    set.default = set.default.concat(PermissionService.inventory_override_tag.default)
    set.default = set.default.concat(PermissionService.inventory_override_composition.default)
    return set;
  }

  CombinedPermission_EventGetExtendedEvent(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_getextendedevent.default);
    set.default = set.default.concat(PermissionService.inventory_location_get.default);
    return set;
  }

  CombinedPermission_EventDownloadExtendedEvent(): PermissionSet {
    let set = new PermissionSet("Admin", this.CombinedPermission_EventGetExtendedEvent().default)
    set.default = set.default.concat(PermissionService.inventory_event_download.default);
    set.default = set.default.concat(PermissionService.company_supplier_list.default)
    return set;
  }

  CombinedPermission_EventReadLocationConfig(): PermissionSet {
    let set = new PermissionSet("Admin", this.CombinedPermission_EventGetExtendedEvent().default);
    set.default = set.default.concat(PermissionService.inventory_location_list.default)
    set.default = set.default.concat(PermissionService.inventory_configuration_list.default)
    return set;
  }

  CombinedPermission_EventSetLocationConfig(): PermissionSet {
    let set = new PermissionSet("Admin", this.CombinedPermission_EventReadLocationConfig().default);
    set.default = set.default.concat(PermissionService.inventory_event_setlocationconfigs.default)
    return set;
  }

  CombinedPermission_EmployeeEditEmployeeRelationship(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employmentrelationship_list.default)
    set.default = set.default.concat(PermissionService.company_employmentrelationship_setrelationship.default)
    return set;
  }

  CombinedPermission_TelegramViewChannelConfiguration(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_telegram_getchannelsettings.default);
    set.default = set.default.concat(PermissionService.company_company_getcompanyshards.default);
    return set;
  }

  CombinedPermission_TelegramEditChannelConfiguration(): PermissionSet {
    let set = new PermissionSet("Admin", this.CombinedPermission_TelegramViewChannelConfiguration().default);
    set.default = set.default.concat(PermissionService.company_telegram_setchannelsettings.default);
    return set;
  }

  CombinedPermission_EmployeeTelegram(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employee_setsocial.default);
    set.default = set.default.concat(PermissionService.company_employee_resetsocial.default);
    return set;
  }

  CombinedPermission_EventSetPublicProperties(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_setpublicvariable.default)
    set.default = set.default.concat(PermissionService.inventory_event_changepublicresponsetime.default);
    return set;
  }

  CombinedPermission_HomeEventPermission(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.inventory_event_list.default);
    set.default = set.default.concat(PermissionService.inventory_event_getcurrentornext.default)
    return set;
  }

  CombinedPermission_EmployeeSalesView(): PermissionSet {
    let set = new PermissionSet("Admin", PermissionService.company_employee_employeesaleslist.default);
    set.default = set.default.concat(PermissionService.company_employee_employeesalesorders.default)
    return set;
  }

  static = new PermissionSet(
    "",
    [""]
  )

  // employee
  GetEmployeeBasePermission(): PermissionSet {
    return PermissionService.ep_base;
  }

  static ep_base = new PermissionSet(
    "_none",
    ["Employee"]
  )


  constructor(private authenticationService: AuthenticationService) {

  }

  CheckPermission(permissions: PermissionSet): boolean {
    const user = this.authenticationService.userValue;
    if (user == null) return false;
    if (user.permissions.includes(permissions._super)) return true;
    return permissions.default.every((x: string) => user.permissions.includes(x));
  }
}
