<div class="w-full">
  <div *ngIf="event == undefined && !details" class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Events</h1>
      </div>
    </div>
    <div *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Event_Add())" class="pt-4">
      <label for="NewCategoryName" class="block text-sm font-medium leading-6 text-gray-900">Neues Event</label>
      <div class="grid grid-cols-2 gap-2">
        <div>
          <div class="mt-2 flex rounded-md shadow-sm">
            <span
              class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Start</span>
            <input [(ngModel)]="start" type="datetime-local" name="start" id="start"
                   class="px-2 flex w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>
        <div>
          <div class="mt-2 flex rounded-md shadow-sm">
            <span
              class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Ende</span>
            <input [(ngModel)]="end" type="datetime-local" name="end" id="end"
                   class="px-2 flex w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>
      </div>
      <form class="mt-2 flex rounded-md shadow-sm">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks"
                 viewBox="0 0 16 16">
              <path
                d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
              <path
                d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
            </svg>
          </div>
          <input type="text" name="NewCategoryName" id="NewCategoryName" [(ngModel)]="name"
                 class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                 placeholder="Name">
        </div>
        <button (click)="Add()" type="submit"
                class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
               viewBox="0 0 16 16">
            <path
              d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
          </svg>
          Hinzufügen
        </button>
      </form>
    </div>
    <div class="flex">
      <input #search (input)="FilterItems(search.value)" type="text" name="search" id="search"
             class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 my-4"
             placeholder="Veranstaltung suchen...">
      <select class="py-1.5 my-4 pl-2" [(ngModel)]="last" (ngModelChange)="SetLast($event);">
        <option selected [value]="10">10</option>
        <option selected [value]="100">100</option>
        <option selected [value]="1000">1000</option>
        <option selected [value]="2000000000">Alle</option>
      </select>
    </div>
    <div class="w-full grid grid-cols-2 select-none">
      <div class="pt-0 cursor-pointer text-theme-primary-700 hover:text-theme-primary-900"
           (click)="selectionMode = !selectionMode; selectionModeChanged();">
        <a *ngIf="!selectionMode">Mehrere auswählen</a>
        <a *ngIf="selectionMode">Fertig</a>
      </div>
      <div class="flex justify-end gap-x-2" *ngIf="selectionMode">
        <div class="place-self-center inline-flex gap-x-1">
          <input [(ngModel)]="oneFile" type="checkbox" id="one-file" class="place-self-center">
          <label for="one-file" class="place-self-center">Kombinierte Datei</label>
        </div>
        <a
          *ngIf="!isMassDownloading() && PermissionService.CheckPermission(PermissionService.CombinedPermission_EventDownloadExtendedEvent())"
          class="cursor-pointer place-self-center text-theme-primary-700 hover:text-theme-primary-900"
          [ngClass]="{'!text-gray-600 !pointer-events-none': !isMassSelected()}"
          (click)="massDownload()">Download</a>
        <a *ngIf="isMassDownloading()" class="!text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="animate-spin bi bi-slash-circle place-self-center" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="pt-4">
      <ul role="list"
          class="divide-y divide-gray-100 overflow-hidden bg-white shadow-md ring-1 ring-gray-900/5 sm:rounded-xl"
          [ngClass]="{'pointer-events-none' : !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventGetExtendedEvent())}">
        <li *ngFor="let e of filtered_events; let i = index" [routerLink]="!selectionMode ? ['/events'] : undefined"
            [queryParams]="{ event: e.id, previous: '' }"
            queryParamsHandling="merge"
            class="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer sm:px-6"
            [attr.data-index]="i" [attr.id]="e.id" (click)="selectionItem(e)">
          <div class="flex gap-x-4">
            <input *ngIf="selectionMode" [(ngModel)]="e.selected" class="z-30 my-auto" type="checkbox">
            <img class="h-12 w-12 p-2 flex-none rounded-full bg-gray-700" src="./assets/images/icon_nobackground_md.png"
                 alt="">
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                <a>
                  <span class="absolute inset-x-0 -top-px bottom-0"></span>
                  {{ e.name }}
                  <span class="text-gray-400" *ngIf="e.publicTitle != ''">({{ e.publicTitle }})</span>
                </a>
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
                <span class="relative truncate">{{ e.id }}</span>
              </p>
            </div>
          </div>
          <div class="flex items-center gap-x-4">
            <div class="hidden sm:flex sm:flex-col sm:items-end">
              <p class="text-sm leading-6 text-gray-900">Ende: {{ (e.end) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
              <p class="text-sm leading-6 text-gray-900">Start: {{ (e.start) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
            </div>
            <svg
              *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EventGetExtendedEvent()) && !selectionMode"
              class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </li>
      </ul>

    </div>
  </div>

  <div *ngIf="event && PermissionService.CheckPermission(PermissionService.CombinedPermission_EventGetExtendedEvent())"
       class="absolute inset-0 pt-4 overflow-y-auto px-4 flex">
    <div class="max-w-7xl mx-auto w-full">
      <div class="grid grid-cols-1">
        <div class="flex w-full">
          <svg *ngIf="location_id == ''" [routerLink]="['/events']" [queryParams]="{ event: '', previous: event.id }"
               fragment="{{event.id}}" queryParamsHandling="merge" xmlns="http://www.w3.org/2000/svg" width="20"
               height="20" fill="currentColor"
               class="col-span-1 mr-2 self-center bi bi-arrow-left-circle hover:text-theme-primary-700 hover:cursor-pointer"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
          <svg *ngIf="location_id != ''" [routerLink]="['/events']" [queryParams]="{ event: event.id, location: '' }"
               queryParamsHandling="merge" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="col-span-1 mr-2 self-center bi bi-arrow-left-circle hover:text-theme-primary-700 hover:cursor-pointer"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
          <div class="flex-grow"></div>
          <div class="isolate flex rounded-md shadow-sm col-span-12 md:col-span-2">
            <button *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_ShiftMakerComponent())"
                    [routerLink]="['/' + Routes.CompanyModule + '/' + CompanyRoutes.ShiftMaker]"
                    [queryParams]="{event: event.id, date: event.start.toISOString()}" type="button"
                    class="mr-2 relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar3"
                   viewBox="0 0 16 16">
                <path
                  d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                <path
                  d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </button>

            <button
              *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Event_GetEmployeeOrders())"
              (click)="OpenEmployeeSales(event.id)" type="button"
              class="mr-2 relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                <path fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
              </svg>
            </button>

            <button
              *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EventDownloadExtendedEvent())"
              [class.rounded-r-md]="
                  !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventReadLocationConfig()) &&
                  !PermissionService.CheckPermission(PermissionService.Inventory_Event_Delete()) &&
                  !PermissionService.CheckPermission(PermissionService.Inventory_Event_Get())"
              (click)="download()" type="button"
              class="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg *ngIf="!downloading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                   class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"/>
                <path
                  d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
              </svg>
              <svg *ngIf="downloading" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="animate-spin bi bi-slash-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
              </svg>
            </button>

            <button
              *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EventReadLocationConfig())"
              [class.rounded-l-md]="
                  !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventDownloadExtendedEvent()) &&
                  PermissionService.CheckPermission(PermissionService.Inventory_Event_GetEmployeeOrders())"
              [class.rounded-r-md]="
                  !PermissionService.CheckPermission(PermissionService.Inventory_Event_Delete()) &&
                  !PermissionService.CheckPermission(PermissionService.Inventory_Event_Get())"
              (click)="locationConfigVisible = true" type="button"
              class="-ml-px relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                <path
                  d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
              </svg>
            </button>
            <button *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Event_Get())"
                    [class.rounded-l-md]="
                  !PermissionService.CheckPermission(PermissionService.Inventory_Event_GetEmployeeOrders()) &&
                  !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventDownloadExtendedEvent()) &&
                  !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventReadLocationConfig())"
                    [class.rounded-r-md]="!PermissionService.CheckPermission(PermissionService.Inventory_Event_Delete())"
                    (click)="redirectToSettings(event.id)" type="button"
                    class="-ml-px relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </button>

            <button *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Event_Delete())"
                    [class.rounded-l-md]="
            !PermissionService.CheckPermission(PermissionService.Inventory_Event_Get()) &&
            !PermissionService.CheckPermission(PermissionService.Inventory_Event_GetEmployeeOrders()) &&
            !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventDownloadExtendedEvent()) &&
            !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventReadLocationConfig())"
                    (click)="Remove(event);" type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-red-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-calendar-x"
                   viewBox="0 0 16 16">
                <path
                  d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/>
                <path
                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
              </svg>
            </button>
          </div>
        </div>
        <h1
          class="md:text-center pt-4 pb-4 flex-grow col-span-11 md:col-span-8 self-center font-semibold leading-6 text-gray-900 md:!text-3xl">
          {{ event.name }}{{ !location ? '' : ':&nbsp;' + location.name }}
          <br>
          <span class="fw-light !text-sm">Verbindungsstatus:
          <span (click)="Connection?.start()"
                class="fw-normal place-self-center pl-2 text-yellow-400 pointer-events-none select-none"
                [ngClass]="{
                '!text-green-600': Connection?.state == HubConnectionState.Connected,
                '!text-red-600 pointer-events-auto cursor-pointer': Connection?.state == HubConnectionState.Disconnected
                }">
            {{ Connection?.state }}
          </span>
        </span>
        </h1>
      </div>

      <div class="md:hidden grid grid-cols-1 gap-3">
        <div class="bg-white shadow-lg w-full rounded-lg">
          <div class="px-3 py-2">
            <p class="text-gray-600 text-sm fw-bold">Gesamtumsatz</p>
            <p class="text-2xl fw-bold">{{ event.oTotal + event.nTotal | currency: "EUR" }}</p>
          </div>
        </div>
        <div class="bg-white shadow-lg w-full rounded-lg">
          <div class="grid grid-cols-2 divide-x divide-y">
            <div class="px-3 py-2">
              <p class="text-gray-600 text-sm fw-bold">Artikel</p>
              <div class="grid grid-cols-2">
                <p>{{ event.nAmount }}x</p>
                <p class="text-lg text-end">{{ event.nTotal | currency: "EUR" }}</p>
              </div>
            </div>
            <div class="px-3 py-2">
              <p class="text-gray-600 text-sm fw-bold">Sonstiges</p>
              <div class="grid grid-cols-2">
                <p>{{ event.oAmount }}x</p>
                <p class="text-lg text-end">{{ event.oTotal | currency: "EUR" }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="rounded-md max-w-7xl mx-auto hidden md:flex">
        <div class="mx-auto max-w-7xl w-full">
          <div class="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 2xl:grid-cols-4 gap-3">
            <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg w-full">
              <p class="text-sm font-medium leading-6 text-gray-700 inline-flex">Sonstiges</p>
              <p class="mt-2 grid grid-cols-2 items-baseline gap-x-2">
              <span
                class="text-3xl font-semibold 2xl:font-light tracking-tight text-gray-900">{{ event.oTotal | currency: "EUR" }}</span>
                <span
                  class="text-3xl font-semibold 2xl:font-light tracking-tight text-gray-900 justify-self-end">{{ event.oAmount }}
                  x</span>
              </p>
            </div>
            <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
              <p class="text-sm font-medium leading-6 text-gray-700 inline-flex">Artikel</p>
              <p class="mt-2 flex items-baseline gap-x-2 grid grid-cols-2">
              <span
                class="text-3xl font-semibold 2xl:font-light tracking-tight text-gray-900 ">{{ event.nTotal | currency: "EUR" }}</span>
                <span
                  class="text-3xl font-semibold 2xl:font-light tracking-tight text-gray-900 justify-self-end">{{ event.nAmount }}
                  x</span>
              </p>
            </div>
            <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
              <p class="text-sm font-medium leading-6 text-gray-700">Gesamtbetrag</p>
              <p class="mt-2 flex items-baseline gap-x-2 grid grid-cols-2">
              <span
                class="text-3xl font-semibold tracking-tight text-gray-900">{{ event.nTotal + event.oTotal | currency: "EUR" }}</span>
              </p>
            </div>
            <div class="bg-white shadow-lg px-4 py-3 sm:px-6 lg:px-8 rounded-lg">
              <p class="text-sm font-medium leading-6 text-gray-700">Bestseller</p>
              <p class="mt-2 flex items-baseline gap-x-2">
              <span
                class="text-4xl font-semibold tracking-tight text-gray-900">{{ (event.compositions.length > 0 ? event.compositions[0].composition.name : "") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <ul role="list"
          class="pt-4 grid grid-cols-1 gap-x-3 gap-y-4 lg:grid-cols-2 grid-rows-1 lg:grid-rows-2 h-50 max-w-7xl mx-auto"
          style="max-height: 50vh;">
        <li [class.hidden]="views[view_index%4]!='location'"
            class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
          <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt"
                 viewBox="0 0 16 16">
              <path
                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            </svg>
            <div class="text-sm font-medium leading-6 text-gray-900">Verkaufspunkt: {{ event.total | currency: "EUR" }}
            </div>
            <div (click)="view_index = view_index+1"
                 class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
              </svg>
            </div>
          </div>
          <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
            <div *ngFor="let location of event.locations"
                 class="flex justify-between gap-x-4 py-1 hover:underline cursor-pointer"
                 [routerLink]="['/events']" [queryParams]="{ location: location.location.id }"
                 queryParamsHandling="merge">
              <dt class="text-gray-500 fw-normal">{{ location.location.name }}</dt>
              <dd class="text-gray-700">{{ location.total | currency: "EUR" }}</dd>
            </div>
          </dl>
        </li>

        <li [class.hidden]="views[view_index%4]!='others'"
            class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
          <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-ticket-perforated" viewBox="0 0 16 16">
              <path
                d="M4 4.85v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Z"/>
              <path
                d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3h-13ZM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9V4.5Z"/>
            </svg>
            <div class="text-sm font-medium leading-6 text-gray-900">Sonstiges: {{ event.oTotal | currency: "EUR" }}
            </div>
            <div (click)="view_index = view_index+1"
                 class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
              </svg>
            </div>
          </div>
          <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
            <div *ngFor="let other of event.others" class="flex justify-between gap-x-4 py-1">
              <dt class="text-gray-500 fw-normal">{{ other.amount }}x&nbsp;{{ other.composition.name }}</dt>
              <dd class="text-gray-700">{{ other.total | currency: "EUR" }}</dd>
            </div>
          </dl>
        </li>

        <li [class.hidden]="views[view_index%4]!='payment'"
            class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
          <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wallet"
                 viewBox="0 0 16 16">
              <path
                d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"/>
            </svg>
            <div class="text-sm font-medium leading-6 text-gray-900">
              Transaktionstypen: {{ (event.oTotal + event.nTotal) | currency: "EUR" }}
            </div>
            <div (click)="view_index = view_index+1"
                 class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
              </svg>
            </div>
          </div>
          <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
            <div *ngFor="let payment of event.payments" class="flex justify-between gap-x-4 py-1">
              <dt class="text-gray-500 fw-normal">{{ payment.amount }}
                x {{ n5paymentMapper.GetString(payment.bookingType) }}
              </dt>
              <dd class="text-gray-700">{{ payment.total | currency: "EUR" }}</dd>
            </div>
          </dl>
        </li>

        <li [class.hidden]="views[view_index%4]!='drinks'"
            class="lg:!block overflow-hidden rounded-xl border border-gray-200 shadow-lg">
          <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cup-straw"
                 viewBox="0 0 16 16">
              <path
                d="M13.902.334a.5.5 0 0 1-.28.65l-2.254.902-.4 1.927c.376.095.715.215.972.367.228.135.56.396.56.82 0 .046-.004.09-.011.132l-.962 9.068a1.28 1.28 0 0 1-.524.93c-.488.34-1.494.87-3.01.87-1.516 0-2.522-.53-3.01-.87a1.28 1.28 0 0 1-.524-.93L3.51 5.132A.78.78 0 0 1 3.5 5c0-.424.332-.685.56-.82.262-.154.607-.276.99-.372C5.824 3.614 6.867 3.5 8 3.5c.712 0 1.389.045 1.985.127l.464-2.215a.5.5 0 0 1 .303-.356l2.5-1a.5.5 0 0 1 .65.278zM9.768 4.607A13.991 13.991 0 0 0 8 4.5c-1.076 0-2.033.11-2.707.278A3.284 3.284 0 0 0 4.645 5c.146.073.362.15.648.222C5.967 5.39 6.924 5.5 8 5.5c.571 0 1.109-.03 1.588-.085l.18-.808zm.292 1.756C9.445 6.45 8.742 6.5 8 6.5c-1.133 0-2.176-.114-2.95-.308a5.514 5.514 0 0 1-.435-.127l.838 8.03c.013.121.06.186.102.215.357.249 1.168.69 2.438.69 1.27 0 2.081-.441 2.438-.69.042-.029.09-.094.102-.215l.852-8.03a5.517 5.517 0 0 1-.435.127 8.88 8.88 0 0 1-.89.17zM4.467 4.884s.003.002.005.006l-.005-.006zm7.066 0-.005.006c.002-.004.005-.006.005-.006zM11.354 5a3.174 3.174 0 0 0-.604-.21l-.099.445.055-.013c.286-.072.502-.149.648-.222z"/>
            </svg>
            <div class="text-sm font-medium leading-6 text-gray-900">Artikel: {{ event.nAmount }}</div>
            <div (click)="view_index = view_index+1"
                 class="text-gray-600 hover:text-gray-800 cursor-pointer lg:hidden flex w-full justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
              </svg>
            </div>
          </div>
          <dl class="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 overflow-y-auto h-100 !pb-32 lg:!pb-20">
            <div *ngFor="let composition of event.compositions" class="flex justify-between gap-x-4 py-1">
              <dt class="text-gray-500 fw-normal">{{ composition.composition.name }}</dt>
              <dd class="text-gray-700">{{ composition.amount }}</dd>
            </div>
          </dl>
        </li>


      </ul>

      <div class="">
        <!--<div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">

          </div>
          <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button type="button" class="block rounded-md bg-theme-primary-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">Export</button>
          </div>
        </div>-->
        <div class="mt-8 flow-root">
          <div class=" -my-2 overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col"
                      class="whitespace-nowrap py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900">Artikel
                  </th>
                  <th scope="col"
                      class="whitespace-nowrap py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900">Beleg
                  </th>
                  <th scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                    Einzelpreise
                  </th>
                  <th (click)="SortBy('price')" scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div class="inline-flex">
                      <div class="grid grid-cols-1 gap-1">
                        <svg [class.opacity-100]="this.field == 'price' && !this.inverse"
                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                          <path
                            d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                        <svg [class.opacity-100]="this.field == 'price' && this.inverse"
                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                          <path
                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                      <a
                        class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Gesamt</a>
                    </div>
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Verkaufspunkt
                  </th>
                  <th scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                    Profil
                  </th>
                  <th (click)="SortBy('date')" scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div class="inline-flex">
                      <div class="grid grid-cols-1 gap-1">
                        <svg [class.opacity-100]="this.field == 'date' && !this.inverse"
                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                          <path
                            d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                        <svg [class.opacity-100]="this.field == 'date' && this.inverse"
                             xmlns="http://www.w3.org/2000/svg"
                             width="16" height="16" fill="currentColor"
                             class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                          <path
                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                      <a
                        class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Zeitstempel</a>
                    </div>
                  </th>
                  <th scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell">
                    Transaction ID
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let order of event.orders; let index = index; TrackBy: TrackByIndex"
                    [class.bg-gray-200]="order.bookingType == N5BookingType.Employee">
                  <td class="whitespace-nowrap py-2 pl-2 pr-3 text-sm text-gray-500">
                    <p *ngFor="let composition of order.compositions">
                      <span>{{ composition.quantity }}x&nbsp;</span>
                      <span>{{ composition.composition.name }}</span>
                    </p>
                  </td>
                  <td class="pl-2">
                    <a [href]="order.receipt" target="_blank" *ngIf="order.receipt"
                       class="bg-red-300 text-theme-primary-700 hover:text-theme-primary-900 select-none text-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
                      <path fill="currentColor"
                            d="M8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h4a1 1 0 0 0 0-2m14-6a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 0 0 2h4a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1M20 1h-4a1 1 0 0 0 0 2h4a1 1 0 0 1 1 1v4a1 1 0 0 0 2 0V4a3 3 0 0 0-3-3M2 9a1 1 0 0 0 1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 0 0-2H4a3 3 0 0 0-3 3v4a1 1 0 0 0 1 1m8-4H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1M9 9H7V7h2Zm5 2h4a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1m1-4h2v2h-2Zm-5 6H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1m-1 4H7v-2h2Zm5-1a1 1 0 0 0 1-1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1m4-3a1 1 0 0 0-1 1v3a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1m-4 4a1 1 0 1 0 1 1a1 1 0 0 0-1-1"/>
                    </svg>
                    </span>
                    </a>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden sm:block">
                    <p *ngFor="let composition of order.compositions">
                    <span class="text-red-600"
                          *ngIf="composition.compositionOverride && composition.compositionOverride.price != null">{{ composition.compositionOverride.price | currency: "EUR" }}</span>
                      <span [class.text-red-600]="composition.composition.special"
                            *ngIf="!(composition.compositionOverride && composition.compositionOverride.price != null)">{{ composition.composition.price | currency: "EUR" }}</span>
                    </p>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <div class="inline-flex"
                         (click)="ShowForeignTransactionId(order)"
                         [ngClass]="{'text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer' : order.bookingType == 1 && order.foreignTransactionId }">
                      <svg *ngIf="order.bookingType == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                           fill="currentColor" class="bi bi-cash-coin mr-2 place-self-center" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                        <path
                          d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                        <path
                          d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
                      </svg>
                      <svg *ngIf="order.bookingType == 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                           fill="currentColor" class="bi bi-credit-card-2-back-fill mr-2 place-self-center"
                           viewBox="0 0 16 16">
                        <path
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
                      </svg>
                      <svg *ngIf="order.bookingType == 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                           fill="currentColor" class="bi bi-person-circle mr-2 place-self-center" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        <path fill-rule="evenodd"
                              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                      </svg>
                      <span class="place-self-center">
                      {{ order.oTotal + order.nTotal | currency: "EUR" }}
                    </span>
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 cursor-pointer hover:text-theme-primary-700"
                    [routerLink]="['/events']" [queryParams]="{ location: order.location.id }"
                    queryParamsHandling="merge">{{ order.location.name }}
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-900 hidden md:table-cell">{{ order.configuration.name }}
                  </td>
                  <td
                    class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ (order.created) | date : "dd.MM.YYYY, HH:mm" }}
                  </td>


                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden xl:table-cell">{{ order.id }}</td>
                </tr>

                <!-- More transactions... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="locations && event && locationConfigVisible && PermissionService.CheckPermission(PermissionService.CombinedPermission_EventReadLocationConfig())"
  class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto lg:pl-72">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="grid grid-cols-2 border-b-2 border-gray-400 content-center pb-2">
            <div class="flex">
              <p class="fw-bold text-lg place-self-center">Verkaufspunkt Konfiguration</p>
            </div>
            <div class="w-full p-0.5 bg-yellow-400 rounded-md self-start">
              <select
                [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_EventSetLocationConfig())"
                [ngModel]="generalConfiguration" (ngModelChange)="GeneralConfigurationChanged($event)"
                class="w-full p-1">
                <option selected [ngValue]="undefined">---</option>
                <option class="ring-1 ring-yellow-300 border-1 border-yellow-300"
                        *ngFor="let configuration of configurations" [ngValue]="configuration">{{ configuration.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="grid grid-cols-2" *ngFor="let location of locations;let index = index;trackBy:TrackByIndex;">
            <span class="text-black">{{ location.location.name }}</span>
            <select
              [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_EventSetLocationConfig())"
              class="p-1" [(ngModel)]="locations[index].configuration">
              <option selected [value]="undefined">---</option>
              <option *ngFor="let configuration of configurations" [ngValue]="configuration">{{ configuration.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:grid-cols-2 sm:gap-3">
          <button (click)="locationConfigVisible = false" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0">
            Cancel
          </button>
          <button
            *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EventSetLocationConfig())"
            (click)="SaveLocationConfiguration()" type="button"
            class="mt-2 sm:!mt-0 inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="employeeSales && PermissionService.CheckPermission(PermissionService.Inventory_Event_GetEmployeeOrders())"
  class="absolute inset-0 z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="absolute inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="grid grid-cols-1 border-b-2 border-gray-400 content-center pb-2">
            <div class="flex">
              <p class="fw-bold text-lg place-self-center">Mitarbeiterartikel Übersicht</p>
            </div>
          </div>
          <div *ngIf="employeeSales.length == 0" class="pt-2">
            <span>Keine Einträge.</span>
          </div>
          <div class="grid grid-cols-2 border-b-2 py-2"
               *ngFor="let employee of employeeSales;let index = index;trackBy:TrackByIndex;">
            <span class="text-black">{{ employee.firstName }} {{ employee.lastName }}<br><span
              class="fw-bold">{{ employee.total | currency: "EUR" }}</span></span>

            <div>
              <div *ngFor="let order of employee.orders" class="grid grid-cols-1">
                <div *ngFor="let composition of order.compositions">
                  <span
                    *ngIf="(employee.orders.indexOf(order) == 0 && order.compositions.indexOf(composition) == 0 && !employee.details) || employee.details">{{ composition.quantity }}
                    x {{ composition.composition.name }}</span>
                </div>
              </div>
              <div
                *ngIf="(employee.orders.length > 1 || employee.orders[0].compositions.length > 1) && !employee.details">
                <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none"
                   (click)="employee.details = true">Alle anzeigen</a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:gap-3">
          <button (click)="employeeSales = undefined" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0">
            Schließen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="suppliers" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 lg:left-72 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                 class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
              <path
                d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Datei auswählen</h3>
            <div class="mt-2">
              <nav class="flex flex-1 flex-col" aria-label="Sidebar">
                <ul role="list" class="-mx-2 space-y-1">
                  <li>
                    <!-- Current: "bg-gray-50 text-theme-primary-700", Default: "text-gray-700 hover:text-theme-primary-700 hover:bg-gray-50" -->
                    <a (click)="download('__full')"
                       class="cursor-pointer select-none bg-gray-200 text-theme-primary-700 group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold">Vollständiger
                      Bericht</a>
                  </li>
                  <li *ngFor="let supplier of suppliers">
                    <a (click)="download(supplier.id)"
                       class="cursor-pointer text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold">{{ supplier.name }}</a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button (click)="downloading = false; suppliers = undefined;" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
            Zurück zur Übersicht
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-supplier-popup [(visible)]="singleEventSupplierSelectorVisible" (selectedChange)="download(($event)?.id ?? '')"
                    na="Vollständiger Bericht"></app-supplier-popup>
<app-supplier-popup [(visible)]="supplierSelectorVisible" (selectedChange)="massDownload(($event)?.id ?? '')"
                    na="Vollständiger Bericht"></app-supplier-popup>

<app-error-banner [(error)]="error"></app-error-banner>
