import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Sorting} from "../../x-models/x-enums/sorting";

@Component({
  selector: 'app-sorting-indicator',
  templateUrl: './sorting-indicator.component.html',
  styleUrls: ['./sorting-indicator.component.scss']
})
export class SortingIndicatorComponent {
  @Input() currentSorting!: Sorting;
  @Input() inverseState!: boolean;
  @Input() thisSorting!: Sorting;
  @Input() displayName!: string;
  @Output() sortingChange = new EventEmitter();
}
