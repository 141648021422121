import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, finalize, map, Observable} from "rxjs";
import {UserJwt} from "../models/admin_owner/user/user-jwt";
import {ActivatedRoute, Router, RouterStateSnapshot} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {GeneralHttpService} from "../http-requests/general-http.service";
import {Roles} from "./x-enums/roles";
import {CookieService} from "../_services/cookie.service";
import {CookieType} from "../x-models/x-enums/cookie-type";
import {UserSelectorService} from "../_services/user-selector.service";
import {state} from "@angular/animations";
import {UrlHelper} from "../helpers/url-helper";

@Injectable({
  providedIn: 'root'
})

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private userSubject: BehaviorSubject<UserJwt | null>;
  public user: Observable<UserJwt | null>;

  constructor(private router: Router,
              private http: HttpClient,
              @Inject("BASE_URL") private baseUrl: string,
              private generalHttpService: GeneralHttpService,
              private cookieService: CookieService,
              private userSelectorService: UserSelectorService,
              private route: ActivatedRoute) {
    this.userSubject = new BehaviorSubject((JSON.parse(localStorage.getItem('user')!)));
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.baseUrl + "/auth/user/Login", {
      "Name": username,
      "Password": password
    }).pipe(map(user => {
      this.cookieService.setLocalStorage(CookieType.Required, 'user', JSON.stringify(user));
      this.userSubject.next(user);

      this.userSelectorService.add(user);

      return user;
    }));
  }

  logout() {
    let uid = this.userValue!.id;
    this.generalHttpService.logout(this.userValue!.jwt ?? "")
      .pipe(finalize(() => {
        localStorage.removeItem('user');
        this.userSubject.next(null);

        // remove from user selector
        this.userSelectorService.remove(uid);

        this.router.navigate(['/login']);
      })).subscribe({
      error: (err) => {
        console.error(err);
      }
    })
  }

  switch() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.routerState.snapshot.url}}).then(() => {
    });
  }

  select(user: UserJwt) {
    this.cookieService.setLocalStorage(CookieType.Required, 'user', JSON.stringify(user));
    // this.userSubject.next(user);
    const returnUrl = UrlHelper.QueryParams.returnUrl || '/';
    // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    window.open(returnUrl, '_self');
  }

  isEmployee(): boolean {
    return this.userValue?.permissions.includes(Roles.Employee) ?? true;
  }
}
