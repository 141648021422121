import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {EventDto} from "../../models/event/event-dto";
import {EventEdto} from "../../models/event/event-edto";
import {EventOdto} from "../../models/event/event-odto";

@Injectable({
  providedIn: 'root'
})
export class EventHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) { }

  list(shard: string = "", start: Date | null = null, end: Date | null = null): Observable<EventOdto[]> {
    return this.httpClient.get<EventOdto[]>(this.baseUrl + "/Owner/Event/List?shard=" + shard +
      "&start=" + (start == null ? "" : start?.toISOString()) +
      "&end=" + (end == null ? "" : end?.toISOString())
    );
  }

  get(shard: string, id: string, location: string): Observable<EventEdto>{
    return this.httpClient.get<EventEdto>(this.baseUrl + "/Owner/Event/Get?shard=" + shard +
      "&id=" + id + "&location=" + location);
  }
}
