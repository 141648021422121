import {Component, Input, Output} from '@angular/core';
import {SupplierService} from "../../_services/_dto-services/supplier.service";
import {PopupBase} from "../../../../_abstracts/popup-base";
import {SupplierDto} from "../../x-models/supplier-dto";

@Component({
  selector: 'app-supplier-popup',
  templateUrl: './supplier-popup.component.html',
  styleUrls: ['./supplier-popup.component.scss']
})
export class SupplierPopupComponent extends PopupBase<SupplierDto> {
  constructor(public SupplierService: SupplierService) {
    super();
  }
}
