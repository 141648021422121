<main class="flex-1 pb-8">
  <div class="">
    <div class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
      <h2
        *ngIf="currentOrNextEvent && PermissionService.CheckPermission(PermissionService.CombinedPermission_HomeEventPermission())"
        class="text-lg font-medium leading-6 text-gray-900">{{ isCurrentEvent ? "Aktuelles Event" : "Nächstes Event" }}</h2>
      <div
        [ngClass]="{'pointer-events-none cursor-normal': !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventGetExtendedEvent())}"
        *ngIf="currentOrNextEvent" [routerLink]="['/events']" [queryParams]="{ event: currentOrNextEvent.id }"
        queryParamsHandling="merge"
        class="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer sm:px-6 bg-white rounded-md shadow mt-2 mb-4">
        <div class="flex gap-x-4">
          <img class="h-12 w-12 p-2 flex-none rounded-full bg-gray-700" src="./assets/images/icon_nobackground_md.png"
               alt="">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              <a>
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                {{ currentOrNextEvent.name }}
              </a>
            </p>
            <p class="mt-1 flex text-xs leading-5 text-gray-500">
              <span class="relative truncate">{{ currentOrNextEvent.id }}</span>
            </p>
          </div>
        </div>
        <div class="flex items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <p class="text-sm leading-6 text-gray-900">{{ (currentOrNextEvent.start) | date : "dd.MM.YYYY, HH:mm" }}
              Uhr</p>
            <p class="text-sm leading-6 text-gray-900">{{ (currentOrNextEvent.end) | date : "dd.MM.YYYY, HH:mm" }}
              Uhr</p>
          </div>
          <svg *ngIf="GetEventDetailsPermission()" class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20"
               fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
      </div>

      <h2 class="text-lg font-medium leading-6 text-gray-900">Tipp des Tages</h2>
      <div class="mt-2 mb-8">
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="px-3 py-2 inline-flex">
            <div class="h-full w-8 place-self-center text-yellow-600">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path fill="currentColor"
                      d="M6.813 2.406L5.405 3.812L7.5 5.906L8.906 4.5zm18.375 0L23.093 4.5L24.5 5.906l2.094-2.093zM16 3.03c-.33.004-.664.023-1 .064c-.01 0-.02-.002-.03 0c-4.056.465-7.284 3.742-7.845 7.78c-.448 3.25.892 6.197 3.125 8.095a5.238 5.238 0 0 1 1.75 3.03v6h2.28c.348.597.983 1 1.72 1s1.372-.403 1.72-1H20v-4h.094v-1.188c0-1.466.762-2.944 2-4.093C23.75 17.06 25 14.705 25 12c0-4.94-4.066-9.016-9-8.97m0 2c3.865-.054 7 3.11 7 6.97c0 2.094-.97 3.938-2.313 5.28l.032.032A7.792 7.792 0 0 0 18.279 22h-4.374c-.22-1.714-.955-3.373-2.344-4.563c-1.767-1.5-2.82-3.76-2.468-6.312c.437-3.15 2.993-5.683 6.125-6.03a6.91 6.91 0 0 1 .78-.064zM2 12v2h3v-2zm25 0v2h3v-2zM7.5 20.094l-2.094 2.093l1.407 1.407L8.905 21.5zm17 0L23.094 21.5l2.093 2.094l1.407-1.407zM14 24h4v2h-4z"/>
              </svg>
            </div>
            <div class="flex flex-col ml-4">
              <div class="inline-flex text-yellow-600">
                <span class="text-lg place-self-center fw-bold">Schon gewusst?</span>
              </div>
              <div>
                <span class="font-bold">Option / Alt + Linke Maustaste</span>
                <span> öffnet Seiten in neuem Tab.</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <h2
        *ngIf="dashboardStats && PermissionService.CheckPermission(PermissionService.Inventory_General_GetDashboardStats())"
        class="text-lg font-medium leading-6 text-gray-900">Overview</h2>
      <div
        *ngIf="dashboardStats && PermissionService.CheckPermission(PermissionService.Inventory_General_GetDashboardStats())"
        class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->

        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="px-5 py-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z"/>
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="truncate text-sm font-medium text-gray-500">Anzahl Items</dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">{{ dashboardStats.itemCount }}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="px-5 py-3"
               *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_ItemComponent())">
            <div class="text-sm">
              <a [routerLink]="[ '/items' ]"
                 class="font-medium text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Alle Items
                anzeigen&nbsp;&rarr;</a>
            </div>
          </div>
        </div>

        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="px-5 py-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z"/>
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="truncate text-sm font-medium text-gray-500">Anzahl Verkaufspunkte</dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">{{ dashboardStats.locationCount }}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="px-5 py-3"
               *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_LocationsComponent())">
            <div class="text-sm">
              <a [routerLink]="['/locations']" href="#"
                 class="font-medium text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Alle
                Verkaufspunkte anzeigen&nbsp;&rarr;</a>
            </div>
          </div>
        </div>

        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="px-5 py-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z"/>
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="truncate text-sm font-medium text-gray-500">Anzahl Events</dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">{{ dashboardStats.eventCount }}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="px-5 py-3"
               *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_EventsComponent())">
            <div class="text-sm">
              <a [routerLink]="['/events']" href="#"
                 class="font-medium text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Alle Events
                anzeigen&nbsp;&rarr;</a>
            </div>
          </div>
        </div>

        <!-- More items... -->
      </div>
    </div>

    <div *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_HomeEventPermission())"
         class="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8 flex">
      <h2 class="place-self-center">Letzte Events</h2>
      <div class="grow"></div>
      <a *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_EventsComponent())"
         [routerLink]="[ '/events' ]"
         class="select-none text-sm place-self-center text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Alle
        anzeigen</a>
    </div>
    <div *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_HomeEventPermission())"
         class="mx-auto pt-4 max-w-6xl px-4 sm:px-6 lg:px-8">
      <ul role="list"
          [ngClass]="{'!pointer-events-none' : !PermissionService.CheckPermission(PermissionService.CombinedPermission_EventGetExtendedEvent())}"
          class="divide-y divide-gray-100 overflow-hidden bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl">
        <li *ngFor="let event of lastEvents" [routerLink]="['/events']" [queryParams]="{ event: event.id }"
            queryParamsHandling="merge"
            class="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer sm:px-6">
          <div class="flex gap-x-4">
            <img class="h-12 w-12 p-2 flex-none rounded-full bg-gray-700" src="./assets/images/icon_nobackground_md.png"
                 alt="">
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                <a>
                  <span class="absolute inset-x-0 -top-px bottom-0"></span>
                  {{ event.name }}
                </a>
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
                <span class="relative truncate">{{ event.id }}</span>
              </p>
            </div>
          </div>
          <div class="flex items-center gap-x-4">
            <div class="hidden sm:flex sm:flex-col sm:items-end">
              <p class="text-sm leading-6 text-gray-900">{{ (event.start) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
              <p class="text-sm leading-6 text-gray-900">{{ (event.end) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
            </div>
            <svg *ngIf="GetEventDetailsPermission()" class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20"
                 fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </li>
      </ul>
    </div>
  </div>
</main>
