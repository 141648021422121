import {CompositionNl} from "../composition-nl";
import {CompositionDto} from "../composition-dto";

export class OtherCounter {
  constructor(composition: CompositionDto) {
    this.composition = composition;
  }

  composition: CompositionDto;
  amount: number = 0;
  total: number = 0;
}
