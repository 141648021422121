import {Component, Directive, EventEmitter, Input, Output} from "@angular/core";

@Directive()
export abstract class PopupBase<T> {
  @Input() na: string = "Keine Auswahl";
  @Input() save: string = "Wählen";

  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() selectedChange = new EventEmitter<T | undefined>();

  Save() {
    let entity = this.SelectedEntity;
    this.selectedChange.next(entity);
    this.visible = false;
    this.visibleChange.next(this.visible);
  }

  Cancel() {
    this.visible = false;
    this.visibleChange.next(this.visible);
  }

  public SelectedEntity: T | undefined;

  Set(entity: T | undefined) {
    this.SelectedEntity = entity;
  }
}
