import {Component, OnInit} from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import {SettingHttpService} from "../x-http-requests/setting-http.service";
import {PermissionService} from "../../../_auth/permission.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {state} from "@angular/animations";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  encryption_key: string = "";
  encryption_key_confirm: string = "";
  su_api_key: string = "";
  telegram_bot_key: string = "";
  telegram_cvd_channel: string = "";
  telegram_confirmation_code: number | undefined;
  error?: HttpErrorResponse;
  pattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$/g;
  upper = /^(?=.*[A-Z])/g;
  lower = /^(?=.*[a-z])/g;
  digit = /^(?=.*[0-9])/g;
  special = /^(?=.*[!@#$&*])/g;
  length = /^.{8,32}$/g;

  sumUpIsConfigured: boolean = false;
  telegramIsConfigured: boolean = false;
  telegramWaitingForConfirmation: boolean = false;


  constructor(private settingHttpService: SettingHttpService,
              public permissionService: PermissionService,
              private globalAlertService: GlobalAlertService) {
  }

  CardPaymentReset() {
    this.encryption_key = "";
    this.encryption_key_confirm = "";
    this.su_api_key = "";
    this.LoadData();
  }

  TelegramReset() {
    this.telegram_bot_key = "";
    this.telegram_cvd_channel = "";
    this.telegram_confirmation_code = undefined;
    this.telegramWaitingForConfirmation = false;
    this.LoadData();
  }

  CardPaymentSave(): any {
    if (this.encryption_key_confirm != this.encryption_key) {
      return this.error = new HttpErrorResponse({
        statusText: "Keys not matching!"
      })
    } else if (!this.encryption_key.match(this.pattern)) {
      return this.error = new HttpErrorResponse({
        statusText: "Key requirements are not met!"
      })
    } else if (this.su_api_key == "") {
      return this.error = new HttpErrorResponse({
        statusText: "API Key is empty!"
      })
    }

    let cipher = this.su_api_key;

    this.settingHttpService.SetAuth("su_api_key", cipher, 1).subscribe(result => {
      this.error = undefined;
      this.encryption_key = "";
      this.encryption_key_confirm = "";
      this.su_api_key = "";
      this.sumUpIsConfigured = true;
    }, error => {
      this.error = error
    })
  }

  TelegramSave() {
    let data: object[] = [];
    if (this.telegram_bot_key == "" || this.telegram_cvd_channel == "") {
      this.error = new HttpErrorResponse({
        statusText: "Key and Channel must not be empty!"
      })
      return;
    }
    if (this.telegram_bot_key != "") data.push({
      "id": "telegram_bot_key", "value": this.telegram_bot_key, "type": 2
    });
    if (this.telegram_cvd_channel != "") data.push({
      "id": "telegram_cvd_channel", "value": this.telegram_cvd_channel, "type": 2
    })

    if (this.telegramWaitingForConfirmation) {
      if (this.telegram_confirmation_code == undefined || this.telegram_confirmation_code == 0) {
        this.error = new HttpErrorResponse({
          statusText: "Confirmation code must be provided!"
        });
        return;
      }

      data.push({
        "id": "telegram_confirmation_token", "value": "" + this.telegram_confirmation_code, "type": 2
      });
    }

    this.settingHttpService.SetAuths(data).subscribe(x => {
      this.error = undefined;
      if (!this.telegramWaitingForConfirmation && data.length == 2) {
        this.telegramWaitingForConfirmation = true;
      } else if (this.telegramWaitingForConfirmation && data.length == 3) {
        this.telegram_bot_key = "";
        this.telegram_cvd_channel = "";
        this.telegram_confirmation_code = undefined;
        this.telegramWaitingForConfirmation = false;
        this.telegramIsConfigured = true;
      }
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  LoadData() {
    this.settingHttpService.SumUpIsConfigured().subscribe(x => {
      this.error = undefined;
      this.sumUpIsConfigured = x;
    }, error => {
      this.error = error;
      console.error(error);
    })
    this.settingHttpService.TelegramIsConfigured().subscribe(x => {
      this.error = undefined;
      this.telegramIsConfigured = x;
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  timeZones: string[] = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ].sort()

  selectedTimeZone: string | undefined;

  timeZoneSet(zone: string | undefined) {
    let previous = this.selectedTimeZone;
    this.settingHttpService.SetTimezone(zone ?? "").subscribe(x => {
      this.selectedTimeZone = x.value == "" ? undefined : x.value;
      this.globalAlertService.createAlertBannerModel("Wert gespeichert", "Die neue Zeitzone wurde erfolgreich gesetzt.", AlertLevel.success, 2000);
      this.globalAlertService.show()
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Zeitzone konnte nicht gesetzt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show()
      this.selectedTimeZone = previous;
    })
  }

  loadTimeZone() {
    this.settingHttpService.GetTimezone().subscribe(x => {
      this.selectedTimeZone = x.value == "" ? undefined : x.value;
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Zeitzone konnte nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  loadLogoImage() {

  }

  ngOnInit(): void {
    this.LoadData();
    this.loadTimeZone();
  }
  
  protected readonly state = state;
}
