import {enableProdMode, Inject} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieHelper} from "./app/helpers/CookieHelper";
import {registerLicense} from "@syncfusion/ej2-base";
import {Browser} from "leaflet";
import win = Browser.win;

// sentry
import * as Sentry from "@sentry/angular";

export enum EnivronmentTypes {
  Development = "development",
  Production = "production"
}

if (environment.production) {
  Sentry.init({
    dsn: "https://d241c7449ff8205c5f21833da9839cc1@o4507808737198080.ingest.de.sentry.io/4507814820773968",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\w*\.cheqme\.app/, /^api\.\w*\.cheqme\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0, // Capture 100% of the profiles
    environment: environment.production ? EnivronmentTypes.Production : EnivronmentTypes.Development
  });
}

let url: string = "";

registerLicense("Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiWH1fcHZRRGFbWEZxXQ==");

export function getBaseUrl() {
  if (environment.production && !window.location.hostname.includes('localhost')) {
    return `https://api.${window.location.hostname}`
  } else {
    return "http://localhost:5141";
  }
  //return "https://n5cm.api.nymus.app";

  //return document.getElementsByTagName('base')[0].href;
}

export function getHeaders() {
  return new HttpHeaders().set('Authorization', 'Bearer ' + CookieHelper.getCookie("JWT"));
}

const providers = [
  {provide: 'BASE_URL', useFactory: getBaseUrl, deps: []},
  {provide: 'BASE_HEADERS', useFactory: getHeaders, deps: []}
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
