<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Tags</h1>
    </div>
  </div>
  <div *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Tag_Add())" class="pt-4">
    <label for="NewTagName" class="block text-sm font-medium leading-6 text-gray-900">Neuer Tag</label>
    <form class="mt-2 flex rounded-md shadow-sm">
      <div class="relative flex flex-grow items-stretch focus-within:z-10">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks"
               viewBox="0 0 16 16">
            <path
              d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
            <path
              d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
          </svg>
        </div>
        <input type="text" name="NewTagName" id="NewTagName" [(ngModel)]="newTagName"
               class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
               placeholder="Name">
        <input type="color" id="NewTagColor" class="absolute rounded-0 inset-y-0 right-0 flex items-center h-full"/>
      </div>
      <button (click)="AddTag()" type="submit"
              class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
             viewBox="0 0 16 16">
          <path
            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
        </svg>
        Hinzufügen
      </button>
    </form>
    <div class="flex pt-2">
      <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
      <button [disabled]="newTagDisabledFlagDisabled" (click)="newTagDisabledFlag = !newTagDisabledFlag"
              [class.!bg-theme-primary-700]="newTagDisabledFlag" type="button"
              class="disabled:opacity-25 bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2"
              role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span aria-hidden="true" [class.!translate-x-5]="newTagDisabledFlag"
              class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
      </button>
      <span [class.opacity-25]="newTagDisabledFlagDisabled" class="ml-3 text-sm" id="DisabledToggle">
        <span class="font-medium text-gray-900">Deaktiviert</span>
      </span>
    </div>
    <div class="flex py-2">
      <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
      <button [disabled]="newTagOthersFlagDisabled" (click)="SetOtherTag(!this.newTagOthersFlag)"
              [class.!bg-theme-primary-700]="newTagOthersFlag" type="button"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2"
              role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span aria-hidden="true" [class.!translate-x-5]="newTagOthersFlag"
              class="disabled:opacity-25 translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
      </button>
      <span [class.opacity-25]="newTagOthersFlagDisabled" class="ml-3 text-sm" id="OthersToggle">
        <span class="font-medium text-gray-900">Sonstiges</span>
      </span>
    </div>
    <div class="flex">
      <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
      <button [disabled]="newTagAllCompositionsFlagDisabled"
              (click)="SetAllCompositionsTag(!this.newTagAllCompositionsFlag)"
              [class.!bg-theme-primary-700]="newTagAllCompositionsFlag" type="button"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2"
              role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span aria-hidden="true" [class.!translate-x-5]="newTagAllCompositionsFlag"
              class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
      </button>
      <span [class.opacity-25]="newTagAllCompositionsFlagDisabled" class="ml-3 text-sm" id="AllCompositionsToggle">
        <span class="font-medium text-gray-900">Alles inkludieren</span>
      </span>
    </div>
  </div>
  <div class="mt-8 sm:-mx-0 w-full">
    <app-loading *ngIf="tags == undefined"></app-loading>
    <input #search (input)="FilterTag(search.value)" type="text" name="search" id="search"
           class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 my-4"
           placeholder="Tag suchen...">
    <div class="w-full overflow-x-auto flex">
      <table class="w-full divide-y divide-gray-300"
             *ngIf="filtered_tags && filtered_tags.length > 0">
        <thead>
        <tr>
          <th (click)="SortBy('name')" scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
            <div class="inline-flex">
              <div class="grid grid-cols-1 gap-1">
                <svg [class.opacity-100]="this.field == 'name' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                <svg [class.opacity-100]="this.field == 'name' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
              <a
                class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Name</a>
            </div>
          </th>
          <th (click)="SortBy('order')" scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
            <div class="inline-flex">
              <div class="grid grid-cols-1 gap-1">
                <svg [class.opacity-100]="this.field == 'order' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                <svg [class.opacity-100]="this.field == 'order' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
              <a
                class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">R</a>
            </div>
          </th>
          <th (click)="SortBy('created')" scope="col"
              class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            <div class="inline-flex">
              <div class="grid grid-cols-1 gap-1">
                <svg [class.opacity-100]="this.field == 'created' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                <svg [class.opacity-100]="this.field == 'created' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="16" fill="currentColor"
                     class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
              <a
                class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900">Erstellt
                am</a>
            </div>
          </th>
          <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Flag /
            ID
          </th>
          <th *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_TagSetFilter())" scope="col"
              class="px-3 py-3.5 text-left font-semibold text-gray-900 text-sm">Filter
          </th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 text-lg">
            <span class="sr-only">Edit</span>
          </th>
        </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
        <tr *ngFor="let tag of filtered_tags; let index = index; trackBy:TrackByIndex;">
          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
            <a
              [ngClass]="{'pointer-events-none' : !PermissionService.CheckPermission(PermissionService.CombinedPermission_TagRedirectComposition()) || tag.allCompositions }"
              [routerLink]="['/compositions']"
              [queryParams]="tag.other ? {other: tag.other, tag: tag.id }:{  other: tag.other, tag: tag.id }"
              [style.color]="'#' + tag.color">
            <span
              class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium border-2 border-gray-900 max-w-full"
              style="{{'border-color: #' + tag.color}}">
              <svg class="-ml-1 mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8"
                   style="{{'border-color: #' + tag.color}}">
                <circle cx="4" cy="4" r="3"/>
              </svg>
              <span href="" class="d-inline-flex md:text-lg truncate"
                    style="{{'color: #' + tag.color}}">{{ tag.name }}</span>
            </span>
            </a>
          </td>
          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
            <span>{{ tag.order }}</span>
          </td>
          <td
            class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ (tag.created) | date : "dd.MM.YYYY, HH:mm" }}
            Uhr
          </td>
          <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
            <span *ngIf="tag.disabled" class="fw-bold text-red-400">D&nbsp;</span>
            <span *ngIf="tag.other" class="fw-bold text-yellow-600">S&nbsp;</span>
            <span *ngIf="tag.allCompositions" class="fw-bold text-blue-600">A&nbsp;</span>
            {{ tag.id }}
          </td>
          <td *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_TagSetFilter())"
              class="whitespace-nowrap px-3 text-sm text-gray-500">
            <div>
              <select [(ngModel)]="filtered_tags[index].showFilter" (ngModelChange)="onFilterChange(tag, $event)"
                      id="filter" name="filter"
                      class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
                <option value="none" [selected]="tag.filter == undefined">-- KEINER --</option>
                <option *ngFor="let category of categories" [value]="category.id"
                        [selected]="tag.filter != undefined && category.id == tag.filter.id">{{ category.name }}
                </option>
              </select>
            </div>
          </td>
          <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 sm:pl-0">
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Tag_Get())"
               (click)="redirectToSettings(tag.id)"
               class="select-none text-gray-600 hover:text-gray-900 cursor-pointer p-1  inline-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Tag_Delete())"
               (click)="RemoveTag(tag)"
               class="select-none text-red-600 hover:text-red-900 cursor-pointer p-1 inline-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
            </a>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-error-banner [(error)]="error"></app-error-banner>

