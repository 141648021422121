import {Component, Input} from '@angular/core';
import {Routes} from "../../../../enums/routes";
import {SettingsInputElementModel} from "../../../../models/settings-input-element-model";
import {EmployeeDto} from "../../x-models/employee-dto";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {EmployeeHttpService} from "../../x-http-requests/employee-http.service";
import {PermissionService} from "../../../../_auth/permission.service";
import {SettingsPageComponent} from "../../../../x-components/settings/settings-page/settings-page.component";
import {AlertLevel} from "../../../../enums/alert-level";
import {HttpPropertyChangeStatus} from "../../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../../models/observable-queue-element";
import {SocialTypes} from "../../../../x-models/x-enums/social-types";
import {PermissionSet} from "../../../../_auth/Models/PermissionSet";
import {EventDto} from "../../../../models/event/event-dto";


@Component({
  selector: 'app-employee-settings',
  templateUrl: "../../../../x-components/settings/settings-page/settings-page.component.html",
  styleUrls: ['./employee-settings.component.scss']
})
export class EmployeeSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.CompanyEmployee
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newUserFirstname: new SettingsInputElementModel(),
    newUserLastname: new SettingsInputElementModel(),
    newUserAdditionalInformation: new SettingsInputElementModel(),
    separator: new SettingsInputElementModel(),
    newUserTelegram: new SettingsInputElementModel(),
    resetUserTelegram: new SettingsInputElementModel()
  };

  public model?: EmployeeDto;
  public timeoutClick: boolean = false;

  constructor(route: ActivatedRoute,
              public PermissionService: PermissionService,
              public employeeHttpService: EmployeeHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newUserFirstname.propertyTitle = "Name";
    this.settingsInputElementModel.newUserTelegram.propertyTitle = "Telegram Benutzername"
    this.settingsInputElementModel.resetUserTelegram.propertyTitle = "Telegram zurücksetzen"
    this.settingsInputElementModel.resetUserTelegram.inputType = "button"

    this.settingsInputElementModel.newUserFirstname.requiredPermission = PermissionService.Company_Employee_ChangeName();
    this.settingsInputElementModel.newUserLastname.requiredPermission = PermissionService.Company_Employee_ChangeName();
    this.settingsInputElementModel.newUserTelegram.requiredPermission = PermissionService.Company_Employee_SetSocial();
    this.settingsInputElementModel.resetUserTelegram.requiredPermission = PermissionService.Company_Employee_ResetSocial();

    this.settingsInputElementModel.newUserAdditionalInformation.propertyTitle = "Hinweise";
    this.settingsInputElementModel.newUserAdditionalInformation.requiredPermission = PermissionService.Company_Employee_SetPublicProperty();

    this.settingsInputElementModel.separator.inputType = "separator";
    this.settingsInputElementModel.separator.propertyTitle = "Telegram"
    this.settingsInputElementModel.separator.requiredPermission = PermissionService.CombinedPermission_EmployeeTelegram();

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Company_Employee_Delete());
  }

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.employeeHttpService.delete(this.model.id).subscribe(() => {
        this.globalAlertService.createAlertBannerModel("Mitarbeiter entfernt", "Mitarbeiter wurde erfolgreich gelöscht", AlertLevel.success, 5000)
        this.globalAlertService.show();
      }
    )
  }

  @Input() loadInputModel = (id: string) => {
    this.employeeHttpService.get(id).subscribe((employee: EmployeeDto) => {
        this.model = employee;
        this.settingsInputElementModel.newUserFirstname.originalPropertyValue = this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue = employee.firstName;
        this.settingsInputElementModel.newUserLastname.originalPropertyValue = this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue = employee.lastName;
        this.settingsInputElementModel.newUserTelegram.originalPropertyValue = this.settingsInputElementModel.newUserTelegram.changeableProperties.newPropertyValue = employee.telegram?.username;
        this.settingsInputElementModel.newUserAdditionalInformation.originalPropertyValue = this.settingsInputElementModel.newUserAdditionalInformation.changeableProperties.newPropertyValue = employee.additionalInformation;

        this.updateElements();
      }
    )
  }

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName());
    this.requiresChanges.push(this.saveSocial("Telegram", this.settingsInputElementModel.newUserTelegram))
    this.requiresChanges.push(this.resetSocial("Telegram", this.settingsInputElementModel.resetUserTelegram))
    this.requiresChanges.push(this.savePublicProperty("AdditionalInformation", this.settingsInputElementModel.newUserAdditionalInformation));

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper()
  }

  savePublicProperty(name: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employeeHttpService.setPublicProperty(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmployeeDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Eigenschaft.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;

    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  resetSocial(name: string, cModel: any): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employeeHttpService.resetSocial(this.model.id, name);
    observableQueueElement.callback = (model: EmployeeDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue = ""
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Zurücksetzen der " + name + " Daten.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveSocial(name: string, cModel: any): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employeeHttpService.setSocial(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmployeeDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue = this.model?.telegram?.username
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des " + name + " Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (!this.FirstNameValidAndChanged() && !this.LastNameValidAndChanged()) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.employeeHttpService.changeName(this.model.id, this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue, this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (employee: EmployeeDto) => {
      this.model = employee;
      this.settingsInputElementModel.newUserFirstname.originalPropertyValue = this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue = this.model?.firstName;
      this.settingsInputElementModel.newUserLastname.originalPropertyValue = this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue = this.model?.lastName;

      this.settingsInputElementModel.newUserFirstname.changeableProperties.changeValue = false;
      this.settingsInputElementModel.newUserLastname.changeableProperties.changeValue = false;

      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = () => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);
    return HttpPropertyChangeStatus.Changed;
  }

  FirstNameValidAndChanged(): boolean {
    if (this.model === undefined) return false;
    if (!this.settingsInputElementModel.newUserFirstname.changeableProperties.changeValue) return false;
    if (this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue === undefined) return false;
    if (this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue === "") return false;
    if (this.settingsInputElementModel.newUserFirstname.changeableProperties.newPropertyValue === this.settingsInputElementModel.newUserFirstname.originalPropertyValue) return false;
    return true;
  }

  LastNameValidAndChanged(): boolean {
    if (this.model === undefined) return false;
    if (!this.settingsInputElementModel.newUserLastname.changeableProperties.changeValue) return false;
    if (this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue === undefined) return false;
    if (this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue === "") return false;
    if (this.settingsInputElementModel.newUserLastname.changeableProperties.newPropertyValue === this.settingsInputElementModel.newUserLastname.originalPropertyValue) return false;
    return true;
  }
}
