import {BookingType} from "./booking-type";

export class PaymentCounter {
  constructor(payment: BookingType) {
    this.bookingType = payment;
  }

  bookingType: BookingType;
  amount: number = 0;
  total: number = 0;
}
