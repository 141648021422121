<div class="px-4 sm:px-6 lg:px-8" *ngIf="compositionType || compositionTypeId == ''">
  <div class="sm:flex sm:items-center pb-4">
    <div class="inline-flex w-full">
      <h1
        class="font-semibold leading-6 text-gray-900 !text-3xl place-self-center">{{ !compositionType ? (!other ? 'Artikel' + (tagName == "" && itemName == "" ? " o. Zuordnung" : "") : 'Sonstiges') : (compositionType.name) }}</h1>
      <span *ngIf="compositions && itemName != ''"
            class="inline-flex place-self-end pl-2 text-lg">mit {{ itemName }}
        <a (click)="SetItem()" class="place-self-center pl-2 text-red-400 hover:text-red-600 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill"
               viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
        </a>
      </span>
      <span *ngIf="compositions && tagName != ''"
            class="inline-flex place-self-end pl-2 text-lg">Tag: {{ tagName }}
        <a (click)="SetTag()" class="place-self-center pl-2 text-red-400 hover:text-red-600 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill"
               viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
        </a>
      </span>
      <div class="flex-grow"></div>
      <div *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Download())"
           class="flex-shrink place-self-center text-gray-400 hover:text-gray-800 cursor-pointer select-none"
           (click)="!downloading ? download() : null">
        <svg *ngIf="!downloading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
             class="bi bi-box-arrow-down" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"/>
          <path fill-rule="evenodd"
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
        </svg>
        <svg *ngIf="downloading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
             class="animate-spin bi bi-arrow-repeat" viewBox="0 0 16 16">
          <path
            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
          <path fill-rule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
        </svg>
      </div>
    </div>
  </div>
  <input #search [ngModel]="searchText" (input)="FilterItem(search.value)" type="text" name="search" id="search"
         class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 mb-4"
         placeholder="Suchen...">
  <div class="place-self-center inline-flex w-full pb-4"
       *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_CompositionSetCompositionType())">
    <span class="isolate inline-flex rounded-md shadow-sm" *ngIf="!selection_mode">
      <button type="button"
              class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              [class.!bg-gray-200]="viewMode == ViewType.GridView" (click)="viewMode = ViewType.GridView">
        <span class="sr-only">Grid</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid"
             viewBox="0 0 16 16">
          <path
            d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
        </svg>
      </button>
      <button type="button"
              class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              [class.!bg-gray-200]="viewMode == ViewType.ListView" (click)="viewMode = ViewType.ListView">
        <span class="sr-only">List</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
      </button>
    </span>
    <a (click)="selection_mode = true" *ngIf="!selection_mode && viewMode == ViewType.GridView"
       class="ml-auto select-none text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Bearbeiten</a>
    <div *ngIf="selection_mode && viewMode == ViewType.GridView" class="ml-auto">
      <a (click)="ShowCompositionTypeSelector()"
         class="select-none pr-2 mr-2 border-r-2 border-gray-400 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
         [ngClass]="{'!text-gray-400 pointer-default hover:!text-gray-400 pointer-events-none': selected_items.length == 0}">Kategorie
        zuweisen</a>
      <a (click)="selection_mode = false; selected_items = []"
         class="select-none text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Fertig</a>
    </div>
  </div>
  <app-loading *ngIf="compositions == undefined"></app-loading>
  <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3" *ngIf="viewMode == ViewType.GridView">
    <li *ngFor="let composition of filtered_compositions; let index = index; trackBy: TrackByIndex;"
        [id]="composition.id"
        class="col-span-1 rounded-lg bg-white shadow grid relative"
        [ngClass]="{'cursor-pointer': selection_mode, '!bg-gray-400': selection_mode && IsInList(composition)}"
        (click)="ToggleInSelection(composition)">
      <div *ngIf="composition.special" class="absolute top-0 bottom-0 left-0 w-2 bg-red-500 rounded-l-lg"></div>
      <div *ngIf="CompositionOther(composition)"
           class="absolute top-0 bottom-0 left-0 w-2 bg-cyan-500 rounded-l-lg"></div>
      <div class="flex w-full items-center justify-between space-x-6 p-6 pb-1 pt-3 relative overflow-hidden">
        <div class="flex-1 w-full">
          <div class="space-x-3 pr-16">
            <h3 class="text-lg font-medium text-gray-900 text-ellipsis overflow-hidden whitespace-nowrap">
              {{ composition.name }}
            </h3>

            <!--
            <app-editable-header #NameChangedCustomInput ClassList="truncate text-lg font-medium text-gray-900" Text="{{composition.name}}" (TextChanged)="UpdateCompositionName(composition.id,$event)" ></app-editable-header>
            -->
          </div>
          <p *ngFor="let component of composition.components" class="mt-1 truncate text-sm text-gray-500">
            {{ component.value | number: "1.0-0" }}
            <span class="-ml-1"
                  *ngIf="!component.unit">&nbsp;{{ UnitConverter.GetString(component.itemUnit) }}&nbsp;</span>
            <span class="-ml-1" *ngIf="component.unit">&nbsp;Einheit{{ component.value > 1 ? "en" : "" }}&nbsp;</span>
            <span class="text-black self-start">{{ component.itemName }}</span>
          </p>
        </div>
        <div class="absolute right-0 inline-flex top-0 p-4 ">
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Get())"
             class="text-gray-900 hover:text-gray-400 cursor-pointer mr-2"
             (click)="redirectToCompositionComponentChange(composition.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-boxes"
                 viewBox="0 0 16 16">
              <path
                d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434zM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567zM7.5 9.933l-2.75 1.571v3.134l2.75-1.571zm1 3.134 2.75 1.571v-3.134L8.5 9.933zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567zm2.242-2.433V3.504L8.5 5.076V8.21zM7.5 8.21V5.076L4.75 3.504v3.134zM5.258 2.643 8 4.21l2.742-1.567L8 1.076zM15 9.933l-2.75 1.571v3.134L15 13.067zM3.75 14.638v-3.134L1 9.933v3.134z"/>
            </svg>

          </a>

          <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Get())"
             class="text-gray-900 hover:text-gray-400 cursor-pointer mr-2" (click)="redirectToSettings(composition.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
            </svg>
          </a>

          <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Delete())"
             (click)="DeleteComposition(composition)" class="text-red-600 hover:text-red-400 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>
        </div>


      </div>
      <div class="self-end divide-y">
        <div class="m-2 mx-4" [class.!text-red-600]="composition.special">
          <span class="text-lg">Preis:
            <span class="fw-bold ml-2 hover:text-theme-primary-700 cursor-pointer"
                  [ngClass]="{'!pointer-events-none' : !PermissionService.CheckPermission(PermissionService.Inventory_Composition_PriceChange())}"
                  (click)="ChangePrice(composition)">
              {{ composition.price | currency:'EUR' }}
            </span>
            <span class="text-xs" *ngIf="compositionType">
              (inkl.&nbsp;{{ (composition.price - composition.price / (1 + VATRateHelper.GetValue(compositionType.vatRate))) | currency: "EUR" }}&nbsp;MwSt.)
            </span>
          </span>
        </div>
        <div
          [ngClass]="{'pointer-events-none' : !PermissionService.CheckPermission(PermissionService.Inventory_Composition_ToggleTag())}"
          class="-mt-px flex flex-wrap divide-gray-200 m-2 mx-3">
          <div *ngFor="let tag of filtered_tags" (click)="ToggleTag(composition, tag)" class="m-1 cursor-pointer mt-2">
            <span *ngIf="GetTagArray(composition).indexOf(tag.id) != -1"
                  class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium border-2 border-gray-900 max-w-full"
                  style="{{'border-color: #' + tag.color + '; color: #' + tag.color}}">
              <span href="" class="d-inline-flex text-lg truncate"
                    style="{{'color: #' + tag.color}}" *ngIf="tag.other">{{ tag.name }}</span>
              <svg class="h-2 w-2" [ngClass]="{'-ml-1 mr-1.5': !tag.other, '-mr-1 ml-1.5': tag.other}"
                   fill="currentColor" viewBox="0 0 8 8"
                   style="{{'border-color: #' + tag.color}}">
                <circle cx="4" cy="4" r="3"/>
              </svg>
              <span href="" class="d-inline-flex text-lg truncate"
                    style="{{'color: #' + tag.color}}" *ngIf="!tag.other">{{ tag.name }}</span>
            </span>
            <span *ngIf="GetTagArray(composition).indexOf(tag.id) == -1"
                  class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium border-2 text-gray-200 border-gray-200 max-w-full">
              <span href="" class="d-inline-flex text-lg truncate" *ngIf="tag.other">{{ tag.name }}</span>
              <svg class="h-2 w-2" [ngClass]="{'-ml-1 mr-1.5': !tag.other, '-mr-1 ml-1.5': tag.other}"
                   fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3"/>
              </svg>
              <span href="" class="d-inline-flex text-lg truncate" *ngIf="!tag.other">{{ tag.name }}</span>
            </span>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <div class="-mx-4 mt-8 sm:-mx-0 overflow-x-auto" *ngIf="viewMode == ViewType.ListView">
    <table class="min-w-full divide-y divide-gray-300"
           *ngIf="filtered_compositions && filtered_compositions.length > 0">
      <thead>
      <tr>
        <th (click)="SortBy('name')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'name' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'name' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Name</a>
          </div>
        </th>
        <th (click)="SortBy('order')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'order' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'order' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">R</a>
          </div>
        </th>
        <th (click)="SortBy('created')" scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'created' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'created' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Erstellt
              am</a>
          </div>
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell text-sm">
          ID
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 text-lg">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let composition of filtered_compositions; let index = index;trackBy:TrackByIndex;">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 flex flex-col">
          <span>{{ composition.name }}</span>
          <span *ngIf="composition.publicName" class="text-gray-400">{{ composition.publicName }}</span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span>{{ composition.order }}</span>
        </td>
        <td
          class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ composition.created | date : "dd.MM.YYYY, HH:mm" }}
          Uhr
        </td>
        <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
          <div class="inline-flex">
            <span class="place-self-center">
              {{ composition.id }}
            </span>
          </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <div class="flex gap-2">
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Get())"
               (click)="redirectToSettings(composition.id)"
               class="select-none text-gray-600 hover:text-gray-900 cursor-pointer inline-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Composition_Delete())"
               (click)="DeleteComposition(composition)"
               class="select-none text-red-600 hover:text-red-900 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <app-error-banner [(error)]="error"></app-error-banner>
</div>

<div *ngIf="composition_type_selector_visible" class="relative z-10" aria-labelledby="modal-title" role="dialog"
     aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto lg:pl-72">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
            </svg>
          </div>
          <div class="mt-3 sm:ml-4 sm:mt-0 place-self-center grow">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Artikel-Kategorie ändern</h3>
            <div class="mt-2 flex flex-col gap-2 cursor-pointer">
              <div class="bg-white border-gray-200 border-2 rounded-md p-2 flex flex-col ring-theme-primary-700"
                   [ngClass]="{'ring-2 ring-offset-1': selected_composition_type == undefined}"
                   (click)="SetCompositionType(undefined)">
                <span>Ohne Zuordnung</span>
              </div>
              <div *ngFor="let compositionType of CompositionTypeService.CompositionTypes"
                   class="bg-white border-gray-200 border-2 rounded-md p-2 flex flex-col ring-theme-primary-700"
                   [ngClass]="{'ring-2 ring-offset-1': compositionType == selected_composition_type}"
                   (click)="SetCompositionType(compositionType)">
                <span>{{ compositionType.name }}</span>
                <span class="text-gray-400 text-sm">{{ compositionType.id }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button (click)="SaveCompositionType()" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 sm:ml-3 sm:w-auto">
            Speichern
          </button>
          <button (click)="HideCompositionTypeSelector()" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

