import {LocationNl} from "../location-nl";

export class LocationCounter {
  constructor(location: LocationNl) {
    this.location = location;
  }
  location: LocationNl;
  total: number = 0;
  sales: number = 0;
  drinks: number = 0;
}
