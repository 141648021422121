<app-navbar-office *ngIf="authenticationService.userValue?.jwt && !isEmployee"></app-navbar-office>
<body class="bg-transparent">

  @if (authenticationService.userValue?.jwt && !isEmployee) {
    <main>
      <div class="fixed top-12 bottom-0 inset-x-0 transition-[top] py-8 px-2 lg:!p-8 overflow-y-auto" #relative
           [ngClass]="{'!top-36': NavbarService.hasExpanded()}"> <!-- px-4 sm:px-6 lg:px-8 -->
        <app-alert-banner class=""></app-alert-banner>
        <router-outlet></router-outlet>
        <app-fullscreen-loader [show]="LoadingIndicatorService.loading"></app-fullscreen-loader>
      </div>
    </main>
  } @else if (authenticationService.userValue?.jwt && isEmployee) {
    <main class="absolute pt-10 lg:pt-20 inset-0 overflow-y-auto !px-4 lg:!px-8" #relative>
      <app-alert-banner class=""></app-alert-banner>
      <router-outlet></router-outlet>
    </main>
  } @else {
    <main #relative>
      <app-alert-banner class=""></app-alert-banner>
      <router-outlet></router-outlet>
    </main>
  }
<app-info-message *ngIf="keyboardService.isOptionPressed"
                  message="Links werden in neuem Tab geöffnet"></app-info-message>
<app-cookie-settings></app-cookie-settings>
<!--<app-chaport-chat></app-chaport-chat>-->
<app-help-integration
  *ngIf="authenticationService.userValue && !authenticationService.isEmployee()"></app-help-integration>
<!--<app-jira-popup></app-jira-popup>-->
</body>
