import {Component, OnInit} from '@angular/core';
import {LocationHttpService} from "../http-requests/location-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationDto} from "../models/location-dto";
import { HttpErrorResponse } from "@angular/common/http";
import {ConfigurationDto} from "../models/configuration-dto";
import {ConfigurationHttpService} from "../http-requests/configuration-http.service";
import {PermissionService} from "../_auth/permission.service";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";
import {Routes} from "../enums/routes";
import {SignalRResponse} from "../_modules/signalRTracker/x-models/signal-r-response";
import {Device} from "../_modules/signalRTracker/x-models/device";
import {DeviceResponseLinker} from "../_modules/signalRTracker/x-models/device-response-linker";

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  filtered_locations?: LocationDto[];
  locations?: LocationDto[];
  configurations?: ConfigurationDto[];
  newLocationName: string = "";
  searchText: string = "";
  configuration: string = "";
  loadingLocations: string [] = [];

  field: string = "";
  inverse: boolean = false;
  error?: HttpErrorResponse;

  constructor(private locationHttpService: LocationHttpService,
              private configurationHttpService: ConfigurationHttpService,
              private router: Router,
              private route: ActivatedRoute,
              public PermissionService: PermissionService,
              private globalAlertService: GlobalAlertService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  AssignCopy() {
    this.filtered_locations = Object.assign([], this.locations);
  }

  FilterLocation(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    }
    this.filtered_locations = Object.assign([], this.locations?.filter(location =>
      location.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    ))
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  AddLocation(): void {
    this.locationHttpService.add(this.newLocationName).subscribe(x => {
      if (this.locations == undefined) this.locations = [];
      x.showConfig = "none";
      this.locations.splice(0, 0, x);
      this.newLocationName = "";
      this.FilterLocation(this.searchText);
      this.globalAlertService.createAlertBannerModel("Erfolgreich hinzugefügt", `Der Verkaufspunkt ${x.name} wurde erfolgreich hinzugefügt`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Hinzufügen des Verkaufspunkts ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  RemoveLocation(location: LocationDto): void {
    let confirmation = confirm("Löschen des folgenden Verkaufspunkts bestätigen:\n" + location.name + "\n" + location.id);
    if (!confirmation) return;
    this.locationHttpService.delete(location.id).subscribe(x => {
      this.locations!.splice(this.locations!.indexOf(location), 1);
      this.FilterLocation(this.searchText);
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `Der Verkaufspunkt ${location.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Der Verkaufspunkt ${location.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SortBy(field: string) {
    if (this.filtered_locations == undefined) this.filtered_locations = [];
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "order":
        this.filtered_locations.sort((a, b) => {
          if (a.order - b.order != 0) return a.order - b.order;

          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
        break;
      case "name":
        this.filtered_locations.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        break;
      case "created":
        this.filtered_locations.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
      case "configuration":
        this.filtered_locations.sort((a, b) => {
          if (a.configuration.name.toLowerCase() > b.configuration.name.toLowerCase()) return 1;
          if (a.configuration.name.toLowerCase() < b.configuration.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
        break;
    }
    if (this.inverse) this.filtered_locations.reverse();
  }

  LoadData(): void {
    // get configurations for setting the location config
    if (this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_LocationReadConfiguration())) {
      this.configurationHttpService.list().subscribe(result => {
        this.configurations = result;
      }, error => console.error(error));
    }

    // get locations for list view
    this.locationHttpService.list().subscribe(result => {
      this.locations = result;
      this.locations.forEach(loc => {
        if (loc.configuration == undefined) loc.showConfig = "none";
        else loc.showConfig = loc.configuration.id;
      })
      this.AssignCopy();
    }, error => console.error(error));
  }

  SetLoading(id: string, state: boolean) {
    let index = this.loadingLocations.indexOf(id);
    if (state) {
      if (index == -1) this.loadingLocations.push(id);
    } else {
      if (index > -1) this.loadingLocations.splice(index, 1);
    }
  }

  onConfigurationChange(location: LocationDto, configuration: string) {
    this.SetLoading(location.id, true);
    this.locationHttpService.setConfig(location.id, configuration).subscribe(x => {
      this.SetLoading(location.id, false);
      let errorDevices: DeviceResponseLinker[] = x.item2 == undefined ? [] : x.item2.filter(x => !x.response.success);
      let successDevices: DeviceResponseLinker[] = x.item2 == undefined ? [] : x.item2.filter(x => x.response.success);
      this.globalAlertService.createAlertBannerModel("Übertragung des Preisprofils", `Die Konfiguration wurde auf ${successDevices.length} Gerät${successDevices.length == 1 ? '' : 'en'} erfolgreich geändert, ${errorDevices.length} Gerät${errorDevices.length == 1 ? ' ist' : 'e sind'} fehlgeschlagen${errorDevices.length > 0 ? ":" : "."} ${errorDevices.map(x => " " + x.device.name)}`, errorDevices.length > 0 ? AlertLevel.error : AlertLevel.success, 3000);
      this.globalAlertService.show();
    }, error => {
      this.SetLoading(location.id, false);
      if (this.locations == undefined) return;
      this.locations[this.locations!.indexOf(location)!].showConfig =
        this.locations[this.locations!.indexOf(location)!].configuration != undefined ? this.locations[this.locations!.indexOf(location)!].configuration.id : "none";
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Verarbeiten der Anfrage ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.LocationSettings], {queryParams: {id: id}});
  }

  pushConfiguration(location: LocationDto) {
    this.SetLoading(location.id, true);
    this.locationHttpService.pushLocation(location.id).subscribe(x => {
      this.SetLoading(location.id, false);
      let errorDevices: DeviceResponseLinker[] = x.filter(x => !x.response.success);
      let successDevices: DeviceResponseLinker[] = x.filter(x => x.response.success);
      this.globalAlertService.createAlertBannerModel("Übertragung des Preisprofils", `Die Konfiguration wurde auf ${successDevices.length} Gerät${successDevices.length == 1 ? '' : 'en'} erfolgreich geändert, ${errorDevices.length} Gerät${errorDevices.length == 1 ? ' ist' : 'e sind'} fehlgeschlagen${errorDevices.length > 0 ? ":" : "."} ${errorDevices.map(x => " " + x.device.name)}`, errorDevices.length > 0 ? AlertLevel.error : AlertLevel.success, 3000);
      this.globalAlertService.show();
    }, error => {
      this.SetLoading(location.id, false);
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Verarbeiten der Anfrage ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.configuration = params.configuration == undefined ? "" : params.configuration;
    });
    this.LoadData();
  }
}
