import {Component, Input} from '@angular/core';
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../enums/routes";
import {ActivatedRoute, Router} from "@angular/router";
import {CompositionHttpService} from "../../http-requests/composition-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {PermissionService} from "../../_auth/permission.service";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../models/observable-queue-element";
import {AlertLevel} from "../../enums/alert-level";
import {LocationDto} from "../../models/location-dto";
import {LocationHttpService} from "../../http-requests/location-http.service";

@Component({
  selector: 'app-location-settings',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./location-settings.component.scss']
})
export class LocationSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.Locations;
  public settingsInputElementModel: { [key: string]: any } = {
    newLocationName: new SettingsInputElementModel(),
    newReceivesTip: new SettingsInputElementModel(),
    newOrderNumber: new SettingsInputElementModel(),
    newDefaultEmployeeCount: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public locationHttpService: LocationHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newLocationName.propertyTitle = "Name";
    this.settingsInputElementModel.newLocationName.requiredPermission = PermissionService.Inventory_Location_ChangeName()
    this.settingsInputElementModel.newLocationName.order = 1;

    this.settingsInputElementModel.newOrderNumber.propertyTitle = "Reihenfolge";
    this.settingsInputElementModel.newOrderNumber.requiredPermission = PermissionService.Inventory_Location_SetOrder()
    this.settingsInputElementModel.newOrderNumber.order = 2;
    this.settingsInputElementModel.newOrderNumber.inputType = "number";

    this.settingsInputElementModel.newReceivesTip.propertyTitle = "Anteil am TG";
    this.settingsInputElementModel.newReceivesTip.requiredPermission = PermissionService.Inventory_Location_SetTipShare()
    this.settingsInputElementModel.newReceivesTip.order = 3;
    this.settingsInputElementModel.newReceivesTip.inputType = "checkbox";

    this.settingsInputElementModel.newDefaultEmployeeCount.propertyTitle = "Anzahl Arbeitsplätze";
    this.settingsInputElementModel.newDefaultEmployeeCount.requiredPermission = PermissionService.Inventory_Location_SetDefaultEmployeeCount()
    this.settingsInputElementModel.newDefaultEmployeeCount.order = 4;
    this.settingsInputElementModel.newDefaultEmployeeCount.inputType = "number";

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Location_Delete());
  }

  public model?: LocationDto;
  public timeoutClick: boolean = false;


  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.locationHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Verkaufspunkt gelöscht", "Verkaufspunkt wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveReceivesTip())
    this.requiresChanges.push(this.saveOrder())
    this.requiresChanges.push(this.saveDefaultEmployeeCount())

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {
    this.locationHttpService.get(id).subscribe((model: LocationDto) => {
      this.model = model;
      this.settingsInputElementModel.newLocationName.originalPropertyValue = this.settingsInputElementModel.newLocationName.changeableProperties.newPropertyValue = model.name;
      this.settingsInputElementModel.newReceivesTip.originalPropertyValue = this.settingsInputElementModel.newReceivesTip.changeableProperties.newPropertyValue = model.receivesTips;
      this.settingsInputElementModel.newOrderNumber.originalPropertyValue = this.settingsInputElementModel.newOrderNumber.changeableProperties.newPropertyValue = model.order;
      this.settingsInputElementModel.newDefaultEmployeeCount.originalPropertyValue = this.settingsInputElementModel.newDefaultEmployeeCount.changeableProperties.newPropertyValue = model.defaultEmployeeCount;
    });
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newLocationName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newLocationName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newLocationName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.locationHttpService.changeName(this.model.id, this.settingsInputElementModel.newLocationName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: LocationDto) => {
      this.model = model;
      this.settingsInputElementModel.newLocationName.originalPropertyValue = this.settingsInputElementModel.newLocationName.changeableProperties.newPropertyValue = model.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveReceivesTip(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newReceivesTip.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newReceivesTip.changeableProperties.newPropertyValue === this.settingsInputElementModel.newReceivesTip.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.locationHttpService.setTipShare(this.model.id, this.settingsInputElementModel.newReceivesTip.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: LocationDto) => {
      this.model = model;
      this.settingsInputElementModel.newReceivesTip.originalPropertyValue = this.settingsInputElementModel.newReceivesTip.changeableProperties.newPropertyValue = model.receivesTips;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Beteiligung am Trinkgeld.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveOrder(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newOrderNumber.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newOrderNumber.changeableProperties.newPropertyValue === this.settingsInputElementModel.newOrderNumber.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.locationHttpService.setOrder(this.model.id, this.settingsInputElementModel.newOrderNumber.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: LocationDto) => {
      this.model = model;
      this.settingsInputElementModel.newOrderNumber.originalPropertyValue = this.settingsInputElementModel.newOrderNumber.changeableProperties.newPropertyValue = model.order;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Reihenfolge.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveDefaultEmployeeCount(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDefaultEmployeeCount.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDefaultEmployeeCount.changeableProperties.newPropertyValue === this.settingsInputElementModel.newDefaultEmployeeCount.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.locationHttpService.setDefaultEmployeeCount(this.model.id, this.settingsInputElementModel.newDefaultEmployeeCount.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: LocationDto) => {
      this.model = model;
      this.settingsInputElementModel.newDefaultEmployeeCount.originalPropertyValue = this.settingsInputElementModel.newDefaultEmployeeCount.changeableProperties.newPropertyValue = model.defaultEmployeeCount;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Mitarbeiteranzahl.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}
