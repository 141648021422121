import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {filter} from "rxjs";

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class ComboboxComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchElement:ElementRef | undefined;
  objects: any[] | undefined;
  @Input() set nyObjects(value: any[] | undefined) {
    this.objects = value;
    this.SetFirstActive();
  };
  FilteredObjects: any[] = [];
  @Input() displayProperty: string = "";
  @Input() idProperty: string = "";
  @Input() filter: boolean = true;
  selection: any | undefined;
  @Output() nySelection = new EventEmitter<any | undefined>();
  index: number | undefined;
  hover: number = 0;
  search: string = "";
  @Input() classes: string = "";

  constructor(private _eref: ElementRef) { }

  SetActive(object: any): void {
    this.index = this.objects?.indexOf(object);
    if (this.index == undefined || this.index == -1) {
      this.selection = undefined;
      this.nySelection.emit(undefined);
    }
    else {
      this.selection = object;
      this.nySelection.emit(this.selection)
    }
    this.search = this.selection[this.displayProperty]
    this.FilteredObjects = [];
    this.hover = 0;
  }

  AssignCopy(): void {
    this.FilteredObjects = this.objects ?? [];
  }

  Filter(): void {
    this.hover = 0;
    if (this.objects == undefined) return;
    if (this.search == "" || !this.filter) {
      this.AssignCopy()
      if (this.filter) this.index = undefined;
    } else {
      this.FilteredObjects = this.objects.filter(
        x => x[this.displayProperty].toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1
      )
      if (this.FilteredObjects.length > 0) this.index = 0;
    }
  }

  SetFirstActive() {
    if (!this.filter && this.objects && this.objects.length > 0) {
      this.SetActive(this.objects[0])
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.searchElement!.nativeElement!.addEventListener("click", (e: any) => {
      this.Filter()
    })
  }

  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.FilteredObjects = [];
      this.search = this.selection[this.displayProperty]
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.filter) return;
    if(event.key == 'ArrowDown'){
      // Your row selection code
      if (this.FilteredObjects.length-1 > this.hover) this.hover++;
    }
    else if(event.key == 'ArrowUp'){
      // Your row selection code
      if (this.hover > 0) this.hover--;
    }
    else if(event.key == 'Enter'){
      // Your row selection code
      event.preventDefault();
      if (this.hover >= 0 && this.FilteredObjects.length > this.hover) this.SetActive(this.FilteredObjects[this.hover])
    }
  }
}
