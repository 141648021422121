import {Component} from "@angular/core";
import {ComponentType} from "@angular/cdk/overlay";

export class NavbarItem {
  title: string = "Title";
  component: ComponentType<any>;
  available: boolean = false;
  isSelected: boolean = false;
  isExpanded: boolean = false;
  inNavBar: boolean = true;

  constructor(title: string, component: ComponentType<any>, permission: boolean, inNavBar: boolean = true) {
    this.title = title;
    this.component = component;
    this.available = permission;
    this.inNavBar = inNavBar;
  }

  showing() {
    return this.isSelected && this.isExpanded;
  }
}
