import {Component, OnInit} from '@angular/core';
import {EventHttpService} from "../management/x-http-requests/event-http.service";
import {EventDto} from "../models/event/event-dto";
import {HttpErrorResponse} from "@angular/common/http";
import {ItemHttpService} from "../http-requests/item-http.service";
import {LocationHttpService} from "../http-requests/location-http.service";
import {AuthenticationService} from "../_auth/authentication.service";
import {PermissionService} from "../_auth/permission.service";
import {DashboardStats} from "../models/stats/dashboard-stats";
import {GeneralHttpService} from "../http-requests/general-http.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  lastEvents?: EventDto[];
  currentOrNextEvent: EventDto | undefined;
  isCurrentEvent: boolean = false;

  dashboardStats: DashboardStats | undefined;

  constructor(private eventHttpService: EventHttpService,
              private itemHttpService: ItemHttpService,
              private locationHttpService: LocationHttpService,
              private authentication: AuthenticationService,
              public PermissionService: PermissionService,
              private generalHttpService: GeneralHttpService) {
  }

  Test() {
    this.eventHttpService.test().subscribe(x => {
    });
  }


  GetItemPermission(): boolean {
    const user = this.authentication.userValue;
    if (user == null) return false;
    return user.permissions.includes("Inventory.ItemController.List") || user.permissions.includes("Admin");
  }

  GetEventPermission(): boolean {
    const user = this.authentication.userValue;
    if (user == null) return false;
    return user.permissions.includes("Inventory.EventController.List") || user.permissions.includes("Admin");
  }

  GetEventDetailsPermission(): boolean {
    const user = this.authentication.userValue;
    if (user == null) return false;
    return user.permissions.includes("Inventory.EventController.Get") || user.permissions.includes("Admin");
  }

  GetLocationPermission(): boolean {
    const user = this.authentication.userValue;
    if (user == null) return false;
    return user.permissions.includes("Inventory.LocationController.List") || user.permissions.includes("Admin");
  }

  LoadData(): void {
    const user = this.authentication.userValue;
    if (user) {
      if (this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_HomeEventPermission())) {
        this.eventHttpService.list(0, 3, null, null, true).subscribe(x => {
          this.lastEvents = x;
        }, error => {
          console.error(error);
        })
        this.eventHttpService.getCurrentOrNext().subscribe(x => {
          this.currentOrNextEvent = x ? x.item1 : undefined;
          this.isCurrentEvent = x && x.item2 ? x.item2 : false;
        }, error => {
          console.error(error);
        })
      }
      if (this.PermissionService.CheckPermission(this.PermissionService.Inventory_General_GetDashboardStats())) {
        this.generalHttpService.getDashboardStats().subscribe(x => {
          this.dashboardStats = x;
        }, error => {
          console.error(error);
        })
      }

    }
  }

  ngOnInit(): void {
    this.LoadData();
  }
}
