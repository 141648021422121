import {Component, Inject, OnInit} from '@angular/core';
import {EventDto} from "../../models/event/event-dto";
import { HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import {EventHttpService} from "../x-http-requests/event-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OrderDto} from "../../models/order-dto";
import {CompositionCounter} from "../../models/order/composition-counter";
import {LocationCounter} from "../../models/location/location-counter";
import {AuthenticationService} from "../../_auth/authentication.service";
import * as signalR from '@microsoft/signalr';
import {LocationDto} from "../../models/location-dto";
import {EventEdto} from "../../models/event/event-edto";
import {OtherCounter} from "../../models/order/other-counter";
import {EventOdto} from "../../models/event/event-odto";
import {LocationHttpService} from "../x-http-requests/location-http.service";
import {N5PaymentMapper, BookingType} from "../../models/payment/booking-type";
import {PaymentCounter} from "../../models/payment/payment-counter";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class OwnerEventsComponent implements OnInit {
  events?: EventOdto[];
  filtered_events?: EventOdto[];
  event?: EventEdto;
  id: string = "";
  shard: string = "";
  details: boolean = false;
  location?: LocationDto;
  location_id: string = "";
  name: string = "";
  start: string = "";
  end: string = "";
  first: number = 0;
  last: number = 10;
  searchText: string = "";
  inverse: boolean = true;
  field: string = "date";
  error?: HttpErrorResponse;
  now: Date = new Date();
  downloading: boolean = false;
  readonly views: string[] = ["drinks", "location", "others"];
  view_index: number = 0;
  n5paymentMapper: N5PaymentMapper = new N5PaymentMapper();


  constructor(private eventHttpService: EventHttpService,
              private locationHttpService: LocationHttpService,
              private route: ActivatedRoute,
              private router: Router,
              @Inject("BASE_URL") private baseUrl: string,
              private authenticationService: AuthenticationService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let start = new Date();
    start.setMinutes(0, 0, 0);
    start.setTime(start.getTime() + (60 * 60 * 1000));
    this.start = this.toIsoString(start).slice(0, 16); // start.toISOString().slice(0,16);

    let end = new Date();
    end.setTime(start.getTime() + (12 * 60 * 60 * 1000));
    this.end = this.toIsoString(end).slice(0, 16); //end.toISOString().slice(0,16);
  }

  AssignCopy(): void {
    this.filtered_events = Object.assign([], this.events);
  }

  FilterItems(value: string): void {
    this.searchText = value;
    if (!value) this.AssignCopy();
    else {
      this.filtered_events = Object.assign([], this.events?.filter(event =>
        event.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ));
    }
  }

  LoadData(): void {
    this.eventHttpService.list().subscribe(x => {
      this.events = x;
      this.FilterItems(this.searchText);
    }, error => {
      console.error(error);
      this.error = error;
    })
  }

  toIsoString(date: Date): string {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  toIsoFromString(date: string): string {
    var tzo = -(new Date()).getTimezoneOffset(), dif = tzo >= 0 ? "+" : "-",
      pad = function (num: number) {
        return (num < 10 ? "0" : "") + num;
      };
    return date + dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" + pad(Math.abs(tzo) % 60);
  }

  CalculateTotal(order: OrderDto): number {
    let total = 0;
    order.compositions.forEach(composition => {
      if (composition.compositionOverride != undefined && composition.compositionOverride.price != null) total += composition.quantity * composition.compositionOverride.price;
      else total += composition.quantity * composition.composition.price;
    })
    return total;
  }

  SortBy(field: string) {
    if (this.event == undefined) return;
    if (this.event.orders == undefined) this.event.orders = []
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "date":
        this.event.orders.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
      case "price":
        this.event.orders.sort((a, b) => {
          if (a.total > b.total) return 1;
          if (a.total < b.total) return -1;
          return 0;
        })
    }
    if (this.inverse) this.event.orders.reverse();
  }

  CalculateStats(event: EventEdto) {
    event.compositions = [];
    event.others = [];
    event.locations = [];
    event.payments = [];

    let all = event.orders.filter(x => x.bookingType != BookingType.Employee).map(x => x.compositions).reduce((accumulator, value) => accumulator.concat(value), []);
    let others = all.filter(x => x.composition.tags.findIndex(c => c.other) != -1);
    let normal = all.filter(x => x.composition.tags.findIndex(c => c.other) == -1);

    event.total = all.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

    event.oAmount = others.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
    event.oTotal = others.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

    event.nAmount = normal.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
    event.nTotal = normal.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);


    event.orders.forEach(order => {
      let others = order.compositions.filter(x => x.composition.tags.findIndex(c => c.other) != -1);
      let normal = order.compositions.filter(x => x.composition.tags.findIndex(c => c.other) == -1);

      order.total = order.compositions.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      order.oAmount = others.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
      order.oTotal = others.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      order.nAmount = normal.map(x => x.quantity).reduce((sum, current) => sum + current, 0);
      order.nTotal = normal.map(x => x.quantity * (x.compositionOverride != undefined && x.compositionOverride.price != undefined ? x.compositionOverride.price : x.composition.price)).reduce((sum, current) => sum + current, 0);

      if (order.bookingType != BookingType.Employee) {
        let bookingTypeIndex = event.payments.findIndex(x => x.bookingType == order.bookingType);
        if (bookingTypeIndex < 0) bookingTypeIndex = event.payments.push(new PaymentCounter(order.bookingType)) - 1;
        event.payments[bookingTypeIndex].amount++;
        event.payments[bookingTypeIndex].total += order.total;

        let lIndex = event.locations.findIndex(x => x.location.id == order.location.id);
        if (lIndex < 0) lIndex = event.locations.push(new LocationCounter(order.location)) - 1;
        event.locations[lIndex].sales++;
        event.locations[lIndex].total += order.total;

        normal.forEach((normal) => {
          let normalIndex = event.compositions.findIndex(x => x.composition.id == normal.composition.id);
          if (normalIndex < 0) normalIndex = event.compositions.push(new CompositionCounter(normal.composition)) - 1;
          event.compositions[normalIndex].amount += normal.quantity
        })

        others.forEach((other) => {
          let otherIndex = event.others.findIndex(x => x.composition.id == other.composition.id);
          if (otherIndex < 0) otherIndex = event.others.push(new OtherCounter(other.composition)) - 1;
          event.others[otherIndex].amount += other.quantity
          event.others[otherIndex].total += other.quantity * (other.compositionOverride != undefined && other.compositionOverride.price != undefined ? other.compositionOverride.price : other.composition.price)
        })
      }
    })
    event.locations.sort((a, b) => {
      if (a.location.name.toLowerCase() > b.location.name.toLowerCase()) return -1;
      if (a.location.name.toLowerCase() < b.location.name.toLowerCase()) return 1;
      return 0;
    })
    event.compositions.sort((a, b) => {
      return b.amount - a.amount;
    })
    event.others.sort((a, b) => {
      return b.amount - a.amount;
    })
    event.payments.sort((a, b) => {
      return b.total - a.total;
    })
  }

  GetEvent(shard: string, id: string, location: string) {
    this.eventHttpService.get(shard, id, location).subscribe(x => {
      this.error = undefined;
      this.event = x;
      this.CalculateStats(this.event);
      if (location != "") this.GetLocation(shard, location);
      if (this.connection == undefined) this.SignalRConnect(this.event.id, shard);
    }, error => {
      console.error(error);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {event: "", location: ""},
        queryParamsHandling: 'merge'
      })
      this.error = error;
      this.details = false;
    });
  }

  GetLocation(shard: string, id: string) {
    this.locationHttpService.get(shard, id).subscribe(x => {
      this.location = x;
    }, error => {
      console.error(error);
    })
  }

  private downloadFile = (data: HttpResponse<Blob>) => {
    const downloadedFile = new Blob([data.body!], {type: data.body?.type});
    const a = document.createElement("a");
    a.setAttribute("style", "display: none;");
    document.body.appendChild(a);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    this.downloading = false;
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params.event == undefined ? "" : params.event;
      this.shard = params.shard == undefined ? "" : params.shard;
      this.location_id = params.location == undefined ? "" : params.location;
      if (this.id != "" && this.shard != "") {
        this.details = true;
        this.GetEvent(this.shard, this.id, this.location_id);
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {event: "", location: ""},
          queryParamsHandling: 'merge'
        })
      }
    })
    this.LoadData();
  }

  private connection: signalR.HubConnection | undefined;

  SignalRConnect(event: string, shard: string): void {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this.baseUrl + "/hubs/event?event=" + event + "&shard=" + shard, {
        accessTokenFactory: () => this.authenticationService.userValue!.jwt,
        //skipNegotiation: true,
        //transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this.connection.start().then(function () {
      console.log('SignalR connected!');
    }).catch(function (err) {
      return console.error(err);
    });

    this.connection.on("TransferEventData", () => {
      console.log("Loading new data...");
      if (this.event != null && this.shard != null) this.GetEvent(this.shard, this.event.id, this.location_id);
    })
  }

  protected readonly N5BookingType = BookingType;
}
