import {Component, Input, OnInit} from '@angular/core';
import {SettingsPageComponent} from "../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../enums/routes";
import {SettingsInputElementModel} from "../../../models/settings-input-element-model";
import {ActivatedRoute, Router} from "@angular/router";
import {EventHttpService} from "../../x-http-requests/event-http.service";
import {PermissionService} from "../../../_auth/permission.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {EventDto} from "../../../models/event/event-dto";
import {HttpPropertyChangeStatus} from "../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../models/observable-queue-element";
import {AlertLevel} from "../../../enums/alert-level";
import {TimeUtilities} from "../../../utils/time-utilities";

@Component({
  selector: 'app-event-settings',
  templateUrl: '../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./event-settings.component.scss']
})
export class EventSettingsComponent extends SettingsPageComponent {

  public redirectRoute: string = Routes.Events;
  public settingsInputElementModel: { [key: string]: any } = {
    newEventName: new SettingsInputElementModel(),
    newEventStart: new SettingsInputElementModel(),
    newEventEnd: new SettingsInputElementModel(),
    newEventPublic: new SettingsInputElementModel(),
    newEventPublicTitle: new SettingsInputElementModel(),
    newEventPublicDescription: new SettingsInputElementModel(),
    newEventPublicResponseEnd: new SettingsInputElementModel(),
    separator: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public eventHttpService: EventHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newEventName.propertyTitle = "Name";
    this.settingsInputElementModel.newEventName.order = 1;
    this.settingsInputElementModel.newEventName.requiredPermission = PermissionService.Inventory_Event_ChangeName()

    this.settingsInputElementModel.newEventStart.propertyTitle = "Startzeitpunkt";
    this.settingsInputElementModel.newEventStart.inputType = "datetime-local";
    this.settingsInputElementModel.newEventStart.order = 2;
    this.settingsInputElementModel.newEventStart.requiredPermission = PermissionService.Inventory_Event_ChangeTime()

    this.settingsInputElementModel.newEventEnd.propertyTitle = "Endzeitpunkt";
    this.settingsInputElementModel.newEventEnd.inputType = "datetime-local";
    this.settingsInputElementModel.newEventEnd.order = 3;
    this.settingsInputElementModel.newEventEnd.requiredPermission = PermissionService.Inventory_Event_ChangeTime()

    this.settingsInputElementModel.newEventPublic.propertyTitle = "Freigegeben";
    this.settingsInputElementModel.newEventPublic.inputType = "checkbox";
    this.settingsInputElementModel.newEventPublic.order = 11;
    this.settingsInputElementModel.newEventPublic.requiredPermission = PermissionService.CombinedPermission_EventSetPublicProperties();


    this.settingsInputElementModel.newEventPublicTitle.propertyTitle = "Name";
    this.settingsInputElementModel.newEventPublicTitle.requiredPermission = PermissionService.CombinedPermission_EventSetPublicProperties()
    this.settingsInputElementModel.newEventPublicTitle.order = 12;

    this.settingsInputElementModel.newEventPublicResponseEnd.propertyTitle = "Endzeitpunkt Anmeldung";
    this.settingsInputElementModel.newEventPublicResponseEnd.inputType = "datetime-local";
    this.settingsInputElementModel.newEventPublicResponseEnd.requiredPermission = PermissionService.CombinedPermission_EventSetPublicProperties()
    this.settingsInputElementModel.newEventPublicResponseEnd.order = 14;

    this.settingsInputElementModel.separator.propertyTitle = "Öffentliche Eigenschaften";
    this.settingsInputElementModel.separator.inputType = "separator";
    this.settingsInputElementModel.separator.requiredPermission = (PermissionService.CheckPermission(PermissionService.Inventory_Event_SetPublicVariable()) ? PermissionService.Inventory_Event_SetPublicVariable() : PermissionService.Inventory_Event_ChangePublicResponseTime());
    this.settingsInputElementModel.separator.order = 10;

    this.settingsInputElementModel.newEventPublicDescription.propertyTitle = "Beschreibung";
    this.settingsInputElementModel.newEventPublicDescription.inputType = "textarea";
    this.settingsInputElementModel.newEventPublicDescription.requiredPermission = PermissionService.CombinedPermission_EventSetPublicProperties()
    this.settingsInputElementModel.newEventPublicDescription.order = 13;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Event_Delete());
  }

  public model?: EventDto;
  public timeoutClick: boolean = false;


  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveTime())
    this.requiresChanges.push(this.savePublicProperty("PublicTitle", this.settingsInputElementModel.newEventPublicTitle))
    this.requiresChanges.push(this.savePublicProperty("PublicDescription", this.settingsInputElementModel.newEventPublicDescription))
    this.requiresChanges.push(this.savePublicProperty("Public", this.settingsInputElementModel.newEventPublic))
    this.requiresChanges.push(this.savePublicResponseEnd());

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {

    this.eventHttpService.getEvent(id).subscribe((model: EventDto) => {

      this.model = model;
      this.settingsInputElementModel.newEventName.originalPropertyValue = this.settingsInputElementModel.newEventName.changeableProperties.newPropertyValue = model.name;

      this.settingsInputElementModel.newEventStart.originalPropertyValue = this.settingsInputElementModel.newEventStart.changeableProperties.newPropertyValue = TimeUtilities.dateToString(this.model.start)
      this.settingsInputElementModel.newEventEnd.originalPropertyValue = this.settingsInputElementModel.newEventEnd.changeableProperties.newPropertyValue = TimeUtilities.dateToString(this.model.end)


      this.settingsInputElementModel.newEventPublic.originalPropertyValue = this.settingsInputElementModel.newEventPublic.changeableProperties.newPropertyValue = model.public;
      this.settingsInputElementModel.newEventPublicTitle.originalPropertyValue = this.settingsInputElementModel.newEventPublicTitle.changeableProperties.newPropertyValue = model.publicTitle;
      this.settingsInputElementModel.newEventPublicDescription.originalPropertyValue = this.settingsInputElementModel.newEventPublicDescription.changeableProperties.newPropertyValue = model.publicDescription;

      this.settingsInputElementModel.newEventPublicResponseEnd.originalPropertyValue = this.settingsInputElementModel.newEventPublicResponseEnd.changeableProperties.newPropertyValue = TimeUtilities.dateToString(this.model.publicResponseEnd)

      this.route.queryParams.subscribe(params => {
        if (params.last) {
          this.redirectRoute = Routes.Events + "?event=" + model.id + "&last=" + params.last;
        } else {
          this.redirectRoute = Routes.Events + "?event=" + model.id;
        }
      })
    });

  }

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.eventHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Veranstaltung gelöscht", "Veranstaltung wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  deleteModelWrapper: Function = () => {
    if (this.deleteModel === undefined) return;
    this.deleteModel();
    setTimeout(() => {
        this.redirect(Routes.Events);
      }
      , 2000);

  };


  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEventName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEventName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEventName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.eventHttpService.changeName(this.model.id, this.settingsInputElementModel.newEventName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EventDto) => {

      this.model = model;
      this.settingsInputElementModel.newEventName.originalPropertyValue = this.settingsInputElementModel.newEventName.changeableProperties.newPropertyValue = model.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;

    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  savePublicProperty(name: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.eventHttpService.setPublicProperty(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EventDto) => {

      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der öffentlichen Eigenschaft.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;

    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  savePublicResponseEnd(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEventPublicResponseEnd.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEventPublicResponseEnd.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    let time = new Date(this.settingsInputElementModel.newEventPublicResponseEnd.changeableProperties.newPropertyValue);

    observableQueueElement.observable = this.eventHttpService.changePublicResponseTime(this.model.id, time);
    observableQueueElement.callback = (model: EventDto) => {
      this.model = model;
      this.settingsInputElementModel.newEventPublicResponseEnd.originalPropertyValue = this.settingsInputElementModel.newEventPublicResponseEnd.changeableProperties.newPropertyValue = TimeUtilities.toIsoString(new Date(this.model.publicResponseEnd)).slice(0, 16);
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Zeit.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveTime(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if ((this.settingsInputElementModel.newEventStart.changeableProperties.newPropertyValue === undefined) || (this.settingsInputElementModel.newEventEnd.changeableProperties.newPropertyValue === undefined)) return HttpPropertyChangeStatus.NotChanged;
    if ((this.settingsInputElementModel.newEventStart.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEventStart.originalPropertyValue) && (this.settingsInputElementModel.newEventEnd.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEventEnd.originalPropertyValue)) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();

    let startTime = new Date(this.settingsInputElementModel.newEventStart.changeableProperties.newPropertyValue)
    let endTime = new Date(this.settingsInputElementModel.newEventEnd.changeableProperties.newPropertyValue)


    observableQueueElement.observable = this.eventHttpService.changeTime(this.model.id, startTime, endTime);
    observableQueueElement.callback = (model: EventDto) => {

      this.model = model;
      this.settingsInputElementModel.newEventStart.originalPropertyValue = this.settingsInputElementModel.newEventStart.changeableProperties.newPropertyValue = TimeUtilities.toIsoString(new Date(this.model.start)).slice(0, 16);
      this.settingsInputElementModel.newEventEnd.originalPropertyValue = this.settingsInputElementModel.newEventEnd.changeableProperties.newPropertyValue = TimeUtilities.toIsoString(new Date(this.model.end)).slice(0, 16);
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Zeitraum.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;

  }
}

