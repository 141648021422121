import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TagDto} from "../models/tag-dto";
import {TagHttpService} from "../http-requests/tag-http.service";
import { HttpErrorResponse } from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryDto} from "../models/category-dto";
import {CategoryHttpService} from "../http-requests/category-http.service";
import {LocationDto} from "../models/location-dto";
import {PermissionService} from "../_auth/permission.service";
import {Routes} from "../enums/routes";
import {AlertLevel} from "../enums/alert-level";
import {GlobalAlertService} from "../_services/global-alert.service";

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public tags?: TagDto[] = undefined;
  public filtered_tags?: TagDto[] = undefined;
  public searchText: string = "";
  public newTagName: string = "";
  newTagOthersFlag: boolean = false;
  newTagOthersFlagDisabled: boolean = false;
  newTagDisabledFlag: boolean = false;
  newTagDisabledFlagDisabled: boolean = false;
  newTagAllCompositionsFlag: boolean = false;
  newTagAllCompositionsFlagDisabled: boolean = false;

  public inverse: boolean = false;
  public field: string = "";
  public error?: HttpErrorResponse;
  public categories?: CategoryDto[] = undefined;

  constructor(private tagHttpService: TagHttpService,
              private categoryHttpService: CategoryHttpService,
              private route: ActivatedRoute,
              public PermissionService: PermissionService,
              private router: Router,
              private globalAlertService: GlobalAlertService)
  {
  }

  AssignCopy() {
    this.filtered_tags = Object.assign([], this.tags);
  }

  FilterTag(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    }
    else {
      this.filtered_tags = Object.assign([], this.tags?.filter(tag =>
        tag.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ))
    }
  }

  SetOtherTag(state: boolean) {
    if (state) {
      this.newTagDisabledFlag = true;
      this.newTagDisabledFlagDisabled = true;
      this.newTagAllCompositionsFlag = false;
      this.newTagAllCompositionsFlagDisabled = true;
    } else {
      this.newTagDisabledFlag = false;
      this.newTagDisabledFlagDisabled = false;
      this.newTagAllCompositionsFlagDisabled = false;
    }
    this.newTagOthersFlag = state;
  }

  SetAllCompositionsTag(state: boolean) {
    if (state) {
      this.SetOtherTag(false)
      this.newTagOthersFlag = false;
      this.newTagOthersFlagDisabled = true;
    } else {
      this.newTagOthersFlagDisabled = false;
    }
    this.newTagAllCompositionsFlag = state;
  }

  AddTag(): void {
    this.tagHttpService.add(this.newTagName, (document.getElementById("NewTagColor") as HTMLInputElement)!.value.replace("#", ""), this.newTagOthersFlag, this.newTagDisabledFlag, this.newTagAllCompositionsFlag).subscribe(result => {
      if (this.tags == undefined) this.tags = [];
      result.showFilter = "none";
      this.tags.splice(0,0,result);
      this.newTagName = "";
      this.FilterTag(this.searchText);
      this.globalAlertService.createAlertBannerModel("Erfolgreich hinzugefügt", `Der Tag ${result.name} wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Fehler", `Der Tag konnte nicht hinzugefügt werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  RemoveTag(tag: TagDto) {
    let confirmation = confirm("Folgenden Tag sicher löschen:\n" + tag.name + "\n" + tag.id);
    if (!confirmation) return;
    this.tagHttpService.delete(tag.id).subscribe(result => {
      this.tags!.splice(this.tags!.indexOf(tag), 1);
      this.FilterTag(this.searchText);
      this.globalAlertService.createAlertBannerModel("Erfolgreich gelöscht", `Der Tag ${tag.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Löschen des Tags ${tag.name} ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SortBy(field: string) {
    if (this.filtered_tags == undefined) this.filtered_tags = [];
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "order":
        this.filtered_tags.sort((a,b) => {
          if (a.allCompositions && !b.allCompositions && a.order == 0) return 1;
          if (!a.allCompositions && b.allCompositions && b.order == 0) return -1;

          if (a.order-b.order != 0) return a.order - b.order;

          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
        break;
      case "name":
        this.filtered_tags.sort((a,b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        break;
      case "created":
        this.filtered_tags.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        });
        break;
    }
    if (this.inverse) this.filtered_tags.reverse();
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  onFilterChange(tag: TagDto, filter: string) {
    this.tagHttpService.setFilter(tag.id, filter).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Änderung erfolgreich", `Der Filter auf dem Tag ${tag.name} wurde erfolgreich geändert.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", `Der Filter auf dem Tag ${tag.name} konnte nicht geändert werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
      if (this.filtered_tags == undefined) return;
      this.filtered_tags[this.filtered_tags!.indexOf(tag)!].showFilter =
        this.filtered_tags[this.filtered_tags!.indexOf(tag)!].filter != undefined ? this.filtered_tags[this.filtered_tags!.indexOf(tag)!].filter!.id : "none";
    })
  }

  LoadData(): void {
    // loading tags for list view
    this.tagHttpService.list().subscribe(result => {
      this.tags = result;
      this.tags.forEach(tag => {
        if (tag.filter == undefined) tag.showFilter = "none";
        else tag.showFilter = tag.filter.id;
      })
      this.AssignCopy();
    }, error => console.error(error));

    if (this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_TagSetFilter())) {
      this.categoryHttpService.list().subscribe(result => {
        this.categories = result;
      }, error => console.error(error));
    }
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.TagSettings], {queryParams: {id: id}});
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

    });
    this.LoadData();
  }
}
