import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EmployeeHttpService} from "../x-http-requests/employee-http.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {EmployeeDto} from "../x-models/employee-dto";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {CompositionMinimal} from "../../../models/composition/composition-minimal";
import {BookingType} from "../../../models/payment/booking-type";
import {EmployeeDetailsView} from "../x-models/x-enums/employee-details-view";
import {PermissionService} from "../../../_auth/permission.service";
import {EmployeeEventResponseSummary} from "../../../models/event/employee-event-response-summary";
import {EmploymentRelationshipLinkerDto} from "../x-models/employment-relationship-linker-dto";
import {CompanyRoutes} from "../x-models/x-enums/company-routes";
import {EmployeeDetailsSalesView} from "../x-models/x-enums/employee-details-sales-view";
import {EventDto} from "../../../models/event/event-dto";
import {OrderEdto} from "../../../models/order/order-edto";
import {CompositionOverrideLinkerEdto} from "../../../management/composition/composition-override-linker-edto";
import {OrderDto} from "../../../models/order-dto";
import {GlobalDateService} from "../../../_services/global-date.service";
import {GlobalDateFormat} from "../../../enums/global-date-format";
import {EmployeeService} from "../_services/_dto-services/employee.service";
import {PlatformScannerService} from "../../../_services/platform-scanner.service";
import {DownloadInformation} from "../../x-components/x-models/download-information";
import {TimeUtilities} from "../../../utils/time-utilities";
import {ServerEndpoints} from "../../../server.endpoints";
import {AlignmentService} from "../../../_services/alignment.service";

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  constructor(private employeeHttpService: EmployeeHttpService,
              private employeeService: EmployeeService,
              private globalAlertService: GlobalAlertService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              public GlobalDateService: GlobalDateService,
              public PlatformScannerService: PlatformScannerService,
              public AlignmentService: AlignmentService) {
  }

  employee?: EmployeeDto;
  start: string = "";
  end: string = "";

  isFinished(er: EmploymentRelationshipLinkerDto): boolean {
    return er.end < new Date();
  }

  employeeSales?: CompositionMinimal[]
  employeeShifts?: EmployeeEventResponseSummary[]
  employmentRelationships?: EmploymentRelationshipLinkerDto[];
  employeeSalesError: boolean = false;
  view: EmployeeDetailsView = EmployeeDetailsView.Shifts;

  GetEmployeeSalesSum(): number {
    if (!this.employeeSales) return 0;

    return this.employeeSales.map(x => x.quantity * x.price).reduce((sum, current) => sum + current, 0);
  }

  LoadEmployee(id: string) {
    this.employee = this.employeeService.GetById(id);
    if (!this.employee) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiter konnte nicht geladen werden.", AlertLevel.error, 3000);
      this.globalAlertService.show();
      this.router.navigate([Routes.CompanyEmployee]);
      return;
    }

    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Employee_EmployeeSalesList())) {
      this.LoadEmployeeSales(id, this.start, this.end);
    }
    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Employee_GetEmployeeShiftList())) {
      this.LoadEmployeeShiftsStr(id, this.start, this.end)
    }

    if (this.employeeAttributeEmployee != undefined) {
      this.employeeAttributeEmployee = this.employee;
    }
  }

  employeeDetailsSalesView: EmployeeDetailsSalesView = EmployeeDetailsSalesView.List;
  employeeSalesEvents: EventDto[] | undefined | null = undefined;

  calculateOrderTotal(order: OrderEdto): number {
    return order.compositions.reduce((sum: number, linker: CompositionOverrideLinkerEdto) => sum + linker.quantity * (linker.compositionOverride?.price ?? linker.composition.price), 0);
  }

  calculateEventTotal(event: EventDto): number {
    return event.orders.reduce((sum: number, order: OrderEdto) => sum += this.calculateOrderTotal(order), 0);
  }

  LoadEmployeeSales(id: string, start: string, end: string) {
    this.employeeHttpService.employeeSalesOrders(id, start, end).subscribe({
      next: events => {
        this.employeeSalesEvents = events;
      },
      error: error => {
        console.error(error);
        this.employeeSalesEvents = null;
        this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiterverkäufe nach Events konnten nicht geladen werden.", AlertLevel.error, 2000);
        this.globalAlertService.show();
      }
    })

    this.employeeHttpService.employeeSalesList(id, start, end).subscribe(x => {
      this.employeeSalesError = false;
      this.employeeSales = x;
    }, error => {
      this.employeeSales = undefined;
      this.employeeSalesError = true;
      //this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiterverkäufe konnten nicht geladen werden.", AlertLevel.error, 3000);
      //this.globalAlertService.show()
    })
  }

  LoadEmployeeShiftsStr(id: string, start: string, end: string) {
    this.LoadEmployeeShifts(id, new Date(start), new Date(end));
  }

  LoadEmployeeShifts(id: string, start: Date, end: Date) {
    this.employeeHttpService.getEmployeeShiftList(id, start, end).subscribe(x => {
      this.employmentRelationships = x.relationships;
      this.employeeShifts = x.responses;

      this.employeeShifts?.forEach(response => {
        if (!response.workingHours || !response.start) return;

        let relationship = this.employmentRelationships?.find(c => c.start <= response.start! && c.end > response.start!);
        if (!relationship) return;

        response.salary = response.workingHours * relationship.employmentRelationship!.salary
      })
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", `Arbeitszeiten von ${this.employee!.firstName} ${this.employee!.lastName} konnten nicht geladen werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show()
    })
  }

  // employee attribute popup
  mouseEvent?: MouseEvent;
  employeeAttributePopupVisible: boolean = false;
  employeeAttributeEmployee: EmployeeDto | undefined;

  openPopup(e: any) {
    this.employeeAttributeEmployee = this.employee;
    this.mouseEvent = e;
    this.employeeAttributePopupVisible = true;
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["start"]) this.start = params["start"];
      if (params["end"]) this.end = params["end"];
    })

    this.route.queryParams.subscribe(params => {
      if (!params.id) {
        this.globalAlertService.createAlertBannerModel("Fehler", "Fehlerhafte Parameter beim Seitenaufruf, Weiterleitung zur Übersicht.", AlertLevel.error, 3000);
        this.globalAlertService.show();
        this.router.navigate([Routes.CompanyEmployee])
      } else {
        this.start = params.start ?? (new Date()).toISOString().split("T")[0];
        this.end = params.end ?? (new Date()).toISOString().split("T")[0];

        this.employeeService.EntitiesChanged.subscribe(x => {
          if (!x || x.length == 0) return;
          this.LoadEmployee(params.id)
        })
      }
    })
  }

  getDrinkCount(sales: CompositionMinimal[]): number {
    return sales.reduce((sum, current) => sum + current.quantity, 0);
  }

  getTotalSalary(): number {
    if (!this.employeeShifts) return 0;
    return this.employeeShifts.filter(x => x.salary).reduce((sum, current) => sum + current.salary, 0);
  }

  getTotalHours(): number {
    if (!this.employeeShifts) return 0;
    return this.employeeShifts.filter(x => x.workingHours).reduce((sum, current) => sum + current.workingHours!, 0);
  }

  getIsoString(date: string): string {
    return new Date(date).toISOString()
  }

  // employment relationship
  erVisible: boolean = false;

  erReloadEmployee() {
    if (this.erVisible) return;

    this.route.queryParams.subscribe(params => {
      if (!params.id) {
        this.globalAlertService.createAlertBannerModel("Fehler", "Fehlerhafte Parameter beim Seitenaufruf, Weiterleitung zur Übersicht.", AlertLevel.error, 3000);
        this.globalAlertService.show();
        this.router.navigate([Routes.CompanyEmployee])
      } else {
        this.start = params.start ?? (new Date()).toISOString().split("T")[0];
        this.end = params.end ?? (new Date()).toISOString().split("T")[0];

        this.LoadEmployee(params.id)
      }
    })
  }

  // download
  downloadInformation: DownloadInformation = new DownloadInformation();
  downloadParams: string = "";

  getDownloadParams(): string {
    if (!this.employee) return "";
    let start = new Date(this.start)
    start.setHours(0);
    let end = new Date(this.end)
    end.setHours(0);
    end.setDate(end.getDate() + 1);
    return `id=${this.employee?.id}&start=${start.toISOString()}&end=${end.toISOString()}`;
  }


  protected readonly N5BookingType = BookingType;
  protected readonly Routes = Routes;
  protected readonly EmployeeDetailsView = EmployeeDetailsView;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly EmployeeDetailsSalesView = EmployeeDetailsSalesView;
  protected readonly GlobalDateFormat = GlobalDateFormat;
  protected readonly ServerEndpoints = ServerEndpoints;
}
