<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <div class="icon-background h-32 w-32 rounded-full mx-auto grid place-content-center">
      <img class="mx-auto h-24 w-auto" src="./assets/images/icon_nobackground_md.png" alt="Nymu5 Club Management Logo">
    </div>
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Login-Bereich</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white shadow sm:rounded-lg">
      <form class="space-y-6 mx-6 py-12 sm:mx-12" action="#" method="POST"
            *ngIf="!UserSelectorService.hasUsers() || showLogin;else selector;">
        <a class="inline-flex w-full -ml-5" *ngIf="UserSelectorService.hasUsers()" (click)="showLogin = false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="h-5 w-5 place-self-center">
            <path fill="currentColor"
                  d="M20.834 8.037L9.64 14.5c-1.43.824-1.43 2.175 0 3l11.194 6.463c1.43.826 2.598.15 2.598-1.5V9.537c0-1.65-1.17-2.326-2.598-1.5"/>
          </svg>
          <span class="place-self-center">Accounts anzeigen</span>
        </a>
        <div>
          <label for="username" class="block text-sm font-medium leading-6 text-gray-900">Benutzername</label>
          <div class="mt-2">
            <input [(ngModel)]="username" id="username" name="username" type="text" required
                   class="block w-full rounded-md border-2 border-gray-200 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Passwort</label>
          <div class="mt-2">
            <input [(ngModel)]="password" id="password" name="password" type="password" autocomplete="current-password"
                   required
                   class="block px-2 w-full rounded-md border-2 border-gray-200 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <span class="text-sm">
            Mit dem Login bestätige ich, dass ich die
            <a class="font-bold text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
               [href]="location.protocol + '//' + location.host + '/static/privacy.html'" target="_blank">Datenschutzbestimmungen</a>
            gelesen und verstanden habe und akzeptiere diese.
          </span>
        </div>

        <div>
          <button (click)="login(username, password)" type="submit"
                  class="flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700 login-button">
            Anmelden
          </button>
        </div>
      </form>
      <ng-template #selector class="flex flex-col w-full -mx-6 -my-12 sm:-mx-12 relative">
        <div class="w-full" *ngFor="let user of UserSelectorService.users;let first = first;let last = last;">
          <button
            class="w-full bg-white border-gray-300 border-1 !border-r-0 !border-l-0 !border-t-0 flex flex-col p-3
            hover:!bg-gray-200 focus:!z-10 hover:z-10 ring-inset"
            (click)="UserSelectorService.isExpired(user.jwt) ? reauth(user) : AuthenticationService.select(user)">
            <div class="flex w-full">
              <span class="fw-bold">{{ user.name }}</span>
              <span class="ml-auto font-light text-gray-400">
                {{ UserSelectorService.isExpired(user.jwt) ? "abgemeldet" : "angemeldet" }}
              </span>
            </div>
            <div class="w-full flex flex-col text-left text-gray-400">
              <span *ngIf="user.shard" class="fw-light text-sm">{{ user.shard.name }}</span>
              <span *ngIf="user.company" class="fw-light text-sm">{{ user.company.name }}</span>
            </div>
          </button>
        </div>
        <div class="flex w-full py-3">
          <a class="text-theme-primary-700 hover:text-theme-primary-900 m-auto" (click)="showLogin = true">Anderen
            Account nutzen</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
