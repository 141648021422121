import {LocationDto} from "../location-dto";
import {ConfigurationDto} from "../configuration-dto";

export class LocationConfigLinker {
  location: LocationDto = new LocationDto();
  configuration?: ConfigurationDto;

  constructor(location: LocationDto, configuration: ConfigurationDto | undefined = undefined) {
    this.location = location;
    this.configuration = configuration;
  }
}
