import {ModelBase} from "../model-base";
import {IModelBase} from "../i-model-base";
import {CompositionDto} from "../composition-dto";
import {CompositionNl} from "../composition-nl";

export class CompositionCounter {
  constructor(composition: CompositionDto) {
    this.composition = composition;
  }
  composition: CompositionDto;
  amount: number = 0;
}
