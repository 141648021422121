export enum ServerEndpoints {
  Administration_Contract_GetCurretCompany = "/administration/contract/getcurretcompany",
  Administration_MigrationAssistant_Event_Register_Patch_20231003 = "/administration/migrationassistant/event_register_patch_20231003",
  Administration_MigrationAssistant_GetPatches = "/administration/migrationassistant/getpatches",
  Administration_MigrationAssistant_InventoryNumber_Patch_20230909 = "/administration/migrationassistant/inventorynumber_patch_20230909",
  Administration_MigrationAssistant_Vat_Patch_20230912 = "/administration/migrationassistant/vat_patch_20230912",
  Administration_Permission_List = "/administration/permission/list",
  Administration_Role_Add = "/administration/role/add",
  Administration_Role_AddUser = "/administration/role/adduser",
  Administration_Role_Delete = "/administration/role/delete",
  Administration_Role_Get = "/administration/role/get",
  Administration_Role_List = "/administration/role/list",
  Administration_Role_RemoveUser = "/administration/role/removeuser",
  Administration_Role_TogglePermission = "/administration/role/togglepermission",
  Administration_Settings_GetLogo = "/administration/settings/getlogo",
  Administration_Settings_GetTimezone = "/administration/settings/gettimezone",
  Administration_Settings_SetLogo = "/administration/settings/setlogo",
  Administration_Settings_SetTimezone = "/administration/settings/settimezone",
  Administration_User_Add = "/administration/user/add",
  Administration_User_Delete = "/administration/user/delete",
  Administration_User_Get = "/administration/user/get",
  Administration_User_List = "/administration/user/list",
  Administration_User_ListCompanyUser = "/administration/user/listcompanyuser",
  Administration_User_ListOwners = "/administration/user/listowners",
  Analysis_EventAnalysis_FetchEventData = "/analysis/eventanalysis/fetcheventdata",
  Analysis_EventAnalysis_GetEventsLike = "/analysis/eventanalysis/geteventslike",
  Automation_Scheduler_DeleteGroup = "/automation/scheduler/deletegroup",
  Automation_Scheduler_GetSchedulerGroup = "/automation/scheduler/getschedulergroup",
  Automation_Scheduler_GetSchedulerObjects = "/automation/scheduler/getschedulerobjects",
  Automation_Scheduler_ScheduleGroup = "/automation/scheduler/schedulegroup",
  Booking_Order_GetOrderCountFromShard = "/booking/order/getordercountfromshard",
  Booking_Order_List = "/booking/order/list",
  Booking_Order_SetActiveState = "/booking/order/setactivestate",
  Booking_Table_Add = "/booking/table/add",
  Booking_Table_Delete = "/booking/table/delete",
  Booking_Table_Get = "/booking/table/get",
  Booking_Table_List = "/booking/table/list",
  Booking_Table_Upsert = "/booking/table/upsert",
  Booking_Table_UpsertList = "/booking/table/upsertlist",
  Company_Company_GetCompanyShards = "/company/company/getcompanyshards",
  Company_Company_List = "/company/company/list",
  Company_Employee_Add = "/company/employee/add",
  Company_Employee_AllowEmployeeLogin = "/company/employee/allowemployeelogin",
  Company_EmployeeAttribute_Add = "/company/employeeattribute/add",
  Company_EmployeeAttribute_ChangeName = "/company/employeeattribute/changename",
  Company_EmployeeAttribute_ChangeShortName = "/company/employeeattribute/changeshortname",
  Company_EmployeeAttribute_Delete = "/company/employeeattribute/delete",
  Company_EmployeeAttribute_Get = "/company/employeeattribute/get",
  Company_EmployeeAttribute_List = "/company/employeeattribute/list",
  Company_EmployeeAttribute_SetEmployeeAttributes = "/company/employeeattribute/setemployeeattributes",
  Company_Employee_ChangeName = "/company/employee/changename",
  Company_Employee_Delete = "/company/employee/delete",
  Company_Employee_EmployeeSalesList = "/company/employee/employeesaleslist",
  Company_Employee_EmployeeSalesOrders = "/company/employee/employeesalesorders",
  Company_Employee_Get = "/company/employee/get",
  Company_Employee_GetEmployeeShiftList = "/company/employee/getemployeeshiftlist",
  Company_Employee_GetRestricted = "/company/employee/getrestricted",
  Company_Employee_List = "/company/employee/list",
  Company_Employee_ListMinimal = "/company/employee/listminimal",
  Company_Employee_ListNl = "/company/employee/listnl",
  Company_Employee_RequestVerification = "/company/employee/requestverification",
  Company_Employee_ResetSocial = "/company/employee/resetsocial",
  Company_Employee_SetNewPassword = "/company/employee/setnewpassword",
  Company_Employee_SetPublicProperty = "/company/employee/setpublicproperty",
  Company_Employee_SetSocial = "/company/employee/setsocial",
  Company_EmploymentRelationship_Add = "/company/employmentrelationship/add",
  Company_EmploymentRelationship_ChangeName = "/company/employmentrelationship/changename",
  Company_EmploymentRelationship_Delete = "/company/employmentrelationship/delete",
  Company_EmploymentRelationship_Get = "/company/employmentrelationship/get",
  Company_EmploymentRelationship_GetEventEmploymentCosts = "/company/employmentrelationship/geteventemploymentcosts",
  Company_EmploymentRelationship_GetEventsEmploymentCosts = "/company/employmentrelationship/geteventsemploymentcosts",
  Company_EmploymentRelationship_List = "/company/employmentrelationship/list",
  Company_EmploymentRelationship_SetRelationship = "/company/employmentrelationship/setrelationship",
  Company_EmploymentRelationship_SetValues = "/company/employmentrelationship/setvalues",
  Company_Setting_List = "/company/setting/list",
  Company_Setting_SetAuthPair = "/company/setting/setauthpair",
  Company_Setting_SetAuthPairs = "/company/setting/setauthpairs",
  Company_Setting_SumUpIsConfigured = "/company/setting/sumupisconfigured",
  Company_Setting_TelegramIsConfigured = "/company/setting/telegramisconfigured",
  Company_Supplier_Add = "/company/supplier/add",
  Company_Supplier_ChangeName = "/company/supplier/changename",
  Company_Supplier_Delete = "/company/supplier/delete",
  Company_Supplier_Get = "/company/supplier/get",
  Company_Supplier_List = "/company/supplier/list",
  Company_Supplier_Update = "/company/supplier/update",
  Company_Telegram_AddBot = "/company/telegram/addbot",
  Company_Telegram_AddChannel = "/company/telegram/addchannel",
  Company_Telegram_DeleteBot = "/company/telegram/deletebot",
  Company_Telegram_DeleteChannel = "/company/telegram/deletechannel",
  Company_Telegram_GetBots = "/company/telegram/getbots",
  Company_Telegram_GetChannelSettings = "/company/telegram/getchannelsettings",
  Company_Telegram_List = "/company/telegram/list",
  Company_Telegram_SetChannelSettings = "/company/telegram/setchannelsettings",
  Device_CashPointEntry_Close = "/device/cashpointentry/close",
  Device_CashPointEntry_GetLastClosed = "/device/cashpointentry/getlastclosed",
  Device_CashPointEntry_GetOpen = "/device/cashpointentry/getopen",
  Device_CashPointEntry_GetSummary = "/device/cashpointentry/getsummary",
  Device_CashPointEntry_List = "/device/cashpointentry/list",
  Device_CashPointEntry_Open = "/device/cashpointentry/open",
  Device_CashPointEntry_PrepareForClosing = "/device/cashpointentry/prepareforclosing",
  Device_CashPointEntry_Summary = "/device/cashpointentry/summary",
  Device_Client_GetData = "/device/client/getdata",
  Device_Client_ProcessTransactions = "/device/client/processtransactions",
  Device_Information_ChangeName = "/device/information/changename",
  Device_Information_Delete = "/device/information/delete",
  Device_Information_Get = "/device/information/get",
  Device_Information_List = "/device/information/list",
  Device_Information_RequestInformation = "/device/information/requestinformation",
  Device_Information_SetLockedOut = "/device/information/setlockedout",
  Device_Printer_Add = "/device/printer/add",
  Device_Printer_ChangeName = "/device/printer/changename",
  Device_Printer_Delete = "/device/printer/delete",
  Device_Printer_Get = "/device/printer/get",
  Device_Printer_List = "/device/printer/list",
  Device_Printer_SetVariable = "/device/printer/setvariable",
  Document_Employee_Download = "/document/employee/download",
  Document_Employee_DownloadIndividualDetails = "/document/employee/downloadindividualdetails",
  Document_EmploymentRelationship_Download = "/document/employmentrelationship/download",
  Document_ShiftMaker_Download = "/document/shiftmaker/download",
  Hubs_AutomationHub_Subscribe = "/hubs/automationhub/subscribe",
  Hubs_ClientHub_DV_Information_Set = "/hubs/clienthub/dv_information_set",
  Hubs_ClientHub_DV_Location_Set = "/hubs/clienthub/dv_location_set",
  Hubs_ClientHub_GetDeviceInformation = "/hubs/clienthub/getdeviceinformation",
  Hubs_ClientHub_InvokeEReceiptTransaction = "/hubs/clienthub/invokeereceipttransaction",
  Hubs_ClientHub_O_Transmit = "/hubs/clienthub/o_transmit",
  Hubs_ClientHub_RequestHelp = "/hubs/clienthub/requesthelp",
  Hubs_DeviceHub_JoinListeners = "/hubs/devicehub/joinlisteners",
  Hubs_ShiftMakerHub_PushGeneralUpdate = "/hubs/shiftmakerhub/pushgeneralupdate",
  Hubs_ShiftMakerHub_PushLiveUpdate = "/hubs/shiftmakerhub/pushliveupdate",
  Inventory_Category_Add = "/inventory/category/add",
  Inventory_Category_ChangeName = "/inventory/category/changename",
  Inventory_Category_Delete = "/inventory/category/delete",
  Inventory_Category_Get = "/inventory/category/get",
  Inventory_Category_List = "/inventory/category/list",
  Inventory_Component_Add = "/inventory/component/add",
  Inventory_Component_ChangeName = "/inventory/component/changename",
  Inventory_Component_Delete = "/inventory/component/delete",
  Inventory_Component_Get = "/inventory/component/get",
  Inventory_Component_List = "/inventory/component/list",
  Inventory_Composition_Add = "/inventory/composition/add",
  Inventory_Composition_ChangeName = "/inventory/composition/changename",
  Inventory_Composition_ChangePublicProperty = "/inventory/composition/changepublicproperty",
  Inventory_Composition_ComponentChange = "/inventory/composition/componentchange",
  Inventory_Composition_Create = "/inventory/composition/create",
  Inventory_Composition_Delete = "/inventory/composition/delete",
  Inventory_Composition_Download = "/inventory/composition/download",
  Inventory_Composition_Get = "/inventory/composition/get",
  Inventory_Composition_List = "/inventory/composition/list",
  Inventory_Composition_PriceChange = "/inventory/composition/pricechange",
  Inventory_Composition_SetOrder = "/inventory/composition/setorder",
  Inventory_Composition_ToggleTag = "/inventory/composition/toggletag",
  Inventory_CompositionType_Add = "/inventory/compositiontype/add",
  Inventory_CompositionType_ChangeName = "/inventory/compositiontype/changename",
  Inventory_CompositionType_ChangeVat = "/inventory/compositiontype/changevat",
  Inventory_CompositionType_Delete = "/inventory/compositiontype/delete",
  Inventory_CompositionType_Get = "/inventory/compositiontype/get",
  Inventory_CompositionType_List = "/inventory/compositiontype/list",
  Inventory_CompositionType_ListNl = "/inventory/compositiontype/listnl",
  Inventory_CompositionType_SetCompositionType = "/inventory/compositiontype/setcompositiontype",
  Inventory_CompositionType_SetValue = "/inventory/compositiontype/setvalue",
  Inventory_Configuration_Add = "/inventory/configuration/add",
  Inventory_ConfigurationBuilder_Add = "/inventory/configurationbuilder/add",
  Inventory_ConfigurationBuilder_List = "/inventory/configurationbuilder/list",
  Inventory_ConfigurationBuilder_SetSpecial = "/inventory/configurationbuilder/setspecial",
  Inventory_Configuration_ChangeName = "/inventory/configuration/changename",
  Inventory_Configuration_Delete = "/inventory/configuration/delete",
  Inventory_Configuration_Get = "/inventory/configuration/get",
  Inventory_Configuration_List = "/inventory/configuration/list",
  Inventory_Event_Add = "/inventory/event/add",
  Inventory_Event_ChangeName = "/inventory/event/changename",
  Inventory_Event_ChangePublicResponseTime = "/inventory/event/changepublicresponsetime",
  Inventory_Event_ChangeTime = "/inventory/event/changetime",
  Inventory_Event_Delete = "/inventory/event/delete",
  Inventory_Event_Download = "/inventory/event/download",
  Inventory_Event_Downloads = "/inventory/event/downloads",
  Inventory_Event_Get = "/inventory/event/get",
  Inventory_Event_GetCurrentOrNext = "/inventory/event/getcurrentornext",
  Inventory_Event_GetDisabledLocations = "/inventory/event/getdisabledlocations",
  Inventory_Event_GetEmployeeAvailability = "/inventory/event/getemployeeavailability",
  Inventory_Event_GetEmployeeOrders = "/inventory/event/getemployeeorders",
  Inventory_Event_GetEventSummary = "/inventory/event/geteventsummary",
  Inventory_Event_GetExtendedEvent = "/inventory/event/getextendedevent",
  Inventory_Event_GetPrivatePublicEvents = "/inventory/event/getprivatepublicevents",
  Inventory_Event_GetPublicEvents = "/inventory/event/getpublicevents",
  Inventory_Event_List = "/inventory/event/list",
  Inventory_Event_SendEmployeeEventResponse = "/inventory/event/sendemployeeeventresponse",
  Inventory_Event_SetLocationConfigs = "/inventory/event/setlocationconfigs",
  Inventory_Event_SetPublicVariable = "/inventory/event/setpublicvariable",
  Inventory_Event_Upsert = "/inventory/event/upsert",
  Inventory_General_GetDashboardStats = "/inventory/general/getdashboardstats",
  Inventory_General_OnlineCheck = "/inventory/general/onlinecheck",
  Inventory_Item_Add = "/inventory/item/add",
  Inventory_Item_ChangeName = "/inventory/item/changename",
  Inventory_Item_Delete = "/inventory/item/delete",
  Inventory_Item_Get = "/inventory/item/get",
  Inventory_Item_GetEventSourcingCosts = "/inventory/item/geteventsourcingcosts",
  Inventory_Item_GetEventsSourcingCosts = "/inventory/item/geteventssourcingcosts",
  Inventory_Item_List = "/inventory/item/list",
  Inventory_Item_SetSourceDetail = "/inventory/item/setsourcedetail",
  Inventory_Item_SetUnit = "/inventory/item/setunit",
  Inventory_Location_Add = "/inventory/location/add",
  Inventory_Location_ChangeName = "/inventory/location/changename",
  Inventory_Location_ClientList = "/inventory/location/clientlist",
  Inventory_Location_Delete = "/inventory/location/delete",
  Inventory_Location_Get = "/inventory/location/get",
  Inventory_Location_GetCompanyLocations = "/inventory/location/getcompanylocations",
  Inventory_Location_List = "/inventory/location/list",
  Inventory_Location_PushLocation = "/inventory/location/pushlocation",
  Inventory_Location_SetDefaultEmployeeCount = "/inventory/location/setdefaultemployeecount",
  Inventory_Location_SetLocationConfig = "/inventory/location/setlocationconfig",
  Inventory_Location_SetOrder = "/inventory/location/setorder",
  Inventory_Location_SetTipShare = "/inventory/location/settipshare",
  Inventory_Override_Composition = "/inventory/override/composition",
  Inventory_Override_Tag = "/inventory/override/tag",
  Inventory_Tag_Add = "/inventory/tag/add",
  Inventory_Tag_ChangeColor = "/inventory/tag/changecolor",
  Inventory_Tag_ChangeName = "/inventory/tag/changename",
  Inventory_Tag_Delete = "/inventory/tag/delete",
  Inventory_Tag_Get = "/inventory/tag/get",
  Inventory_Tag_List = "/inventory/tag/list",
  Inventory_Tag_SetFilter = "/inventory/tag/setfilter",
  Inventory_Tag_SetOrder = "/inventory/tag/setorder",
  Legal_Settings_IsConfigured = "/legal/settings/isconfigured",
  Legal_Settings_List = "/legal/settings/list",
  Legal_Settings_SelectProvider = "/legal/settings/selectprovider",
  Legal_Settings_SetCredentials = "/legal/settings/setcredentials",
  Legal_TSS_ChangeState = "/legal/tss/changestate",
  Legal_TSS_Create = "/legal/tss/create",
  Legal_TSS_GetCurrent = "/legal/tss/getcurrent",
  Management_Changelog_Add = "/management/changelog/add",
  Management_Changelog_Delete = "/management/changelog/delete",
  Management_Changelog_Reactivate = "/management/changelog/reactivate",
  Management_Changelog_Update = "/management/changelog/update",
  Management_Company_Add = "/management/company/add",
  Management_Company_ChangeName = "/management/company/changename",
  Management_Company_Delete = "/management/company/delete",
  Management_Company_Get = "/management/company/get",
  Management_Company_List = "/management/company/list",
  Management_Company_SetAddress = "/management/company/setaddress",
  Management_Company_SetAlias = "/management/company/setalias",
  Management_Company_SetMetadata = "/management/company/setmetadata",
  Management_Contract_Delete = "/management/contract/delete",
  Management_Contract_GetCompany = "/management/contract/getcompany",
  Management_Contract_GetCustomerValues = "/management/contract/getcustomervalues",
  Management_Contract_Set = "/management/contract/set",
  Management_Contract_Update = "/management/contract/update",
  Management_Device_Location = "/management/device/location",
  Management_Environment_Add = "/management/environment/add",
  Management_Environment_Delete = "/management/environment/delete",
  Management_Environment_Get = "/management/environment/get",
  Management_Environment_List = "/management/environment/list",
  Management_Environment_Upsert = "/management/environment/upsert",
  Management_Fiskaly_IsConfigured = "/management/fiskaly/isconfigured",
  Management_Fiskaly_SetCredentials = "/management/fiskaly/setcredentials",
  Management_Fiskaly_UpsertCompany = "/management/fiskaly/upsertcompany",
  Management_Fiskaly_UpsertShard = "/management/fiskaly/upsertshard",
  Management_Hangfire_Authorize = "/management/hangfire/authorize",
  Management_Permission_GenerateTsEndpoints = "/management/permission/generatetsendpoints",
  Management_Permission_GenerateTsPermissionSets = "/management/permission/generatetspermissionsets",
  Management_Setup_Configure = "/management/setup/configure",
  Management_Setup_IsConfigured = "/management/setup/isconfigured",
  Management_Shard_Add = "/management/shard/add",
  Management_Shard_ChangeName = "/management/shard/changename",
  Management_Shard_Delete = "/management/shard/delete",
  Management_Shard_Get = "/management/shard/get",
  Management_Shard_List = "/management/shard/list",
  Management_Shard_SetAddress = "/management/shard/setaddress",
  Management_Shard_SetMetadata = "/management/shard/setmetadata",
  Management_User_Create = "/management/user/create",
  Management_User_Delete = "/management/user/delete",
  Management_User_List = "/management/user/list",
  Miscellaneous_Changelog_List = "/miscellaneous/changelog/list",
  Miscellaneous_Feedback_Add = "/miscellaneous/feedback/add",
  Miscellaneous_Feedback_AddComment = "/miscellaneous/feedback/addcomment",
  Miscellaneous_Feedback_List = "/miscellaneous/feedback/list",
  Miscellaneous_Feedback_ListComments = "/miscellaneous/feedback/listcomments",
  Payment_Settings_Get = "/payment/settings/get",
  Payment_Settings_IsConfigured = "/payment/settings/isconfigured",
  Payment_Settings_SelectProvider = "/payment/settings/selectprovider",
  Payment_Settings_SetCredentials = "/payment/settings/setcredentials",
}
