import {Component, OnInit} from '@angular/core';
import {PermissionService} from "../../../_auth/permission.service";
import {EmploymentRelationshipHttpService} from "../x-http-requests/employment-relationship-http.service";
import {EmploymentRelationshipDto} from "../x-models/employment-relationship-dto";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {Router} from "@angular/router";
import {CompanyRoutes} from "../x-models/x-enums/company-routes";
import {Side} from "../x-models/x-enums/side";
import {TimeUtilities} from "../../../utils/time-utilities";
import {DownloadInformation} from "../../x-components/x-models/download-information";
import {EmploymentRelationshipService} from "../_services/_dto-services/employment-relationship.service";
import {ServerEndpoints} from "../../../server.endpoints";

@Component({
  selector: 'app-employee-relationships',
  templateUrl: './employment-relationships.component.html',
  styleUrls: ['./employment-relationships.component.scss']
})
export class EmploymentRelationshipsComponent implements OnInit {
  constructor(public PermissionService: PermissionService, private employmentRelationshipHttpService: EmploymentRelationshipHttpService,
              private globalAlertService: GlobalAlertService, private router: Router,
              public EmploymentRelationshipService: EmploymentRelationshipService) {
    this.EmploymentRelationshipService.EntitiesChanged.subscribe({
      next: value => {
        this.Filter();
      }
    })
  }

  ngOnInit(): void {
    this.start = new Date();
    this.start.setHours(0, 0, 0, 0);
    this.end = new Date();
    this.end.setHours(0, 0, 0, 0);

    // this.EmploymentRelationshipService.LoadData();
  }

  Filter() {
    this.relationships_filtered = Object.assign([], this.EmploymentRelationshipService.Entities?.filter(x => this.er_name == "" || x.name.toLowerCase().indexOf(this.er_name.toLowerCase()) > -1));
  }

  // relationships: EmploymentRelationshipDto[] | undefined;
  relationships_filtered: EmploymentRelationshipDto[] | undefined;

  er_name: string = "";
  er_salary: number = 12;
  er_limit: number | undefined;
  er_multiplier: number | undefined;

  Add() {
    this.employmentRelationshipHttpService.add(this.er_name, this.er_salary, this.er_limit, this.er_multiplier ?? 1).subscribe(x => {
      this.er_name = "";
      this.EmploymentRelationshipService.Replace(x);
      this.Filter();
      this.globalAlertService.createAlertBannerModel("Hinzugefügt", "Arbeitsverhältnis erfolgreich hinzugefügt.", AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Hinzufügen ist ein Fehler aufgetreten. Bitte Werte überprüfen.", AlertLevel.error, 3000);
      this.globalAlertService.show();
    })
  }

  redirectToSettings(id: string) {
    this.router.navigate(["/" + Routes.CompanyModule + "/" + CompanyRoutes.EmployeeRelationshipsSettings], {queryParams: {id: id}});
  }

  selectionMode: boolean = false;

  enterSelectionMode() {
    this.selectionMode = true;
  }

  leaveSelectionMode() {
    this.selectionMode = false;
  }

  start: Date = new Date();
  end: Date = new Date();

  setDate(value: string, side: Side) {
    switch (side) {
      case Side.Left:
        this.start = new Date(value);
        if (value == "") this.start = new Date();
        this.start.setHours(0, 0, 0, 0)
        this.end = new Date(this.start);
        this.end.setMonth(this.end.getMonth() + 1, 0);
        break;
      case Side.Right:
        this.end = new Date(value)
        if (value == "") this.end = new Date();
        this.end.setHours(0, 0, 0, 0)
        break;
    }
    this.downloadParams = `start=${this.start.toISOString()}&end=${this.end.toISOString()}`
  }

  getDate(side: Side): string {
    switch (side) {
      case Side.Left:
        return TimeUtilities.dayToString(this.start)
      case Side.Right:
        return TimeUtilities.dayToString(this.end)
    }
  }

  setDownloadData() {
    this.downloadData = this.EmploymentRelationshipService.GetSelectedIds() ?? [];
  }

  areValidDates(): boolean {
    return this.start <= this.end;
  }

  downloadInformation: DownloadInformation = new DownloadInformation();
  downloadParams: string = "";
  downloadData: any = [];

  protected readonly Routes = Routes;
  protected readonly Side = Side;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly ServerEndpoints = ServerEndpoints;
}
