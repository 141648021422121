import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {LocationDto} from "../../models/location-dto";

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) { }

  get(shard: string, id: string): Observable<LocationDto> {
    return this.httpClient.get<LocationDto>(this.baseUrl + "/Owner/Location/Get?shard=" + shard + "&id=" + id);
  }
}
